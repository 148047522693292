export const TENANT_REDUCER = 'tenant'
export const ASSETS_REDUCER = 'assets'
export const TENANT_ALL_BRANDING_REDUCER_NAME = 'allBranding'
export const TENANT_UPDATE_BRANDING_REDUCER_NAME = 'allBranding'
export const ASSETS_ALL_ASSET_CATEGORIES_BY_TRANSPORT_MODE_REDUCER_NAME =
  'allAssetCategoriesByTransportMode'
export const ASSETS_ALL_ASSET_CLASSES_BY_CATEGORY_REDUCER_NAME = 'allAssetClassesByCategory'
export const ASSETS_ALL_ASSET_TYPES_BY_CATEGORY_REDUCER_NAME = 'allAssetTypesByCategory'
export const ASSETS_ALL_ASSET_TYPES_REDUCER_NAME = 'allAssetTypes'
export const ASSETS_ALL_USER_TYPES_REDUCER_NAME = 'allUserTypes'
export const ASSETS_ALL_ASSET_CATEGORIES_BY_TENANT_ID = 'allAssetCategoriesByTenantId'
