import { type ReactElement } from 'react'
import Loading from '../../assets/loading.gif'
import { SpinnerModalCenter } from './styles'

const SpinnerModalBlock = (): ReactElement<any, any> => {
  return (
    <SpinnerModalCenter>
      <img src={Loading} alt="Loading..." />
    </SpinnerModalCenter>
  )
}

export default SpinnerModalBlock
