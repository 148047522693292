import { createContext, useState, type PropsWithChildren, type ReactElement } from 'react'

import { IntlProvider } from 'react-intl'
import logger from '../../app/middleware/log'
import Arabic from '../../translations/ar_SA.json'
import English from '../../translations/en_US.json'

const LANGUAGE_KEY = 'omnialog-language'

export const LANGUAGES = {
  EN_US: 'en-US',
  AR_SA: 'ar-SA',
}

interface LocalizationContext {
  locale: string
  selectLanguage: (lang: string) => void
}

const initializationLocalizationContext: LocalizationContext = {
  locale: '',
  selectLanguage: () => {
    logger.log('Not ititialized')
  },
}

const setLanguageDirection = (lang: string): void => {
  switch (lang) {
    case LANGUAGES.EN_US:
      document.dir = 'ltr'
      break
    case LANGUAGES.AR_SA:
      document.dir = 'rtl'
      break
  }
}

export const Context = createContext<LocalizationContext>(initializationLocalizationContext)

const currentAppLanguage = localStorage.getItem(LANGUAGE_KEY)

const localLanguage = currentAppLanguage ?? LANGUAGES.EN_US
let lang: Record<string, string>

switch (localLanguage) {
  case LANGUAGES.EN_US:
    lang = English
    break
  case LANGUAGES.AR_SA:
    lang = Arabic
    break
  default:
    lang = English
}
setLanguageDirection(localLanguage)

const Localization = (props: PropsWithChildren<any>): ReactElement<any, any> => {
  const [locale, setLocale] = useState(localLanguage)
  const [messages, setMessages] = useState(lang)

  const selectLanguage = (lang: string): void => {
    setLocale(lang)
    switch (lang) {
      case LANGUAGES.EN_US:
        setMessages(English)
        break
      case LANGUAGES.AR_SA:
        setMessages(Arabic)
        break
    }
    localStorage.setItem(LANGUAGE_KEY, lang)
    setLanguageDirection(lang)
  }

  return (
    <Context.Provider value={{ locale, selectLanguage }}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  )
}

export default Localization
