import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, FormControlLabel, Grid, IconButton, Switch } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useGetCountriesQuery, useGetCurrenciesByCustomerIdQuery } from '../../app/redux-fetch/apiShipment';
import { convertToLabelValue } from '../../app/utils';
import FormInput from '../../components/Forms/FormInput';
import FormSelect from '../../components/Forms/FormSelect';
import commonMessages from '../../components/Messages/commonMessages';
import { useProductModal } from './hooks/useProductModal';
import messages from './messages';

interface ProductFormProps {
    consignmentIndex: number;
    packagingIndex: number;
    productIndex: number;
    removeProduct: (index: number) => void;
}

const ProductForm: React.FC<ProductFormProps> = ({ consignmentIndex, packagingIndex, productIndex, removeProduct }) => {
    const { control, setValue, watch } = useFormContext();
    const { formatMessage } = useIntl();

    const customerId = watch('customerId')

    const { openModal, ModalComponent, productOptions } = useProductModal({
        onSave: (id) => {
            setValue(`consignments.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.productDescription`, id)
        },
    });

    const currencyId = watch(`consignments.${consignmentIndex}.currencyId`)

    const { data: currencies = [] } = useGetCurrenciesByCustomerIdQuery({
        customerId: customerId ?? 0, hideDisabled: true,
    })
    const { data: countries = [] } = useGetCountriesQuery()

    const useProduct = watch(`consignments.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.useProduct`, false)

    return (
        <>
            {ModalComponent}
            <Box
                sx={{
                    border: '1px solid #ccc',
                    padding: '20px',
                    marginTop: 2,
                    borderRadius: 2,
                }}
            >
                <Grid container spacing={2} alignItems="center">
                    {useProduct ? (<>
                        <Grid item xs={12} md={3}>
                            <FormSelect
                                name={`consignments.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.productId`}
                                label={formatMessage(messages.product)}
                                options={productOptions}
                                placeholder={formatMessage(commonMessages.select)}
                                actionLabel={<Button size="small" onClick={() => { openModal() }} sx={{ textTransform: 'none', p: 0, mb: '6px' }}>
                                    {formatMessage(messages.addProduct)}
                                </Button>}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <IconButton onClick={() => removeProduct(productIndex)} color="error">
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </>
                    ) : (
                        <Grid item xs={12} md={3}>
                            <FormInput
                                name={`consignments.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.productDescription`}
                                label={formatMessage(messages.productDescription)}
                                placeholder={formatMessage(commonMessages.pleaseEnter)}
                                fullWidth

                            />
                        </Grid>)}

                    <Grid item xs={12} md={4}>
                        <FormSelect
                            name={`consignments.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.countryOfOriginId`}
                            label={formatMessage(messages.countryOfOrigin)}
                            options={convertToLabelValue(countries)}
                            placeholder={formatMessage(commonMessages.select)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormInput
                            name={`consignments.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.quantity`}
                            label={formatMessage(messages.quantity)}
                            type="number"
                            defaultValue={1}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormInput
                            name={`consignments.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.unitPrice`}
                            type='number'
                            label={formatMessage(messages.unitPrice)}
                            unit={currencies.find(currency => currency.id === currencyId)?.localisedSymbol}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Controller
                            name={`consignments.${consignmentIndex}.packaging.${packagingIndex}.products.${productIndex}.useProduct`}
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={<Switch {...field} checked={field.value} />}
                                    label={field.value ? formatMessage(messages.product) : formatMessage(messages.specifyContent)}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default ProductForm;
