import styled from '@emotion/styled'
import { Typography, TypographyProps } from '@mui/material'

export const SectionTitleTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: '#636363',
  fontWeight: 700,
  fontSize: '20px',
}))

export const SectionLabelTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  color: '#636363',
  fontWeight: 700,
  fontSize: '14px',
}))
