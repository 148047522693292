import cloneDeep from 'lodash/cloneDeep'
import { useCallback } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { useTrackingNumberParamsTypesQuery, useTrackingNumberTemplateQuery } from '../../../app/redux-fetch/apiQuery'
import SpinnerBlock from '../../../components/Spinner/SpinnerBlock'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import TrackingNumberForm from './TrackingNumberForm'
import { ITrackingNumberTemplate } from './types'

const TrackingNumber = () => {
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const tenantId = loggedInUser?.tenantId

  const { data: paramsTypes, isFetching: isParamTypesFetching, isSuccess: isParamTypesLoaded } = useTrackingNumberParamsTypesQuery({ tenantId })
  const { data: template, isFetching: isTemplateFetching, isSuccess: isTemplateLoaded, refetch } = useTrackingNumberTemplateQuery({ tenantId })
  const initialValues = useCallback((): ITrackingNumberTemplate => {
    if (template) {
      const result = cloneDeep(template)
      result.trackingNumberParameters.sort((a, b) => a.sequence - b.sequence)
      const serialNumber = result.trackingNumberParameters.find(tnp => tnp.typeId === 1)
      if (serialNumber && typeof serialNumber.value === 'string') {
        serialNumber.value = serialNumber.value.padStart(8, '0')
      }
      return result
    }
    return {
      trackingNumberParameters: paramsTypes?.map((pt, idx) => {
        return {
          sequence: idx + 1,
          typeId: pt.id
        }
      }) ?? []
    }
  }, [template, paramsTypes])
  const isDisabled = useCallback(() => {
    if (template) {
      return template.trackingNumberParameters.some((x) => x.typeId === 1)
    }
    return false
  }, [template])

  if (!isParamTypesLoaded || isParamTypesFetching || isTemplateFetching || !isTemplateLoaded) return <SpinnerBlock />

  return (
    <TrackingNumberForm
      refetch={refetch}
      value={initialValues()}
      tenantId={tenantId}
      isDisabled={isDisabled()} paramsTypes={[...paramsTypes] ?? []} />
  )
}

export default TrackingNumber
