import { FormikProps } from 'formik'
import { ILoggedInUser } from '../features/types'

export const getProperty = <T>(o: T, propertyName: string): T[keyof T] => {
  return o[propertyName as keyof T]
}

const get = (from: any, ...selectors: any) => {
  return [...selectors].map((s) =>
    s
      .replace(/\[([^[\]]*)\]/g, '.$1.')
      .split('.')
      .filter((t: any) => t !== '')
      .reduce((prev: any, cur: any) => prev && prev[cur], from)
  )
}

export const getError = (formik: FormikProps<any>, id: string) => {
  const touched = formik.touched && get(formik.touched, id)[0]
  const errorMessage = formik.errors && get(formik.errors, id)[0]
  return touched && errorMessage
}

export const REQUEST_INITIAL_STATE = {
  data: null,
  error: null,
  isFetching: false,
  isFinsihed: false,
}

export type ParsedAddress = {
  address1: string
  address2?: string
  city: string
  state: string
  postalCode: string
  country: any
  premise?: string
}

interface IStringMap {
  [key: string]: string[]
}

const ShouldBeComponent: IStringMap = {
  // address1: ['street_number', 'route'],
  address2: ['sublocality_level_1', 'sublocality', 'neighborhood'],
  city: ['locality', 'postal_town', 'sublocality_level_1'],
  state: ['administrative_area_level_1'],
  postalCode: ['postal_code'],
  country: ['country'],
  premise: ['premise', 'subpremise'],
}

export const parseGeoCoderResult = (geoCoderResult: any): ParsedAddress => {
  const addressComponents = geoCoderResult
  const parsedAddress: ParsedAddress = {
    address1: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
  }

  addressComponents.forEach((component: any) => {
    for (const [key, types] of Object.entries(ShouldBeComponent)) {
      if (types.some((type) => component.types.includes(type))) {
        if (key === 'country') {
          parsedAddress.country = component
        } else {
          ;(parsedAddress as any)[key] = component.long_name
        }
        break
      }
    }
  })
  return parsedAddress
}

export const getCompanyName = (user: ILoggedInUser | null): string | null | undefined => {
  if (!user) return null

  if (user.customerId) {
    const selectedCustomer = user.customers.find((customer) => customer.id === user.customerId)
    return selectedCustomer ? selectedCustomer.name : null
  }

  if (user.tenantId) {
    const selectedTenant = user.tenants.find((tenant) => tenant.id === user.tenantId)
    return selectedTenant ? selectedTenant.name ?? '' : null
  }

  return null
}

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export const isEmptyObject = (obj: object): boolean => {
  return Object.entries(obj).length === 0
}

export const getLastDayOfCurrentYear = (): Date => {
  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()
  return new Date(currentYear, 11, 31)
}

interface LabelValue {
  label: string
  value: string | number
}

export const convertToLabelValue = <T extends Record<string, any>>(
  items: T[],
  labelKey: keyof T = 'name',
  valueKey: keyof T = 'id'
): LabelValue[] => {
  return items.map((item) => ({
    label: String(item[labelKey]),
    value: item[valueKey],
  }))
}
