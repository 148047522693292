// ToggleContainer.tsx
import { Box, Button, Collapse } from '@mui/material';
import React from 'react';
import { AccordionHeader } from '../Headers/styles';

interface ToggleContainerProps {
    title: string;
    isOpen?: boolean;
    handleToggle?: () => void;
    children: React.ReactNode;
}

const ToggleContainer: React.FC<ToggleContainerProps> = ({ title, children, isOpen, handleToggle }) => {
    return (
        <>
            <Collapse in={isOpen}>
                <Box sx={{ border: '1px solid #ccc', backgroundColor: '#ffffff', borderRadius: '8px', padding: '16px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                        <AccordionHeader sx={{ p: 0, flexGrow: 1, fontWeight: 400 }}>
                            {title}
                        </AccordionHeader>
                        <Button onClick={handleToggle} sx={{ textTransform: 'none' }}>
                            Hide
                        </Button>
                    </Box>
                    {children}
                </Box>
            </Collapse>
        </>
    );
};

export default ToggleContainer;
