import { Box, Button, TextField } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useGetCountriesQuery } from '../../../app/redux-fetch/apiShipment'
import { getProperty, parseGeoCoderResult } from '../../../app/utils'
import FormDropdown from '../../../components/FormDropDown/FormDropdown'
import PolygonMap from '../../../components/PolygonMap/PolygonMap'
import RequiredAsterisk from '../../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography, SectionTitleTypography } from '../../../components/Typographies/styles'
import { IMarker } from '../../Shipments/types'
import { useLocationFormContext } from '../hooks/useLocationFormContext'
import messages from '../messages'
import { getAddressError } from '../utils'

type AddressDetailsProps = {
    isDisabled?: boolean
}

export const getError = (propertyName: string, touched: any, errors: any): boolean => {
    const isTouched = getProperty(touched, propertyName)
    const errorMessage = getProperty(errors as unknown, propertyName)
    return isTouched && (errorMessage as any)
}


const AddressDetails = ({ isDisabled }: AddressDetailsProps): ReactElement<any, any> => {
    const { formatMessage } = useIntl()

    const { values, setFieldValue, errors, touched } = useLocationFormContext()
    const [marker, setMarker] = useState<IMarker>()

    const [polygons, setPolygons] = useState(values.geofence ?? [])

    const { data: countries = [] } = useGetCountriesQuery();

    const updateMarker = (coords: any) => {
        if (coords.lat != marker?.lat || coords.lng != marker?.lng) {
            setMarker(!coords.lat || !coords.lng ? undefined : coords)
            if (!polygons || polygons.length == 0) {
                // TODO: pan & zoom to new co-ords
                // panTo(coords)
                // setZoom(13)
            }
        }
    }

    useEffect(() => {
        if (updateMarker) {
            updateMarker({ lat: values.lat, lng: values.lng })
        }
    }, [values.lat, values.lng])

    const handleChange = (e: any) => {
        setFieldValue(e.target.id, e.target.value)
    }

    useEffect(() => {
        if (JSON.stringify(values.geofence) !== JSON.stringify(polygons)) {
            setPolygons(values.geofence ?? [])
        }
    }, [values.geofence])

    const handleGeocoder = (geoCoderResult: any) => {
        const parsedAddress = parseGeoCoderResult(geoCoderResult.address_components)
        let address1 = geoCoderResult.formatted_address
        if (parsedAddress?.country) {
            const parsedCountry = countries.find((c: any) => c.iso2 === parsedAddress?.country.short_name)
            address1 = address1.replace(`, ${parsedAddress.country.long_name}`, '').replace(`، ${parsedAddress.country.long_name}`, '').replace(` ${parsedAddress.country.long_name}`, '')
            if (parsedCountry) {
                setFieldValue('address.countryId', parsedCountry.id)
            }
        } else {
            setFieldValue('address.countryId', 0)
        }
        if (parsedAddress?.postalCode) {
            setFieldValue('address.postalCode', parsedAddress?.postalCode)
        } else {
            setFieldValue('address.postalCode', '')
        }
        if (parsedAddress?.city) {
            setFieldValue('address.city', parsedAddress?.city)
        } else {
            setFieldValue('address.city', '')
        }
        if (parsedAddress?.state) {
            setFieldValue('address.state', parsedAddress?.state)
        } else {
            setFieldValue('address.state', '')
        }
        setFieldValue('address.address1', address1)

        setFieldValue('lat', geoCoderResult.geometry.location.lat())
        setFieldValue('lng', geoCoderResult.geometry.location.lng())
    }

    const onHandleDeleteMarker = () => {
        setFieldValue('lat', '')
        setFieldValue('lng', '')
        setMarker(undefined)
    }

    const onHandleDeletePolygon = () => {
        setPolygons([])
        setFieldValue('lat', '')
        setFieldValue('lng', '')
        setFieldValue('geofence', undefined)
        setMarker(undefined)
    }

    useEffect(() => {
        setFieldValue('geofence', polygons)
    }, [polygons])

    return (
        <Box sx={{ width: '100%' }}>
            <Box display={'flex'} sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: '16px'
            }}>
                <SectionTitleTypography >{formatMessage(messages.addressDetails)}</SectionTitleTypography>
                <Box>
                    <Button
                        variant='outlined'
                        color='secondary'
                        disabled={polygons && polygons.length === 0}
                        onClick={onHandleDeletePolygon}
                        sx={{ marginRight: '12px' }}
                    >
                        {formatMessage(messages.deletePolygon)}
                    </Button>
                    <Button
                        variant='outlined'
                        color='secondary'
                        disabled={!marker?.lat && !marker?.lng}
                        onClick={onHandleDeleteMarker}
                    >
                        {formatMessage(messages.deleteMarker)}
                    </Button>
                </Box>
            </Box>
            <Box>
                <PolygonMap
                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}
                    defaultCenter={{
                        lat: 24.638517161652892,
                        lng: 45.84234953431573,
                        zoom: 5,
                    }}
                    marker={marker}
                    height={294}
                    polygons={polygons}
                    onChangePolygons={setPolygons}
                    onChangeMarker={setMarker}
                    handleGeocode={handleGeocoder}
                />
            </Box>
            <Box sx={{ mb: '16px' }}>
                <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.address1)}<RequiredAsterisk /></SectionLabelTypography>
                <TextField
                    disabled={isDisabled}
                    fullWidth
                    id='address.address1'
                    name='address.address1'
                    value={values.address?.address1}
                    onChange={handleChange}
                    error={touched.address?.address1 && Boolean(errors.address?.address1)}
                    helperText={getAddressError('address1', errors, touched)}
                />
            </Box>
            <Box sx={{ mb: '16px' }}>
                <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.address2)}</SectionLabelTypography>
                <TextField
                    disabled={isDisabled}
                    fullWidth
                    id='address.address2'
                    name='address.address2'
                    value={values.address?.address2 ?? ''}
                    onChange={handleChange}
                    error={touched.address?.address2 && Boolean(errors.address?.address2)}
                    helperText={getAddressError('address2', errors, touched)}
                />
            </Box>
            <Box sx={{ mb: '16px' }}>
                <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.address3)}</SectionLabelTypography>
                <TextField
                    disabled={isDisabled}
                    fullWidth
                    id='address.address3'
                    name='address.address3'
                    value={values.address?.address3 ?? ''}
                    onChange={handleChange}
                    error={touched.address?.address3 && Boolean(errors.address?.address3)}
                    helperText={getAddressError('address3', errors, touched)}
                />
            </Box>
            <Box sx={{ mb: '16px' }}>
                <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.city)}<RequiredAsterisk /></SectionLabelTypography>
                <TextField
                    disabled={isDisabled}
                    fullWidth
                    id='address.city'
                    name='address.city'
                    value={values.address?.city}
                    onChange={handleChange}
                    error={touched.address?.city && Boolean(errors.address?.city)}
                    helperText={getAddressError('city', errors, touched)}
                />
            </Box>
            <Box display='flex' columnGap={2} sx={{ mb: '16px' }}>
                <Box>
                    <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.state)}</SectionLabelTypography>
                    <TextField
                        disabled={isDisabled}
                        fullWidth
                        id='address.state'
                        name='address.state'
                        value={values.address?.state ?? ''}
                        onChange={handleChange}
                        error={touched.address?.state && Boolean(errors.address?.state)}
                        helperText={getAddressError('state', errors, touched)}
                    />
                </Box>
                <Box>
                    <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.postCode)}</SectionLabelTypography>
                    <TextField
                        disabled={isDisabled}
                        fullWidth
                        id='address.postalCode'
                        name='address.postalCode'
                        value={values.address?.postalCode ?? ''}
                        onChange={handleChange}
                        error={touched.address?.postalCode && Boolean(errors.address?.postalCode)}
                        helperText={getAddressError('postCode', errors, touched)}
                    />
                </Box>
            </Box>
            <Box sx={{ mb: '16px' }}>
                <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.country)}<RequiredAsterisk /></SectionLabelTypography>
                <FormDropdown
                    id='address.countryId'
                    disabled={isDisabled}
                    onChange={(e, name, newValue) => {
                        setFieldValue(name, newValue)
                    }}
                    value={values.address?.countryId}
                    items={countries}
                    errorText={touched.address?.countryId && errors.address?.countryId}
                    error={
                        (touched.address?.countryId as any) && Boolean(errors.address?.countryId)
                    }
                />
            </Box>
            <Box display='flex' columnGap={2} marginBottom={5}>
                <Box>
                    <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.lat)}</SectionLabelTypography>
                    <TextField
                        disabled={isDisabled}
                        fullWidth
                        id='lat'
                        name='lat'
                        value={values.lat}
                        onChange={handleChange}
                        type='number'
                        error={touched.lat && Boolean(errors.lat)}
                        helperText={getError('lat', errors, touched)}
                        InputLabelProps={{
                            shrink: !!values.lat,
                        }}
                    />
                </Box>
                <Box>
                    <SectionLabelTypography variant="subtitle1" sx={{ mb: '4px' }}>{formatMessage(messages.lng)}</SectionLabelTypography>
                    <TextField
                        disabled={isDisabled}
                        fullWidth
                        id='lng'
                        name='lng'
                        value={values.lng}
                        onChange={handleChange}
                        type='number'
                        error={touched.lng && Boolean(errors.lng)}
                        helperText={getError('lng', errors, touched)}
                        InputLabelProps={{
                            shrink: !!values.lng,
                        }}
                    />
                </Box>

            </Box>
        </Box>
    )
}

export default AddressDetails