import { Accordion, styled, type AccordionProps } from '@mui/material'

export const RouteAccordion = styled(Accordion)<AccordionProps>(() => ({
  borderBotton: '1px solid rgba (0, 0, 0, 0.125)',
  margin: '16px',
}))

export const TransportLegAccordion = styled(Accordion)<AccordionProps>(() => ({
    borderBotton: '1px solid rgba (0, 0, 0, 0.125)',
    margin: '16px',
    backgroundColor: 'rgba(0, 0, 0, 0.025)'
  }))