import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { useAccount, useMsal } from '@azure/msal-react'
import axios from 'axios'
import React, { type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { loginRequest } from '../../authConfig'
import localizedMessages from './messages'
import logger from '../../app/middleware/log'

interface RequestInterceptorProps {
  children?: React.ReactNode
}

const RequestInterceptor = (props: RequestInterceptorProps): ReactElement<any, any> => {
  const { instance, accounts } = useMsal()
  const account = useAccount(accounts[0])
  const { children } = props

  const { formatMessage } = useIntl()
  const formattedError = formatMessage(localizedMessages.noActiveAccount)

  /* eslint-disable no-param-reassign */
  axios.interceptors.request.use(async (config) => {
    if (account == null) {
      throw Error(formattedError)
    }

    const response = await instance
      .acquireTokenSilent({
        ...loginRequest,
        account,
      })
      .catch((error: any) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(loginRequest)
        }
        logger.log(error)
      })

    const bearer = `Bearer ${response?.accessToken}`
    config.headers.Authorization = bearer

    return config
  })
  /* eslint-enable no-param-reassign */

  return <>{children}</>
}

export default RequestInterceptor
