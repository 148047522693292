import { Box } from '@mui/material';

interface FlowSectionProps {
    icon: React.ReactNode;
    children: React.ReactNode;
    hasBorder?: boolean;
    showIcon?: boolean;
}

const FlowSection: React.FC<FlowSectionProps> = ({
    icon,
    children,
    hasBorder = true,
    showIcon = true,
}: FlowSectionProps) => (
    <Box
        sx={{
            display: 'flex',
            alignItems: 'stretch',
        }}
    >
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: '16px',
                flexShrink: 0,
            }}
        >
            {showIcon ? (<Box
                sx={{
                    backgroundColor: 'white',
                    color: '#2196f3',
                    borderRadius: '50%',
                    border: '2px solid #2196f3',
                    width: '40px',
                    height: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexShrink: 0,
                }}
            >
                {icon}
            </Box>) : (
                <Box
                    sx={{
                        width: '40px',
                        flexShrink: 0,
                    }}
                />
            )}
            {hasBorder && (
                <Box
                    sx={{
                        width: '2px',
                        flexGrow: 1,
                        backgroundColor: '#2196f3',
                    }}
                />
            )}
        </Box>
        <Box sx={{ flexGrow: 1 }}>{children}</Box>
    </Box>
);

export default FlowSection;
