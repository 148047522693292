import { AxiosError } from 'axios'
import { fetchCustomerSettingsDataThunk, makeFetchSlice } from '../../../../app/redux-fetch'
import { AppDispatch } from '../../../../app/store/store'
import { fetchAllUserByCustomerId, postUserByCustomerId } from './api'
import {
  CUSTOMER_SETTINGS_ALL_USER_REDUCER_NAME,
  CUSTOMER_SETTINGS_POST_USER_REDUCER_NAME,
} from './consts'
import { IUser } from './types'
import logger from '../../../../app/middleware/log'

// fetch all users by customer id
export const fetchAllCustomerSettingsUserByCustomerIdSlice = makeFetchSlice(
  CUSTOMER_SETTINGS_ALL_USER_REDUCER_NAME
)

export const fetchAllCustomerSettingsUserByCustomerIdReducers =
  fetchAllCustomerSettingsUserByCustomerIdSlice.reducer
export const fetchAllCustomerSettingsUserByCustomeIdActions =
  fetchAllCustomerSettingsUserByCustomerIdSlice.actions

export const fetchAllCustomerSettingsUserThunk = (tenantId: number, customerId?: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchCustomerSettingsDataThunk({
        dispatch,
        actions: fetchAllCustomerSettingsUserByCustomeIdActions,
        asyncFunc: () => fetchAllUserByCustomerId({ tenantId, customerId }),
      })
    } catch (error) {
      logger.error('🚀 Error fetching user by customer id', error)
    }
  }
}

export const postUserByCustomerIdSlice = makeFetchSlice(CUSTOMER_SETTINGS_POST_USER_REDUCER_NAME)
export const postUserByCustomerIdReducers = postUserByCustomerIdSlice.reducer
export const postUserByCustomerIdActions = postUserByCustomerIdSlice.actions

export const postCustomerSettingsUserThunk = (
  customerId: number,
  tenantId: number,
  user: IUser
) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchCustomerSettingsDataThunk({
        dispatch,
        actions: postUserByCustomerIdActions,
        asyncFunc: () => postUserByCustomerId({ customerId, tenantId, user }),
      })
    } catch (error) {
      logger.error('🚀 Error posting user by customer id', (error as AxiosError)?.message)
    }
  }
}
