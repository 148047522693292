import { AxiosResponse } from 'axios'
import { usersAuthorizedFetch } from '../app/axios/omnialog'

export const fetchCustomers = async (tenantId: number): Promise<AxiosResponse<any, any> | any> => {
  return Promise.resolve({ data: { data: [{ id: 1, name: 'Customer name' }], status: 1 } })
}

export const fetchLoggedInUser = async (tenantId?: number, customerId?: number): Promise<AxiosResponse<any, any> | any> => {
  const queryParams: { [key: string]: number } = {}

  if (tenantId != null) {
    queryParams['tenantId'] = tenantId;
  }

  if (customerId != null) {
    queryParams['customerId'] = customerId;
  }
  return await usersAuthorizedFetch.get('/user/me', { params: queryParams })
}

export const fetchLoggedInCustomer = async ({
  tenantId,
  customerId,
}: {
  tenantId: number
  customerId: number
}): Promise<AxiosResponse<any, any>> => {
  return await usersAuthorizedFetch.get(`/tenant/${tenantId}/customer/${customerId}`)
}

export const fetchAllTenantUsers = async (tenantId: number): Promise<AxiosResponse<any, any> | any> => {
  return await usersAuthorizedFetch.get(`/tenant/${tenantId}/user`)
}
