import { FormControl, ListSubheader, MenuItem, Select } from '@mui/material'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useAppDispatch } from '../../../../app/hooks'
import { fetchLoggedInUserThunk } from '../../../slice'
import { ILoggedInUser } from '../../../types'
import messages from './messages'

export interface ICustomerTenantSelectProps {
  user: ILoggedInUser
}

export const getInitialValue = (user: ILoggedInUser | null): string | null => {
  if (!user) return null

  if (user.customerId) {
    const selectedCustomer = user.customers.find((customer) => customer.id === user.customerId)
    return selectedCustomer ? `customer-${selectedCustomer.id}` : null
  }

  if (user.tenantId) {
    const selectedTenant = user.tenants.find((tenant) => tenant.id === user.tenantId)
    return selectedTenant ? `tenant-${selectedTenant.id}` : null
  }

  return null
}

const CustomerTenantSelect = ({ user }: ICustomerTenantSelectProps) => {
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const selectedValue = useCallback(() => getInitialValue(user), [user])

  const handleChange = (event: any) => {
    if (event.target.value.includes('customer-')) {
      const customerId = event.target.value.split('-')[1]
      dispatch(fetchLoggedInUserThunk({ customerId: customerId }))
    } else {
      const tenantId = event.target.value.split('-')[1]
      dispatch(fetchLoggedInUserThunk({ tenantId: tenantId }))
    }
    localStorage.setItem('selectedCustomerTenant', event.target.value)
  }

  return (
    <FormControl variant='standard' style={{ minWidth: 200 }}>
      <Select
        labelId='customer-tenant-select-label'
        value={selectedValue()}
        onChange={handleChange}
        style={{ borderBottom: 'none' }}
      >
        {user.tenants.length > 0 && (
          <ListSubheader>{formatMessage(messages.tenants)}</ListSubheader>
        )}
        {user.tenants.map((tenant) => (
          <MenuItem key={`tenant-${tenant.id}`} value={`tenant-${tenant.id}`} data-type='tenantId'>
            {tenant.name}
          </MenuItem>
        ))}
        {user.customers.length > 0 && (
          <ListSubheader>{formatMessage(messages.customers)}</ListSubheader>
        )}
        {user.customers.map((customer) => (
          <MenuItem
            key={`customer-${customer.id}`}
            value={`customer-${customer.id}`}
            data-type='customerId'
          >
            {customer.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default CustomerTenantSelect
