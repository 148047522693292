import { AxiosResponse } from 'axios'
import { usersAuthorizedFetch } from '../../../app/axios/omnialog'

export const getOnBoarding = async (
  tenantId: number,
  identityProviderIntegrationTypeId: number
): Promise<AxiosResponse<any, any>> => {
  return await usersAuthorizedFetch.get(
    `/integrations/${identityProviderIntegrationTypeId}/onBoard`,
    { params: { tenantId } }
  )
}

export const getIdentityProviderIntegrationByTenantCustomerId = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  tenantId: number,
  customerId?: number
): Promise<AxiosResponse<any, any>> => {
  if (!customerId) return await usersAuthorizedFetch.get(`/tenant/${tenantId}/integrations`)
  return await usersAuthorizedFetch.get(`/tenant/${tenantId}/customer/${customerId}/integrations`)
}

export const fetchOffice365TenantSync = async (
  tenantId: number,
  customerId?: number
): Promise<AxiosResponse<any, any>> => {
  if (customerId)
    return await usersAuthorizedFetch.get(`/tenant/${tenantId}/customer/${customerId}/sync`)
  return await usersAuthorizedFetch.get(`tenant/${tenantId}/sync`)
}
