import { useAppSelector } from '../../app/hooks'
import { fetchLoggedInUserSelector } from '../../features/selectors'
import { ILoggedInUser } from '../../features/types'
import NoMatch from '../NoMatch/NoMatch'

interface ITenantRouteProps {
  children?: React.ReactNode
}

export const TenantRoute = ({ children }: ITenantRouteProps) => {
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const { tenantId, customerId } = loggedInUser

  const isTenantUser = tenantId && !customerId

  if (!isTenantUser) return <NoMatch />

  return children ? <>{children}</> : null
}

export default TenantRoute
