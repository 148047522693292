import { combineReducers } from '@reduxjs/toolkit'
import {
  APP_IS_SIGN_USER_REDUCER_NAME,
  APP_LOGGED_IN_CUSTOMER_REDUCER_NAME,
  APP_LOGGED_IN_USER_REDUCER_NAME
} from './const'
import {
  fetchLoggedInCustomerReducers,
  fetchLoggedInUserReducers,
  isSigninUserReducer
} from './slice'

export const appReducers = combineReducers({
  [APP_LOGGED_IN_USER_REDUCER_NAME]: fetchLoggedInUserReducers,
  [APP_LOGGED_IN_CUSTOMER_REDUCER_NAME]: fetchLoggedInCustomerReducers,
  [APP_IS_SIGN_USER_REDUCER_NAME]: isSigninUserReducer
})
