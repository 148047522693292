import { Box, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InputHeader } from '../Headers/styles';

interface CombinedSelectProps {
    nameValue: string;
    nameUnit: string;
    labelValue: string | number;
    optionsUnit: { label: string; value: string | number }[];
    type?: 'text' | 'number';
}

const CombinedSelect: React.FC<CombinedSelectProps> = ({
    nameValue,
    nameUnit,
    labelValue,
    optionsUnit,
    type = 'number',
}) => {
    const { control } = useFormContext();

    return (
        <Box mb={2}>
            <InputHeader>{labelValue}</InputHeader>
            <Controller
                name={nameValue}
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                        label={labelValue}
                        variant="outlined"
                        fullWidth
                        value={value}
                        onChange={(e) => {
                            const newValue = type === 'number' ? Number(e.target.value) : e.target.value;
                            onChange(newValue);
                        }}
                        error={!!error}
                        helperText={error?.message}
                        type={type}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Controller
                                        name={nameUnit}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                variant="standard"
                                                disableUnderline
                                                renderValue={(selected) => {
                                                    return optionsUnit.find((option) => option.value === selected)?.label;
                                                }}
                                            >
                                                {optionsUnit.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
            />
        </Box>
    );
};

export default CombinedSelect;
