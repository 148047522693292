import AddIcon from '@mui/icons-material/Add';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import RoomIcon from '@mui/icons-material/Room';

import {
  AccordionDetails,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import StyledAccordion from '../../components/Accordions/StyledAccordion';
import StyledAccordionSummary from '../../components/Accordions/StyledAccordionSummary';
import ToggleContainer from '../../components/Containers/ToggleContainer';
import FormDatePickerInput from '../../components/Forms/FormDatePickerInput';
import FormInput from '../../components/Forms/FormInput';
import FormSelect from '../../components/Forms/FormSelect';
import commonMessages from '../../components/Messages/commonMessages';
import InfoDisplayItem from '../../components/Typographies/InfoDisplayItem';
import FlowSection from './FlowSection';
import TransitDetailsForm from './TransitDetailsForm'; // Import the new component
import { useLocationModal } from './hooks/useLocationModal';
import messages from './messages';

interface WaypointFormProps {
  consignmentIndex: number;
  waypointIndex: number;
  removeWaypoint: (index: number) => void;
  insertWaypoint: (index: number, value: any) => void;
}

const WaypointForm: React.FC<WaypointFormProps> = ({
  consignmentIndex,
  waypointIndex,
  removeWaypoint,
  insertWaypoint
}) => {
  const { control, setValue, watch } = useFormContext();
  const [expanded, setExpanded] = useState(true);
  const [isOpenTransitDetails, setIsOpenTransitDetails] = useState(false);
  const { formatMessage } = useIntl();

  const handleToggleAccordion = () => {
    setExpanded((prev) => !prev);
  };

  const handleToggleTransitDetail = () => {
    setIsOpenTransitDetails((prev) => !prev);
  };

  const { openModal, ModalComponent, locationOptions } = useLocationModal({
    onSave: (id) => {
      setValue(`consignments.${consignmentIndex}.waypoints.${waypointIndex}.location`, id)
    },
  });

  const getLocationName = (waypointId: number) => {
    return locationOptions.find((location) => location.value === waypointId)?.label;
  }

  const waypointId = watch(`consignments.${consignmentIndex}.waypoints.${waypointIndex}.locationId`);
  const loadingDate = dayjs(watch(`consignments.${consignmentIndex}.waypoints.${waypointIndex}.loadingDate`)).format('DD/MM/YYYY');
  const clearingRequired = watch(`consignments.${consignmentIndex}.waypoints.${waypointIndex}.clearingRequired`);

  return (
    <>
      {ModalComponent}
      <FlowSection icon={<RoomIcon />}>
        <StyledAccordion
          expanded={expanded}
          onChange={handleToggleAccordion}
          sx={{ pt: '0px' }}
        >
          <StyledAccordionSummary
            index={`waypoint-content-${consignmentIndex}-${waypointIndex}`}
            expanded={expanded}
          >

            <Grid container>
              <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Box display='flex' alignItems='center' width='100%'>
                  {formatMessage(messages.waypoint)} #{waypointIndex + 1}
                  <Typography
                    variant='body2'
                    sx={{ color: '#FF6161', cursor: 'pointer', ml: '16px' }}
                    onClick={() => removeWaypoint(waypointIndex)}
                  >
                    {formatMessage(messages.remove)}
                  </Typography>
                </Box>
              </Grid>
              {!expanded && (
                <>
                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <InfoDisplayItem label={formatMessage(messages.waypoint)} value={getLocationName(waypointId) ?? 'N/A'} isSmallScreen={false} />
                  </Grid>
                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <InfoDisplayItem label={formatMessage(messages.scheduledDate)} value={loadingDate ?? 'N/A'} isSmallScreen={false} />
                  </Grid>
                  <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <InfoDisplayItem label={formatMessage(messages.clearingRequired)} value={clearingRequired ? 'YES' : 'NO'} isSmallScreen={false} />
                  </Grid>
                </>
              )}
            </Grid>
          </StyledAccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <Grid item xs={12} md={4}>
                <FormSelect
                  name={`consignments.${consignmentIndex}.waypoints.${waypointIndex}.locationId`}
                  label={formatMessage(messages.location)}
                  options={locationOptions}
                  actionLabel={<Button size="small" onClick={() => { openModal() }} sx={{ textTransform: 'none', p: 0, mb: '6px' }}>
                    {formatMessage(commonMessages.addNew)}
                  </Button>}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormDatePickerInput
                  name={`consignments.${consignmentIndex}.waypoints.${waypointIndex}.loadingDate`}
                  label='Loading Date & Time'
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <FormSelect
                  name={`consignments.${consignmentIndex}.waypoints.${waypointIndex}.contact`}
                  label='Contact'
                  options={[]}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="subtitle1" gutterBottom sx={{ color: '#636363' }}>
                  {formatMessage(messages.selectedLocation)}
                </Typography>
                <Typography variant="body2" sx={{ color: '#636363' }}>Location 1 (Address Of Location 1)</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormInput
                  name={`consignments.${consignmentIndex}.waypoints.${waypointIndex}.deliveryInstructions`}
                  label={formatMessage(messages.deliveryInstructions)}
                  placeholder={formatMessage(commonMessages.pleaseEnter)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
                  <Controller
                    name={`consignments.${consignmentIndex}.waypoints.${waypointIndex}.clearingRequired`}
                    control={control}
                    defaultValue={false}
                    render={({ field }) => (
                      <FormControlLabel
                        control={<Switch {...field} checked={Boolean(field.value)} />}
                        label={formatMessage(messages.clearingRequired)}
                      />
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
          </AccordionDetails>
        </StyledAccordion>
      </FlowSection>

      <FlowSection icon={<LocalShippingIcon />} showIcon={isOpenTransitDetails}>
        <Box sx={{ pt: '16px' }}>
          <ToggleContainer title={formatMessage(messages.transitDetails)} isOpen={isOpenTransitDetails}
            handleToggle={handleToggleTransitDetail}
          >
            <TransitDetailsForm
              namePrefix={`consignments.${consignmentIndex}.waypoints.${waypointIndex}.transitDetails`}
            />
          </ToggleContainer>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            {!isOpenTransitDetails && (
              <Button onClick={handleToggleTransitDetail} sx={{ textTransform: 'none' }}>
                {formatMessage(messages.showTransitDetails)}
              </Button>
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button
              startIcon={<AddIcon />}
              onClick={() => insertWaypoint(waypointIndex + 1, { location: '' })}
              sx={{ textTransform: 'none' }}
            >
              {formatMessage(messages.addWaypoint)}
            </Button>
          </Box>
        </Box>
      </FlowSection>
    </>
  );
};

export default WaypointForm;
