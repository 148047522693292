import styled from '@emotion/styled';
import { Typography } from '@mui/material';

export const LabelItem = styled(Typography)`
    color: #636363;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-align: left;
    padding: 8px;
    letter-spacing: 0.5px;
    `;