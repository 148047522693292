import { Box } from '@mui/material'
import { Form, Formik, FormikValues } from 'formik'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import {
  useGetAllHsHeadingsQuery,
  useGetAllSubHsHeadingsQuery,
  useUpdateProductCategoryMutation,
} from '../../../app/redux-fetch/apiQuery'
import SubmitButtons from '../../../components/Buttons/SubmitButtons'
import { useSideModalContext } from '../../AssetsManagement/modals/SideModalContext'
import { IHsHeading, IHsSubheading, IProductCategory } from '../types'
import ConfirmCreateDialog from './ConfirmCreateDialog'
import HsLocalTariffTable from './HsLocalTariffTable'
import MainInfo from './MainInfo'
import { getProductCategoryValidationSchema } from './validatationSchemas'

type ProductCategoryFormProps = {
  productCategory: IProductCategory
  isDisabled: boolean
}

const ProductCategoryForm = ({ productCategory, isDisabled }: ProductCategoryFormProps) => {
  const isNew = !productCategory.id
  const { onClose } = useSideModalContext()
  const { formatMessage } = useIntl()
  const { data: hsHeadingItems = [] as IHsHeading[] } = useGetAllHsHeadingsQuery()
  const { data: hsSubheadingItems = [] as IHsSubheading[] } = useGetAllSubHsHeadingsQuery()
  const validationSchema = getProductCategoryValidationSchema(formatMessage)
  const [updateProductCategory] = useUpdateProductCategoryMutation()
  const [isConfigDialogOpen, setIsConfigDialogOpen] = useState(false)
  return (
    <Formik
      initialValues={productCategory}
      onSubmit={async (values: FormikValues) => {
        if (!values.id) {
          setIsConfigDialogOpen(true)
        } else {
          await updateProductCategory({
            customerId: values.customerId,
            body: { ...values, customerId: values.customerId } as IProductCategory,
          })
          onClose()
        }
      }}
      validationSchema={validationSchema}
    >
      <Form>
        <ConfirmCreateDialog
          isOpen={isConfigDialogOpen}
          handleEdit={() => {
            setIsConfigDialogOpen(false)
            onClose()
          }}
          handleCancel={() => {
            setIsConfigDialogOpen(false)
          }}
        />
        <Box sx={{ m: '32px' }}>
          <MainInfo
            hsHeadingItems={hsHeadingItems}
            hsSubheadingItems={hsSubheadingItems}
            isDisabled={isDisabled}
            isEdit={!isNew}
          />
          <HsLocalTariffTable isDisabled={isDisabled} />
        </Box>
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            padding: '16px',
            backgroundColor: '#fff',
            borderTop: '1px solid #e0e0e0',
            zIndex: 999,
          }}
        >
          {!isDisabled && <SubmitButtons create={isNew} />}
        </Box>
      </Form>
    </Formik>
  )
}

export default ProductCategoryForm
