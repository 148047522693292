import Icon from '@mui/icons-material/Remove'
import { FabStyled } from './FabStyled'

export interface IRemoveItemActionProps {
  onClick?: () => void
}
export const RemoveItemAction = ({ onClick }: IRemoveItemActionProps) => {
  return (
    <FabStyled
      aria-label='remove'
      color='error'
      onClick={onClick}
      size='small'
      sx={{ margin: '5px' }}
    >
      <Icon />
    </FabStyled>
  )
}
