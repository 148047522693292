import { Action } from '@reduxjs/toolkit'
import axios from 'axios'
import prop from 'lodash/fp/prop'
import { AppDispatch } from '../store/store'


export * from './consts'
export * from './selectors'
export * from './slice'
export * from './types'

const dataTransformer = prop('data')
export const defaultServerErrors: FetchErrors = [{ detail: 'Something went wrong.' }]

interface FetchDataParams {
  dispatch: AppDispatch
  actions?: FetchActions
  asyncFunc: (params?: any) => any
  transformResponse?: (res: any) => any
  suppressErrors?: boolean
  skipSaveToStore?: boolean
}

interface FetchActions {
  init: () => Action
  succeed: (res: any) => Action
  failure: (err: any) => Action
}

interface ApiError {
  detail: string
  code?: string
}

type FetchErrors = ApiError[]

export async function fetchDataThunk({
  dispatch,
  actions,
  asyncFunc,
  transformResponse = dataTransformer,
  suppressErrors,
}: FetchDataParams) {
  try {
    if (actions?.init) dispatch(actions.init())
    const response = await asyncFunc()
    const transformedResponse = transformResponse(response)

    if (actions?.succeed) dispatch(actions.succeed(transformedResponse))

    return transformedResponse
  } catch (err: unknown) {
    let errors: FetchErrors = defaultServerErrors

    if (axios.isAxiosError(err)) {
      const validationErrorMessage = err?.response?.data?.errorMessage
      const validationErrors = validationErrorMessage && [{ detail: validationErrorMessage }]

      const apiErrors = err?.response?.data?.errors
      errors = validationErrors ?? apiErrors ?? defaultServerErrors
    }

    if (actions?.failure) dispatch(actions.failure(errors))

    if (!suppressErrors) {
      throw err
    }
  }
}


export async function fetchApiDataThunk(params: FetchDataParams) {
  return fetchDataThunk({
    ...params,
    transformResponse: (props) => {
      const { data, error } = prop('data', props)
      if (error) throw error
      return data
    },
  })
}

export async function fetchShipmentsDataThunk(params: FetchDataParams) {
  return fetchDataThunk({
    ...params,
    transformResponse: (props) => {
      const { data, error } = prop('data', props)
      if (error) throw error
      return data
    },
  })
}

export async function fetchBrandingDataThunk(params: FetchDataParams) {
  return fetchDataThunk({
    ...params,
    transformResponse: (props) => {
      const { data, error } = prop('data', props)
      if (error) throw error
      return data
    },
  })
}

export async function fetchAssetsDataThunk(params: FetchDataParams) {
  return fetchDataThunk({
    ...params,
    transformResponse: (props) => {
      const { data, error } = prop('data', props)
      if (error) throw error
      return data
    },
  })
}

export async function fetchCustomerSettingsDataThunk(params: FetchDataParams) {
  return fetchDataThunk({
    ...params,
    transformResponse: (props) => {
      const { data, error } = prop('data', props)
      if (error) throw error
      return data
    },
  })
}

export async function fetchCommonSettingsDataThunk(params: FetchDataParams) {
  return fetchDataThunk({
    ...params,
    transformResponse: (props) => {
      const { data, error } = prop('data', props)
      if (error) throw error
      return data
    },
  })
}
