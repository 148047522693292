import logger from '../../app/middleware/log'
import { fetchShipmentsDataThunk, makeFetchSlice } from '../../app/redux-fetch'
import { AppDispatch } from '../../app/store/store'
import {
  fetchAllCountries,
  fetchAllCustomersByTenantId,
  fetchAllLocationTypes,
  fetchAllMeasureUnits,
  fetchAllPalletTypes,
  fetchAllShipments,
  fetchAllTenantCurrencies,
  fetchAllTransportModes,
  fetchCurrenciesByCustomerId,
  fetchLocationsByCustomerOrTenant,
  fetchShipmentById,
  fetchSystemCurrencies,
  fetchTemperatureRanges,
} from './api'
import {
  SHIPMENTS_ALL_COUNTRIES_REDUCER_NAME,
  SHIPMENTS_ALL_CURRENCIES_REDUCER_NAME,
  SHIPMENTS_ALL_LOCATIONS_BY_TENANT_CUSTOMER_REDUCER_NAME,
  SHIPMENTS_ALL_LOCATION_TYPES_REDUCER_NAME,
  SHIPMENTS_ALL_MEASURE_UNITS_REDUCER_NAME,
  SHIPMENTS_ALL_PALLET_TYPES_REDUCER_NAME,
  SHIPMENTS_ALL_REDUCER_NAME,
  SHIPMENTS_ALL_TEMPERATURE_RANGES_REDUCER_NAME,
  SHIPMENTS_ALL_TRANSPORT_MODES_REDUCER_NAME,
  SHIPMENTS_BY_ID_REDUCER_NAME,
  SHIPMENTS_CUSTOMERS_BY_TENANT_ID_REDUCER_NAME,
  SHIPMENTS_CUSTOMER_CURRENCIES_REDUCER_NAME,
  SHIPMENTS_SYSTEM_CURRENCIES_REDUCER_NAME,
} from './consts'

const fetchAllShipmentsSlice = makeFetchSlice(SHIPMENTS_ALL_REDUCER_NAME)

export const fetchAllShipmentsReducers = fetchAllShipmentsSlice.reducer
export const fetchAllShipmentsActions = fetchAllShipmentsSlice.actions

export const fetchShipmentsThunk = (tenantId: number, customerId?: any) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchShipmentsDataThunk({
        dispatch,
        actions: fetchAllShipmentsActions,
        asyncFunc: () => fetchAllShipments(tenantId, customerId),
      })
    } catch (error) {
      logger.error('Error fetching shipments', error)
    }
  }
}

const fetchAllCountriesSlice = makeFetchSlice(SHIPMENTS_ALL_COUNTRIES_REDUCER_NAME)

export const fetchAllCountriesReducers = fetchAllCountriesSlice.reducer
export const fetchAllCountriesActions = fetchAllCountriesSlice.actions

export const fetchAllCountriesThunk = () => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchShipmentsDataThunk({
        dispatch,
        actions: fetchAllCountriesActions,
        asyncFunc: () => fetchAllCountries(),
      })
    } catch (error) {
      logger.error('Error fetching countries', error)
    }
  }
}

const fetchCurrenciesByCustomerIdSlice = makeFetchSlice(SHIPMENTS_CUSTOMER_CURRENCIES_REDUCER_NAME)

export const fetchCurrenciesByCustomerIdReducers = fetchCurrenciesByCustomerIdSlice.reducer
export const fetchCurrenciesByCustomerIdActions = fetchCurrenciesByCustomerIdSlice.actions

export const fetchCurrenciesByCustomerIdThunk = (customerId: number, hideDisabled: boolean) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchShipmentsDataThunk({
        dispatch,
        actions: fetchCurrenciesByCustomerIdActions,
        asyncFunc: () => fetchCurrenciesByCustomerId(customerId, hideDisabled),
      })
    } catch (error) {
      logger.error('Error fetching currencies by customer id', error)
    }
  }
}

const fetchSystemCurrenciesSlice = makeFetchSlice(SHIPMENTS_SYSTEM_CURRENCIES_REDUCER_NAME)

export const fetchSystemCurrenciesReducers = fetchSystemCurrenciesSlice.reducer
export const fetchSystemCurrenciesActions = fetchSystemCurrenciesSlice.actions

export const fetchSystemCurrenciesThunk = () => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchShipmentsDataThunk({
        dispatch,
        actions: fetchSystemCurrenciesActions,
        asyncFunc: () => fetchSystemCurrencies(),
      })
    } catch (error) {
      logger.error('Error fetching currencies by customer id', error)
    }
  }
}

const fetchAllUnitsSlice = makeFetchSlice(SHIPMENTS_ALL_MEASURE_UNITS_REDUCER_NAME)

export const fetchAllUnitsSliceReducers = fetchAllUnitsSlice.reducer
export const fetchAllUnitsSliceActions = fetchAllUnitsSlice.actions

export const fetchAllUnitsThunk = () => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchShipmentsDataThunk({
        dispatch,
        actions: fetchAllUnitsSliceActions,
        asyncFunc: () => fetchAllMeasureUnits(),
      })
    } catch (error) {
      logger.error('Error fetching units', error)
    }
  }
}

const fetchAllTemperatureRangeSlice = makeFetchSlice(SHIPMENTS_ALL_TEMPERATURE_RANGES_REDUCER_NAME)

export const fetchAllTemperatureRangeReducers = fetchAllTemperatureRangeSlice.reducer
export const fetchAllTemperatureRangeActions = fetchAllTemperatureRangeSlice.actions

export const fetchAllTemperatureRangeThunk = () => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchShipmentsDataThunk({
        dispatch,
        actions: fetchAllTemperatureRangeActions,
        asyncFunc: () => fetchTemperatureRanges(),
      })
    } catch (error) {
      logger.error('Error fetching Temperature Ranges', error)
    }
  }
}

const fetchAllPalletTypesSlice = makeFetchSlice(SHIPMENTS_ALL_PALLET_TYPES_REDUCER_NAME)

export const fetchAllPalletTypesReducers = fetchAllPalletTypesSlice.reducer
export const fetchAllPalletTypesActions = fetchAllPalletTypesSlice.actions

export const fetchAllPalletTypesThunk = () => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchShipmentsDataThunk({
        dispatch,
        actions: fetchAllPalletTypesActions,
        asyncFunc: () => fetchAllPalletTypes(),
      })
    } catch (error) {
      logger.error('Error fetching pallet types', error)
    }
  }
}

const fetchAllTransportModesSilce = makeFetchSlice(SHIPMENTS_ALL_TRANSPORT_MODES_REDUCER_NAME)

export const fetchAllTransportModesReducers = fetchAllTransportModesSilce.reducer
export const fetchAllTransportModesActions = fetchAllTransportModesSilce.actions

export const fetchAllTransportModesThunk = () => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchShipmentsDataThunk({
        dispatch,
        actions: fetchAllTransportModesActions,
        asyncFunc: () => fetchAllTransportModes(),
      })
    } catch (error) {
      logger.error('Error fetching transport modes', error)
    }
  }
}

const fetchAllLocationTypesSilce = makeFetchSlice(SHIPMENTS_ALL_LOCATION_TYPES_REDUCER_NAME)

export const fetchAllLocationTypesReducers = fetchAllLocationTypesSilce.reducer
export const fetchAllLocationTypesActions = fetchAllLocationTypesSilce.actions

export const fetchAllLocationTypesThunk = () => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchShipmentsDataThunk({
        dispatch,
        actions: fetchAllLocationTypesActions,
        asyncFunc: () => fetchAllLocationTypes(),
      })
    } catch (error) {
      logger.error('Error fetching transport modes', error)
    }
  }
}

const fetchLocationsByTenantOrCustomerSilce = makeFetchSlice(
  SHIPMENTS_ALL_LOCATIONS_BY_TENANT_CUSTOMER_REDUCER_NAME
)

export const fetchLocationsByTenantOrCustomerReducers =
  fetchLocationsByTenantOrCustomerSilce.reducer
export const fetchLocationsByTenantOrCustomerActions = fetchLocationsByTenantOrCustomerSilce.actions

export const fetchLocationsByTenantOrCustomerThunk = (
  tenantId: number,
  customerId?: any,
  includeCustomerLocations?: boolean
) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchShipmentsDataThunk({
        dispatch,
        actions: fetchLocationsByTenantOrCustomerActions,
        asyncFunc: () =>
          fetchLocationsByCustomerOrTenant(tenantId, includeCustomerLocations, customerId),
      })
    } catch (error) {
      logger.error('Error fetching locations for customers or tenants', error)
    }
  }
}

const fetchShipmentByIdSilce = makeFetchSlice(SHIPMENTS_BY_ID_REDUCER_NAME)

export const fetchShipmentByIdReducers = fetchShipmentByIdSilce.reducer
export const fetchShipmentByIdActions = fetchShipmentByIdSilce.actions

export const fetchShipmentByIdThunk = (shipmentId: number, tenantId: number, customerId?: any) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchShipmentsDataThunk({
        dispatch,
        actions: fetchShipmentByIdActions,
        asyncFunc: () => fetchShipmentById(shipmentId, tenantId, customerId),
      })
    } catch (error) {
      logger.error('Error fetching locations for customers or tenants', error)
    }
  }
}

const fetchAllCustomersByTenantIdSlice = makeFetchSlice(
  SHIPMENTS_CUSTOMERS_BY_TENANT_ID_REDUCER_NAME
)

export const fetchAllCustomersByTenantIdReducers = fetchAllCustomersByTenantIdSlice.reducer
export const fetchAllCustomersByTenantIdActions = fetchAllCustomersByTenantIdSlice.actions

export const fetchAllCustomersByTenantIdThunk = (tenantId: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchShipmentsDataThunk({
        dispatch,
        actions: fetchAllCustomersByTenantIdActions,
        asyncFunc: () => fetchAllCustomersByTenantId(tenantId),
      })
    } catch (error) {
      logger.error('Error fetching locations for customers or tenants', error)
    }
  }
}

const fetchAllCurrenciesByTenantIdSlice = makeFetchSlice(SHIPMENTS_ALL_CURRENCIES_REDUCER_NAME)

export const fetchAllCurrenciesByTenantIdReducers = fetchAllCurrenciesByTenantIdSlice.reducer
export const fetchAllCurrenciesByTenantIdActions = fetchAllCurrenciesByTenantIdSlice.actions

export const fetchAllCurrenciesByTenantIdThunk = (tenantId: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchShipmentsDataThunk({
        dispatch,
        actions: fetchAllCurrenciesByTenantIdActions,
        asyncFunc: () => fetchAllTenantCurrencies(tenantId),
      })
    } catch (error) {
      logger.error('Error fetching locations for customers or tenants', error)
    }
  }
}
