import logger from '../../app/middleware/log'
import { fetchShipmentsDataThunk, makeFetchSlice } from '../../app/redux-fetch'
import { AppDispatch } from '../../app/store/store'
import {
  fetchAssetConfiguration,
  fetchJourneys,
  fetchTransportsAssets,
  fetchTransportsEmployee,
  fetchTransportsLegById,
  fetchTransportsLoads,
} from './api'
import {
  JOURNEYS_BY_TENANT_ID_REDUCER_NAME,
  TRANSPORTS_ASSETS_CONFIGURATION_REDUCER_NAME,
  TRANSPORTS_ASSETS_REDUCER_NAME,
  TRANSPORTS_EMPLOYEE_REDUCER_NAME,
  TRANSPORTS_LEG_BY_ID_REDUCER_NAME,
  TRANSPORTS_LOADS_REDUCER_NAME,
} from './consts'

const fetchTransportsLegSilce = makeFetchSlice(JOURNEYS_BY_TENANT_ID_REDUCER_NAME)

export const fetchTransportsLegReducers = fetchTransportsLegSilce.reducer
export const fetchTransportsLegActions = fetchTransportsLegSilce.actions

export const fetchJourneysThunk = (tenantId: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchShipmentsDataThunk({
        dispatch,
        actions: fetchTransportsLegActions,
        asyncFunc: () => fetchJourneys(tenantId),
      })
    } catch (error) {
      logger.error('Error fetching transport leg', error)
    }
  }
}

const fetchTransportsLegByIdSilce = makeFetchSlice(TRANSPORTS_LEG_BY_ID_REDUCER_NAME)

export const fetchTransportsLegByIdReducers = fetchTransportsLegByIdSilce.reducer
export const fetchTransportsLegByIdActions = fetchTransportsLegByIdSilce.actions

export const fetchTransportsLegByIdThunk = (tenantId: number, id: string) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchShipmentsDataThunk({
        dispatch,
        actions: fetchTransportsLegByIdActions,
        asyncFunc: () => fetchTransportsLegById(tenantId, id),
      })
    } catch (error) {
      logger.error('Error fetching transport leg by id', error)
    }
  }
}

const fetchTransportsLoadsSilce = makeFetchSlice(TRANSPORTS_LOADS_REDUCER_NAME)

export const fetchTransportsLoadsReducers = fetchTransportsLoadsSilce.reducer
export const fetchTransportsLoadsActions = fetchTransportsLoadsSilce.actions

export const fetchTransportsLoadsThunk = (tenantId: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchShipmentsDataThunk({
        dispatch,
        actions: fetchTransportsLoadsActions,
        asyncFunc: () => fetchTransportsLoads(tenantId),
      })
    } catch (error) {
      logger.error('Error fetching transport loads', error)
    }
  }
}

const fetchTransportsAssetsSilce = makeFetchSlice(TRANSPORTS_ASSETS_REDUCER_NAME)

export const fetchTransportsAssetsReducers = fetchTransportsAssetsSilce.reducer
export const fetchTransportsAssetsActions = fetchTransportsAssetsSilce.actions

export const fetchTransportsAssetsThunk = (tenantId: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchShipmentsDataThunk({
        dispatch,
        actions: fetchTransportsAssetsActions,
        asyncFunc: () => fetchTransportsAssets(tenantId),
      })
    } catch (error) {
      logger.error('Error fetching transport assets', error)
    }
  }
}

const fetchTransportsEmployeeSilce = makeFetchSlice(TRANSPORTS_EMPLOYEE_REDUCER_NAME)

export const fetchTransportsEmployeeReducers = fetchTransportsEmployeeSilce.reducer
export const fetchTransportsEmployeeActions = fetchTransportsEmployeeSilce.actions

export const fetchTransportsEmployeeThunk = (tenantId: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchShipmentsDataThunk({
        dispatch,
        actions: fetchTransportsEmployeeActions,
        asyncFunc: () => fetchTransportsEmployee(tenantId),
      })
    } catch (error) {
      logger.error('Error fetching transport employee', error)
    }
  }
}

const fetchTransportAssetConfigurationSlice = makeFetchSlice(
  TRANSPORTS_ASSETS_CONFIGURATION_REDUCER_NAME
)

export const fetchTransportAssetConfigurationReducers =
  fetchTransportAssetConfigurationSlice.reducer
export const fetchTransportAssetConfigurationActions = fetchTransportAssetConfigurationSlice.actions

export const fetchTransportAssetConfigurationThunk = (tenantId: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchShipmentsDataThunk({
        dispatch,
        actions: fetchTransportAssetConfigurationActions,
        asyncFunc: () => fetchAssetConfiguration(tenantId),
      })
    } catch (error) {
      logger.error('Error fetching transport employee', error)
    }
  }
}
