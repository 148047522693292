import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'tenantSettings.title',
    description: 'Tenant Settings title',
    defaultMessage: 'Tenant Settings',
  },
  brandingSettingsTab: {
    id: 'tenantSettings.tabs.brandingSettings',
    description: 'Branding tab',
    defaultMessage: 'Branding',
  },
  assetsSettingTab: {
    id: 'tenantSettings.tabs.assetSettings',
    description: 'Assets configuration tab',
    defaultMessage: 'Asset settings',
  },
  assetsTransportMode: {
    id: 'tenantSettings.asset.transportModeDropdown',
    description: 'Assets transport mode dropdown title',
    defaultMessage: 'Transport mode',
  },
  assetsCategories: {
    id: 'tenantSettings.asset.categories',
    description: 'Assets categories title',
    defaultMessage: 'Asset Categories',
  },
  assetsClasses: {
    id: 'tenantSettings.asset.classes',
    description: 'Assets classes title',
    defaultMessage: 'Asset Classes',
  },
  assetsTypes: {
    id: 'tenantSettings.asset.types',
    description: 'Assets types title',
    defaultMessage: 'Asset Types',
  },
  assetsDataGridNameHeader: {
    id: 'tenantSettings.asset.nameHeader',
    description: 'Assets data grid column name header',
    defaultMessage: 'Name',
  },
  assetsDataGridEnabledHeader: {
    id: 'tenantSettings.asset.enabledHeader',
    description: 'Assets data grid clolumn enabled header',
    defaultMessage: 'Enabled',
  },
  assetsDataGridCustomerSelectableHeader: {
    id: 'tenantSettings.asset.customerSelectableHeader',
    description: 'Assets data grid clolumn customer selectable header',
    defaultMessage: 'Customer selectable',
  },
  assetsDataGridDeleteHeader: {
    id: 'tenantSettings.asset.deleteHeader',
    description: 'Assets data grid clolumn delete header',
    defaultMessage: 'Delete',
  },
  assetsSaveSettings: {
    id: 'tenantSettings.asset.saveSettings',
    description: 'Assets save settings button',
    defaultMessage: 'Save settings',
  },
  colorPrimary: {
    id: 'tenantSettings.colorPrimary',
    description: 'Primary Color',
    defaultMessage: 'Primary Palette Color',
  },
  colorSecondary: {
    id: 'tenantSettings.colorSecondary',
    description: 'Secondary Color',
    defaultMessage: 'Secondary Palette Color',
  },
  colorContrastText: {
    id: 'tenantSettings.colorContrastText',
    description: 'Contrast Text Color',
    defaultMessage: 'Contrast Text Color',
  },
  colorText: {
    id: 'tenantSettings.colorText',
    description: 'Text Color',
    defaultMessage: 'Text Color',
  },
  saveBranding: {
    id: 'tenantSettings.saveBranding',
    description: 'Save Branding',
    defaultMessage: 'Save',
  },
  resetToDefault: {
    id: 'tenantSettings.resetToDefault',
    description: 'Reset to default branding',
    defaultMessage: 'Reset',
  },
})

export default messages
