import { AxiosResponse } from 'axios'
import { usersAuthorizedFetch } from '../../../../app/axios/omnialog'
import { IUserPayload } from './types'

export const fetchAllUserByCustomerId = async ({
  customerId,
  tenantId,
}: Pick<IUserPayload, 'tenantId' | 'customerId'>): Promise<AxiosResponse<any, any>> => {
  if (!customerId) return await usersAuthorizedFetch.get(`/tenant/${tenantId}/user`)
  return await usersAuthorizedFetch.get(`/tenant/${tenantId}/customer/${customerId}/user`)
}
export const postUserByCustomerId = async ({
  customerId,
  tenantId,
  user,
}: IUserPayload): Promise<AxiosResponse<any, any>> => {
  if (!customerId) return await usersAuthorizedFetch.post(`/tenant/${tenantId}/user`, user)
  return await usersAuthorizedFetch.post(`/tenant/${tenantId}/customer/${customerId}/user`, user)
}

export const putUserByCustomerId = async ({
  customerId,
  tenantId,
  user,
}: IUserPayload): Promise<AxiosResponse<any, any>> => {
  if (!customerId) return await usersAuthorizedFetch.put(`/tenant/${tenantId}/user`, user)
  return await usersAuthorizedFetch.put(`/tenant/${tenantId}/customer/${customerId}/user`, user)
}
export const deleteUserByCustomerId = async ({
  customerId,
  tenantId,
  user,
}: IUserPayload): Promise<AxiosResponse<any, any>> => {
  if (!customerId) return await usersAuthorizedFetch.delete(`/tenant/${tenantId}/user/${user.id}`)
  return await usersAuthorizedFetch.delete(
    `/tenant/${tenantId}/customer/${customerId}/user/${user.id}`
  )
}

export const fetchUserType = async (): Promise<AxiosResponse<any, any>> =>
  usersAuthorizedFetch.get('/user-type')
