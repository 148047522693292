import { type ReportHandler } from 'web-vitals'
import logger from './app/middleware/log'

const reportWebVitals = (onPerfEntry?: ReportHandler): void => {
  if (onPerfEntry != null && onPerfEntry instanceof Function) {
    import('web-vitals').then(
      ({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
        getCLS(onPerfEntry)
        getFID(onPerfEntry)
        getFCP(onPerfEntry)
        getLCP(onPerfEntry)
        getTTFB(onPerfEntry)
      },
      () => {
        logger.info('reportWebVitals finished')
      }
    )
  }
}

export default reportWebVitals
