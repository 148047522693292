import DeleteIcon from '@mui/icons-material/Delete';
import CopyIcon from '@mui/icons-material/FileCopy';
import { AccordionDetails, FormControlLabel, Grid, IconButton, Switch } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useGetMeasureUnitsQuery } from '../../app/redux-fetch/apiQuery';
import { useGetAllPackagingTemplatesQuery, useGetAllPackagingTypesQuery } from '../../app/redux-fetch/apiShipment';
import { convertToLabelValue } from '../../app/utils';
import StyledAccordion from '../../components/Accordions/StyledAccordion';
import StyledAccordionSummary from '../../components/Accordions/StyledAccordionSummary';
import BoxWhiteContainer from '../../components/Containers/BoxWhiteContainer';
import CombinedSelect from '../../components/Forms/CombinedSelect';
import FormInput from '../../components/Forms/FormInput';
import FormSelect from '../../components/Forms/FormSelect';
import { AccordionHeader } from '../../components/Headers/styles';
import commonMessages from '../../components/Messages/commonMessages';
import InfoDisplayItem from '../../components/Typographies/InfoDisplayItem';
import { LabelItem } from '../../components/Typographies/LabelItem';
import PackageContentForm from './PackageContentForm';
import TemperatureControlledForm from './TemperatureControlledForm';
import { useShipmentFormContext } from './context/ShipmentFormContext';
import messages from './messages';

interface PackagingTemplate {
    id: number;
    name: string;
    packagingTypeId: number;
    physicalProperties: {
        length: number;
        width: number;
        height: number;
        lengthMeasureUnitId: number;
    };
}

interface MeasureUnit {
    id: number;
    units: string;
    measureTypeId: number;
}


interface PackagingItemProps {
    consignmentIndex: number;
    packagingIndex: number;
    itemId: string;
    remove: (index: number) => void;
    copy: (index: number) => void;
}

const PackagingItem: React.FC<PackagingItemProps> = ({
    consignmentIndex,
    packagingIndex,
    itemId,
    remove,
    copy,
}) => {
    const { watch, control, setValue } = useFormContext();
    const { formatMessage } = useIntl();

    const packagingData =
        watch(`consignments.${consignmentIndex}.packaging.${packagingIndex}`) ?? {};

    console.log('🚀 ~ packagingData:', packagingData)

    const {
        packagingTypeId,
        weight,
        weightUnit,
        length,
        width,
        height,
        dimensionUnit,
        temperatureControlled,
        setPoint: temperatureSetPoint,
        containerType,
        numberOfPackages,
        setPointUnitId,
    } = packagingData;

    const [expanded, setExpanded] = useState(true);

    const { currentPage } = useShipmentFormContext();
    const { data: measureUnits = [] } = useGetMeasureUnitsQuery();

    const { data: packagingTypes } = useGetAllPackagingTypesQuery();

    const { data: packagingTemplates = [] } = useGetAllPackagingTemplatesQuery();

    const palletPackagingTemplatesOptions = useMemo(
        () =>
            convertToLabelValue(
                packagingTemplates
                    .filter((x: PackagingTemplate) => x.packagingTypeId === 2)
                    .map((x: PackagingTemplate) => ({ name: x.name, id: x.id }))
            ),
        [packagingTemplates]
    );

    const resetFields = (fields: any) => {
        fields.forEach((field: any) => {
            setValue(
                `consignments.${consignmentIndex}.packaging.${packagingIndex}.${field}`,
                undefined
            );
        });
    };


    const containerTemplateOptions = useMemo(
        () =>
            convertToLabelValue(
                packagingTemplates
                    .filter((x: any) => x.packagingTypeId === 1)
                    .map((x: any) => {
                        return { name: x.name, id: x.id };
                    })
            ),
        [packagingTemplates]
    );

    const massUnitOptions = useMemo(
        () =>
            convertToLabelValue(
                measureUnits
                    .filter((x) => x.measureTypeId === 1)
                    .map((x) => {
                        return { name: x.units, id: x.id };
                    })
            ),
        [measureUnits]
    );


    const getTemperatureUnit = useCallback(
        (id: number) => {
            return measureUnits.find((unit) => unit.id === id)?.units;
        },
        [measureUnits]
    );

    const lengthUnitOptions = useMemo(
        () =>
            convertToLabelValue(
                measureUnits
                    .filter((x) => x.measureTypeId === 4)
                    .map((x) => {
                        return { name: x.units, id: x.id };
                    })
            ),
        [measureUnits]
    );

    const getDimensionUnit = (unitId: number) => {
        return measureUnits.find((x) => x.id === unitId)?.units ?? '';
    };

    const handleToggleAccordion = useCallback(() => {
        setExpanded((prev) => !prev);
    }, []);

    const handlePackagingTypeChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const newPackagingTypeId = Number(event.target.value);
        setValue(
            `consignments.${consignmentIndex}.packaging.${packagingIndex}.packagingTypeId`,
            newPackagingTypeId
        );

        const fieldsToResetMap: { [key: number]: string[] } = {
            1: ['weight', 'weightUnit', 'length', 'width', 'height', 'dimensionUnit'],
            2: ['packagingTemplateId', 'containerType', 'weightUnit', 'dimensionUnit'],
            3: ['packagingTemplateId', 'containerType'],
        };

        const fieldsToReset = [
            'packagingTemplateId',
            'containerType',
            'weight',
            'weightUnit',
            'length',
            'width',
            'height',
            'dimensionUnit',
        ];

        resetFields(fieldsToReset);
    };

    // Handler for packaging template change
    const handlePackagingTemplateChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const newPackagingTemplateId = Number(event.target.value);
        setValue(
            `consignments.${consignmentIndex}.packaging.${packagingIndex}.packagingTemplateId`,
            newPackagingTemplateId
        );

        const selectedTemplate = packagingTemplates.find(
            (template: PackagingTemplate) => template.id === newPackagingTemplateId
        );

        if (selectedTemplate) {
            const {
                physicalProperties: {
                    length: templateLength,
                    width: templateWidth,
                    height: templateHeight,
                    lengthMeasureUnitId: templateDimensionUnit,
                },
            } = selectedTemplate;

            setValue(
                `consignments.${consignmentIndex}.packaging.${packagingIndex}.length`,
                templateLength !== undefined ? Number(templateLength) : undefined
            );
            setValue(
                `consignments.${consignmentIndex}.packaging.${packagingIndex}.width`,
                templateWidth !== undefined ? Number(templateWidth) : undefined
            );
            setValue(
                `consignments.${consignmentIndex}.packaging.${packagingIndex}.height`,
                templateHeight !== undefined ? Number(templateHeight) : undefined
            );
            setValue(
                `consignments.${consignmentIndex}.packaging.${packagingIndex}.dimensionUnit`,
                templateDimensionUnit !== undefined ? Number(templateDimensionUnit) : undefined
            );

            resetFields(['weight', 'weightUnit']);
        } else {
            resetFields(['length', 'width', 'height', 'dimensionUnit']);
        }
    };


    const renderSummaryPage = () => {
        if (currentPage !== 3) return null;

        return (
            <Grid container>
                <Grid item xs={12} md={12}>
                    <BoxWhiteContainer sx={{ mb: '32px' }}>
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                md={4}
                                sx={{ display: 'flex', justifyContent: 'flex-start' }}
                            >
                                <LabelItem>
                                    {formatMessage(messages.consignmentPackaging)} #{packagingIndex + 1}
                                </LabelItem>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={2}
                                sx={{ display: 'flex', justifyContent: 'flex-start' }}
                            >
                                <InfoDisplayItem
                                    label={formatMessage(messages.packagingType)}
                                    value={
                                        packagingTypes.find((x: any) => x.id === packagingTypeId)?.name ??
                                        formatMessage(commonMessages.na)
                                    }
                                    isSmallScreen={false}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={2}
                                sx={{ display: 'flex', justifyContent: 'flex-start' }}
                            >
                                <InfoDisplayItem
                                    label={formatMessage(messages.noOfIdenticalPackages)}
                                    value={numberOfPackages ?? formatMessage(commonMessages.na)}
                                    isSmallScreen={false}
                                />
                            </Grid>
                            {packagingTypeId === 3 && (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        md={2}
                                        sx={{ display: 'flex', justifyContent: 'flex-start' }}
                                    >
                                        <InfoDisplayItem
                                            label={formatMessage(messages.weight)}
                                            value={`${weight ?? 'N/A'} ${weightUnit ?? ''}`}
                                            isSmallScreen={false}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        md={2}
                                        sx={{ display: 'flex', justifyContent: 'flex-start' }}
                                    >
                                        <InfoDisplayItem
                                            label={formatMessage(messages.dimensions)}
                                            value={`${length ?? 'N/A'}x${width ?? 'N/A'}x${height ?? 'N/A'} ${getDimensionUnit(dimensionUnit) ?? ''
                                                }`}
                                            isSmallScreen={false}
                                        />
                                    </Grid>
                                </>
                            )}

                            {packagingTypeId === 2 && (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        md={2}
                                        sx={{ display: 'flex', justifyContent: 'flex-start' }}
                                    >
                                        <InfoDisplayItem
                                            label={formatMessage(messages.width)}
                                            value={`${width ?? formatMessage(commonMessages.na)
                                                } ${getDimensionUnit(dimensionUnit) ?? ''}`}
                                            isSmallScreen={false}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        md={2}
                                        sx={{ display: 'flex', justifyContent: 'flex-start' }}
                                    >
                                        <InfoDisplayItem
                                            label={formatMessage(messages.height)}
                                            value={`${height ?? 'N/A'
                                                } ${getDimensionUnit(dimensionUnit) ?? ''}`}
                                            isSmallScreen={false}
                                        />
                                    </Grid>
                                </>
                            )}

                            {packagingTypeId === 1 && (
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        md={2}
                                        sx={{ display: 'flex', justifyContent: 'flex-start' }}
                                    >
                                        <InfoDisplayItem
                                            label={formatMessage(messages.containerType)}
                                            value={containerType ?? formatMessage(commonMessages.na)}
                                            isSmallScreen={false}
                                        />
                                    </Grid>
                                </>
                            )}

                            {temperatureControlled && (
                                <Grid
                                    item
                                    xs={12}
                                    md={2}
                                    sx={{ display: 'flex', justifyContent: 'flex-start' }}
                                >
                                    <InfoDisplayItem
                                        label={formatMessage(messages.temperatureSetPoint)}
                                        value={`${temperatureSetPoint} ${getTemperatureUnit(setPointUnitId)}`}
                                        isSmallScreen={false}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </BoxWhiteContainer>
                </Grid>
                <Grid item xs={12} md={12}>
                    <PackageContentForm
                        consignmentIndex={consignmentIndex}
                        packagingIndex={packagingIndex}
                    />
                </Grid>
            </Grid>
        );
    };

    const renderPackagingItemForm = () => {
        if (currentPage !== 2) return null;
        return (
            <StyledAccordion
                key={itemId}
                expanded={expanded}
                onChange={handleToggleAccordion}
            >
                <StyledAccordionSummary
                    index={`packaging-${packagingIndex + 1}`}
                    expanded={expanded}
                >
                    <AccordionHeader sx={{ flexGrow: 1 }}>
                        {formatMessage(messages.consignmentPackaging)} #{packagingIndex + 1}
                    </AccordionHeader>
                    <IconButton onClick={() => copy(packagingIndex)} color="primary">
                        <CopyIcon />
                    </IconButton>
                    <IconButton onClick={() => remove(packagingIndex)} color="error">
                        <DeleteIcon />
                    </IconButton>
                </StyledAccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <FormSelect
                                name={`consignments.${consignmentIndex}.packaging.${packagingIndex}.packagingTypeId`}
                                label={formatMessage(messages.packagingType)}
                                options={convertToLabelValue(packagingTypes ?? [])}
                                placeholder={formatMessage(commonMessages.select)}
                                onChange={handlePackagingTypeChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <FormInput
                                name={`consignments.${consignmentIndex}.packaging.${packagingIndex}.numberOfPackages`}
                                label={formatMessage(messages.noOfIdenticalPackages)}
                                type="number"
                                fullWidth
                            />
                        </Grid>
                        {packagingTypeId === 1 && ( // Container
                            <>
                                <Grid item xs={12} md={4}>
                                    <FormSelect
                                        name={`consignments.${consignmentIndex}.packaging.${packagingIndex}.packagingTemplateId`}
                                        label={formatMessage(messages.containerType)}
                                        options={containerTemplateOptions}
                                        onChange={handlePackagingTemplateChange}
                                        placeholder="Please select"
                                        fullWidth
                                    />
                                </Grid>
                            </>
                        )}
                        {packagingTypeId === 2 && ( // Pallet
                            <>
                                <Grid item xs={12} md={4}>
                                    <FormSelect
                                        name={`consignments.${consignmentIndex}.packaging.${packagingIndex}.packagingTemplateId`}
                                        label={formatMessage(messages.palletType)}
                                        options={palletPackagingTemplatesOptions}
                                        onChange={handlePackagingTemplateChange}
                                        placeholder="Please select"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <CombinedSelect
                                        nameValue={`consignments.${consignmentIndex}.packaging.${packagingIndex}.weight`}
                                        nameUnit={`consignments.${consignmentIndex}.packaging.${packagingIndex}.weightUnit`}
                                        labelValue={formatMessage(messages.weight)}
                                        optionsUnit={massUnitOptions}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <CombinedSelect
                                        nameValue={`consignments.${consignmentIndex}.packaging.${packagingIndex}.height`}
                                        nameUnit={`consignments.${consignmentIndex}.packaging.${packagingIndex}.dimensionUnit`}
                                        labelValue={formatMessage(messages.height)}
                                        optionsUnit={lengthUnitOptions}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} alignContent={'center'}>
                                    <Controller
                                        name={`consignments.${consignmentIndex}.packaging.${packagingIndex}.isStackable`}
                                        control={control}
                                        defaultValue={false}
                                        render={({ field }) => (
                                            <FormControlLabel
                                                control={<Switch {...field} checked={field.value} />}
                                                label={formatMessage(messages.isStackable)}
                                            />
                                        )}
                                    />
                                </Grid>
                            </>
                        )}
                        {packagingTypeId === 3 && ( // Parcel
                            <>
                                <Grid item xs={12} md={4}>
                                    <CombinedSelect
                                        nameValue={`consignments.${consignmentIndex}.packaging.${packagingIndex}.weight`}
                                        nameUnit={`consignments.${consignmentIndex}.packaging.${packagingIndex}.weightUnit`}
                                        labelValue={formatMessage(messages.weight)}
                                        optionsUnit={massUnitOptions}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <CombinedSelect
                                        nameValue={`consignments.${consignmentIndex}.packaging.${packagingIndex}.length`}
                                        nameUnit={`consignments.${consignmentIndex}.packaging.${packagingIndex}.dimensionUnit`}
                                        labelValue={formatMessage(messages.length)}
                                        optionsUnit={lengthUnitOptions}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <CombinedSelect
                                        nameValue={`consignments.${consignmentIndex}.packaging.${packagingIndex}.width`}
                                        nameUnit={`consignments.${consignmentIndex}.packaging.${packagingIndex}.dimensionUnit`}
                                        labelValue={formatMessage(messages.width)}
                                        optionsUnit={lengthUnitOptions}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <CombinedSelect
                                        nameValue={`consignments.${consignmentIndex}.packaging.${packagingIndex}.height`}
                                        nameUnit={`consignments.${consignmentIndex}.packaging.${packagingIndex}.dimensionUnit`}
                                        labelValue={formatMessage(messages.height)}
                                        optionsUnit={lengthUnitOptions}
                                    />
                                </Grid>
                            </>
                        )}
                        <TemperatureControlledForm
                            consignmentIndex={consignmentIndex}
                            packagingIndex={packagingIndex}
                        />
                    </Grid>
                </AccordionDetails>
            </StyledAccordion>
        );
    };

    return (
        <>
            {renderSummaryPage()}
            {renderPackagingItemForm()}
        </>
    );
};

export default PackagingItem;
