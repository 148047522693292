import { Typography, TypographyProps, styled } from '@mui/material';

export const TableTitleTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: '#636363',
    fontWeight: 700,
    fontSize: '32px',
  }))

  export const TableSubTitleTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: '#636363',
    fontWeight: 700,
    fontSize: '20px',
  }))