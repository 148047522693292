import { Accordion, AccordionProps } from '@mui/material'
import React from 'react'

type StyledAccordionProps = AccordionProps & {
    children: React.ReactNode
}

const StyledAccordion: React.FC<StyledAccordionProps> = ({ children, ...accordionProps }) => {
    return (
        <Accordion
            {...accordionProps}
            sx={(theme) => {
                const userSx = typeof accordionProps.sx === 'function' ? accordionProps.sx(theme) : accordionProps.sx;
                return {
                    ...userSx as object,
                    borderRadius: theme.palette?.accordion.border?.radius || '4px',
                    '&.MuiAccordion-root:before': {
                        display: 'none',
                    },
                    mt: '8px',
                    '&:first-of-type': {
                        borderTopLeftRadius: '4px',  // Add custom radius for top corners
                        borderTopRightRadius: '4px',
                    },
                    '&:last-of-type': {
                        borderBottomLeftRadius: '4px',  // Add custom radius for bottom corners if needed
                        borderBottomRightRadius: '4px',
                    },
                }
            }}
        >
            {children ?? <></>}
        </Accordion>
    )
}

export default StyledAccordion
