/* eslint-disable */
import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  title: {
    id: 'contact.title',
    description: 'Contanct form title',
    defaultMessage: 'Contacts',
  },
  name: {
    id: 'contact.name',
    description: 'Name of contact',
    defaultMessage: 'Name',
  },
  newContact: {
    id: 'contact.newContact',
    description: 'Name of new contact',
    defaultMessage: 'New Contact',
  },
  email: {
    id: 'contact.email',
    description: 'Email label',
    defaultMessage: 'Email',
  },
  phone: {
    id: 'contact.phone',
    description: 'Phone label',
    defaultMessage: 'Phone',
  },
  contactType: {
    id: 'contact.contactType',
    description: 'Contact type label',
    defaultMessage: 'Contact type',
  },
  newButtonLbl: {
    id: 'contact.newButtonLbl',
    description: 'New button label',
    defaultMessage: 'New',
  },
  editButtonLbl: {
    id: 'contact.editButtonLbl',
    description: 'Edit link label',
    defaultMessage: 'Edit',
  },
  updateBtn: {
    id: 'contact.updateBtn',
    description: 'Udate button',
    defaultMessage: 'Update',
  },
  addBtn: {
    id: 'contact.addBtn',
    description: 'Add button lable',
    defaultMessage: 'Add',
  },
  cancelBtn: {
    id: 'contact.cancelBtn',
    description: 'Cancel btn',
    defaultMessage: 'Cancel',
  },
  viewButtonLbl: {
    id: 'contact.viewButtonLbl',
    description: 'View link label',
    defaultMessage: 'View',
  },
  deleteButtonLbl: {
    id: 'contact.deleteButtonLbl',
    description: 'Delete link label',
    defaultMessage: 'Del',
  },
})
