import {
  Autocomplete,
  FilterOptionsState,
  FormControl,
  FormHelperText,
  SxProps,
  TextField,
  Theme,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import React, { useCallback, useEffect, useMemo, useRef, useState, type ReactElement } from 'react'
import { useIntl } from 'react-intl'
import logger from '../../app/middleware/log'
import messages from './messages'

const StyledAutocomplete = styled(Autocomplete)({
  backgroundColor: 'transparent',
  '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
    // Default transform is 'translate(14px, 20px) scale(1)''
    // This lines up the label with the initial cursor position in the input
    // after changing its padding-left.
    transform: 'translate(34px, 20px) scale(1);',
  },
  '&.Mui-focused .MuiInputLabel-outlined': {
    color: 'black',
  },
  '& .MuiAutocomplete-inputRoot': {
    // padding: 0,
    paddingLeft: 7,
    border: 5,
    color: 'black',
    // This matches the specificity of the default styles at https://github.com/mui-org/material-ui/blob/v4.11.3/packages/material-ui-lab/src/Autocomplete/Autocomplete.js#L90
    '&[class*="MuiOutlinedInput - root"] .MuiAutocomplete-input:first-of-type': {
      // Default left padding is 6px
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#03A9F4',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: '#03A9F4',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#03A9F4',
    },
  },
})

export interface DropdownItem {
  id: number | string
  name: string
  symbol?: string
}

interface FormDropdownProps {
  error?: boolean
  errorText?: any
  id: string
  items: DropdownItem[]
  label?: string
  disabled?: boolean
  required?: boolean
  onChange: (event: any, name: string, value: any) => void
  handleOnBlur?: {
    /** Classic React blur handler, keyed by input name */
    (e: React.FocusEvent<any>): void
    /** Preact-like linkState. Will return a handleBlur function. */
    <T = string | any>(fieldOrEvent: T): T extends string ? (e: any) => void : void
  }
  sx?: SxProps<Theme> | undefined
  value: any
  defaultValue?: any
  showSystemEntitiesText?: string
  itemsFilterForNonSystemEntities?: (value: any, index?: number, Array?: any[]) => boolean
  noOptionsText?: string
  disableClearable?: boolean
  size?: 'small' | 'medium'
}

interface AutoCompleteOption {
  id: string | number
  key: string | number
  label: string
  value: string | number
}

const FormDropdown = (props: FormDropdownProps): ReactElement<any, any> => {
  const {
    error,
    handleOnBlur: onBlur,
    errorText,
    id,
    items,
    label,
    onChange,
    sx,
    value,
    defaultValue,
    disabled,
    showSystemEntitiesText,
    itemsFilterForNonSystemEntities,
    noOptionsText,
    disableClearable,
    size,
    required
  } = props

  const { formatMessage } = useIntl()
  const sortItems = useMemo(() => {
    return [...items].sort((a, b) => a.name.localeCompare(b.name))
  }, [items])

  const changeHandler =
    onChange == null
      ? () => {
        logger.log('not defined')
      }
      : onChange

  const [showSystemEntities, setShowSystemEntities] = useState(!itemsFilterForNonSystemEntities)
  const [isOpen, setIsOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState<number>(value)
  useEffect(() => {
    setSelectedValue(value)
  }, [value])
  const autoCompleteOptions = useMemo(() => {
    return sortItems
      .filter(
        (i) =>
          i.id == value ||
          showSystemEntities ||
          (itemsFilterForNonSystemEntities && itemsFilterForNonSystemEntities(i))
      )
      .map((item) => ({
        id: item.id,
        key: item.id,
        label: item.name,
        value: item.id,
      }))
  }, [
    items,
    itemsFilterForNonSystemEntities,
    value,
    showSystemEntitiesText,
    showSystemEntities,
    formatMessage,
  ])

  if (showSystemEntitiesText) {
    if (!autoCompleteOptions.some((item) => item.id === -1)) {
      autoCompleteOptions.unshift({
        id: -1,
        key: '',
        label: formatMessage(
          showSystemEntities ? messages.formDropDownHideSystem : messages.formDropDownShowSystem,
          { entities: showSystemEntitiesText }
        ),
        value: '',
      })
    }
  }

  let reconciledValue = useMemo(() => {
    return autoCompleteOptions.find((item) => item.id === (value ?? 0))
  }, [autoCompleteOptions, value, selectedValue])

  if (!reconciledValue || selectedValue === -1) {
    reconciledValue = { id: -1, key: '', label: '', value: '' }
  }

  const reconciledDefaultValue = autoCompleteOptions.find((item) => item.id === (defaultValue ?? 0))

  const inputReference = useRef<HTMLInputElement | null>(null)
  const handleOnChange = (event: any, newValue: any): void => {
    setSelectedValue(newValue.id)

    if (newValue.id == -1) {
      setShowSystemEntities(!showSystemEntities)
    } else {
      changeHandler(event, id, newValue.value)
    }
  }

  const filterOptions = useCallback(
    (options: unknown[], { inputValue }: FilterOptionsState<{ inputValue: string }>) => {
      if (!inputValue) {
        return options
      }
      const sortItems = options as AutoCompleteOption[]
      const input = inputValue.toLowerCase() || ''
      return sortItems.filter(({ label }) => label.toLowerCase().includes(input))
    },
    []
  )
  return (
    <FormControl
      fullWidth={true}
      error={Boolean(error)}
      sx={sx}
      style={{ backgroundColor: 'transparent' }}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <StyledAutocomplete
        id={id}
        disableClearable={disableClearable ? disableClearable : true}
        disabled={disabled}
        noOptionsText={noOptionsText ? noOptionsText : 'No options'}
        onBlur={onBlur}
        onOpen={() => setIsOpen(true)}
        onClose={(e: any) => {
          const result = parseInt(e.target.getAttribute('data-entity-id')) < 0
          setIsOpen(result)
          if (!result) {
            setSelectedValue(value)
          }
        }}
        open={isOpen}
        defaultValue={reconciledDefaultValue}
        onChange={handleOnChange}
        options={autoCompleteOptions}
        renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: any) => (
          <li
            {...props}
            data-entity-id={option.id}
            style={{ fontWeight: parseInt(option.id ?? '0') < 0 ? 'bold' : '' }}
          >
            {option.label}
          </li>
        )}
        renderInput={(params: any) => (
          <TextField
            {...params}
            label={label}
            required={required ? required : false}
            ref={inputReference}
            placeholder={formatMessage(messages.formDropDownSelectOption)}
            InputLabelProps={{ shrink: true }}
            size={size}
          />
        )}
        value={reconciledValue}
        filterOptions={filterOptions}
      />
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  )
}

export default FormDropdown
