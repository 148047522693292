import logger from '../../../app/middleware/log'
import { fetchBrandingDataThunk, makeFetchSlice } from '../../../app/redux-fetch'
import { AppDispatch } from '../../../app/store/store'
import { getIdentityProviderIntegrationByTenantCustomerId, getOnBoarding } from './api'
import {
  TENANT_ALL_IDENTITY_PROVIDER_ID_REDUCER_NAME,
  TENANT_ALL_ON_BOARDING_REDUCER_NAME,
} from './consts'

const fetchOffice365OnBoardingSlice = makeFetchSlice(TENANT_ALL_ON_BOARDING_REDUCER_NAME)

export const fetchOffice365OnBoardingReducers = fetchOffice365OnBoardingSlice.reducer
export const fetchOffice365OnBoardingActions = fetchOffice365OnBoardingSlice.actions

export const fetchOffice365OnBoardingThunk = (
  tenantId: number,
  identityProviderIntegrationTypeId: number
) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchBrandingDataThunk({
        dispatch,
        actions: fetchOffice365OnBoardingActions,
        asyncFunc: () => getOnBoarding(tenantId, identityProviderIntegrationTypeId),
      })
    } catch (error) {
      logger.error('🚀 Error fetching office 356 on boarding', error)
    }
  }
}

const fetchIdentityProviderIntegrationIdSlice = makeFetchSlice(
  TENANT_ALL_IDENTITY_PROVIDER_ID_REDUCER_NAME
)

export const fetchIdentityProviderIntegrationIdReducers =
  fetchIdentityProviderIntegrationIdSlice.reducer
export const fetchIdentityProviderIntegrationIdActions =
  fetchIdentityProviderIntegrationIdSlice.actions

export const fetchIdentityProviderIntegrationIdThunk = (tenantId: number, customerId?: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchBrandingDataThunk({
        dispatch,
        actions: fetchIdentityProviderIntegrationIdActions,
        asyncFunc: () => getIdentityProviderIntegrationByTenantCustomerId(tenantId, customerId),
      })
    } catch (error) {
      logger.log('🚀 Error fetching identity provider integration id', error)
    }
  }
}
