import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import { ColorChangeHandler, ColorResult, SketchPicker } from 'react-color'
import reactCSS from 'reactcss'

export interface SwatchColorPickerProps {
  name?: string
  color?: string
  onChangeComplete?: ColorChangeHandler
}

function SwatchColorPicker({ name, color, onChangeComplete }: SwatchColorPickerProps) {
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false)
  const [state, setState] = React.useState<Pick<ColorResult, 'hex'>>({
    hex: `${color}`,
  })
  useEffect(() => {
    setState({ hex: `${color}` })
  }, [color])
  const handleClick = (event: any) => {
    event.stopPropagation()
    event.preventDefault()
    setDisplayColorPicker(!displayColorPicker)
  }

  const handleClose = () => {
    setDisplayColorPicker(false)
  }

  const handleChange: ColorChangeHandler = (color: ColorResult) => {
    setState({ ...color })
  }

  const styles = reactCSS({
    default: {
      container: {
        textTransform: 'uppercase' as const,
        margin: '20px 0',
      },
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `${state.hex}`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-flex',
        cursor: 'pointer',
        margin: '0 10px',
        zIndex: '2',
      },
      popover: {
        position: 'absolute' as const,
        zIndex: '3',
      },
      cover: {
        position: 'fixed' as const,
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  })

  return (
    <Box>
      <div style={styles.container}>
        <div style={styles.swatch} onClick={handleClick}>
          <div style={styles.color} />
        </div>
        <span>{name}</span>
        {displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={handleClose} />
            <SketchPicker
              color={state.hex}
              onChange={handleChange}
              onChangeComplete={onChangeComplete}
            />
          </div>
        ) : null}
      </div>
    </Box>
  )
}

export default SwatchColorPicker
