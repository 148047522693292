import * as loglevel from 'loglevel'
import { LOG_LEVEL } from '../axios/const'

const allowedLogLevels: loglevel.LogLevelDesc[] = ['trace', 'debug', 'info', 'warn', 'error']

const getLogLevel = (): loglevel.LogLevelDesc => {
  const envLogLevel = LOG_LEVEL as loglevel.LogLevelDesc

  if (allowedLogLevels.includes(envLogLevel)) {
    return envLogLevel
  }

  return 'error'
}

const currentLogLevel = getLogLevel()
loglevel.setLevel(currentLogLevel)

const logger = loglevel
export default logger
