import Tab from '@mui/material/Tab'
import { styled } from '@mui/material/styles'

const StyledTab = styled(Tab)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    borderRadius: '5px',
  },
}))

export default StyledTab
