import React from 'react'
import MaskedInput from 'react-text-mask'

interface Props {
    inputRef?: (ref: HTMLElement | null) => void
    [key: string]: any
}

const MaskedInputComponent: React.FC<Props> = (props) => {
    const { inputRef, ...other } = props

    return (
        <MaskedInput
            {...other}
            ref={(ref: any) => {
                if (typeof inputRef === 'function') {
                    inputRef(ref ? ref.inputElement : null)
                }
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            showMask
        />
    )
}

export default MaskedInputComponent