import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  title: {
    id: 'shipment.title',
    description: 'Shipment title',
    defaultMessage: 'Shipments',
  },
  createShipmentBtn: {
    id: 'shipment.createShipmentBtn',
    description: 'Create shipment button',
    defaultMessage: 'Create Shipment',
  },
  backToShipmentstBtn: {
    id: 'shipment.backToShipmentstBtn',
    description: 'Back to Shipments button',
    defaultMessage: 'Back to Shipments',
  },
  lockForReviewBtn: {
    id: 'shipment.lockForReviewBtn',
    description: 'Locked for review button',
    defaultMessage: 'Locked for review',
  },
  trackingNumberHeader: {
    id: 'shipment.trackingNumber.Header',
    description: 'Tracking Number',
    defaultMessage: 'Tracking Number',
  },
  customerIdHeader: {
    id: 'shipment.customerId.Header',
    description: 'Customer',
    defaultMessage: 'Customer',
  },
  shipperIdHeader: {
    id: 'shipment.shipperId.Header',
    description: 'Shipper header',
    defaultMessage: 'Shipper',
  },
  shipmentStatus: {
    id: 'shipment.status.Header',
    description: 'Shipment Status column name',
    defaultMessage: 'Status',
  },
  acceptedStatus: {
    id: 'shipment.acceptedStatus',
    description: 'Shipment Accepted Status',
    defaultMessage: 'Accepted',
  },
  submittedStatus: {
    id: 'shipment.submittedStatus',
    description: 'Shipment Submitted Status',
    defaultMessage: 'Submitted',
  },
  lockedForReview: {
    id: 'shipment.lockedForReview',
    description: 'Locked for Review Status',
    defaultMessage: 'Locked for Review',
  },
  draftStatus: {
    id: 'shipment.draftStatus',
    description: 'Shipment Draft Status',
    defaultMessage: 'Draft',
  },
  submittedDate: {
    id: 'shipment.submittedDate.Header',
    description: 'Shipment Submitted Date column name',
    defaultMessage: 'Submitted Date',
  },
  customerReferenceNumberHeader: {
    id: 'shipment.customerReferenceNumber.Header',
    description: 'Customer Reference Number Header',
    defaultMessage: 'Customer Reference Number',
  },
  cargoValueHeader: {
    id: 'shipment.cargoValue.header',
    description: 'Cargo Value Header',
    defaultMessage: 'Cargo Value',
  },
  descriptionHeader: {
    id: 'shipment.description',
    description: 'Cargo Description Header',
    defaultMessage: 'Description',
  },
  currencyHeader: {
    id: 'shipment.Currency.Header',
    description: 'Currency',
    defaultMessage: 'Currency',
  },
  loadQuantityHeader: {
    id: 'shipment.Load.Quantity.Header',
    description: 'Load Quantity Header',
    defaultMessage: 'Quantity',
  },
  loadDescriptionHeader: {
    id: 'shipment.Load.Description.Header',
    description: 'Load Description Header',
    defaultMessage: 'Description',
  },
  loadValueHeader: {
    id: 'shipment.Load.Value.Header',
    description: 'Load Value Header',
    defaultMessage: 'Value',
  },
  loadPickupLocationHeader: {
    id: 'shipment.Load.PickupLocation.Header',
    description: 'Load Pickup location Header',
    defaultMessage: 'Pickup Location',
  },
  loadPickupLocationDateHeader: {
    id: 'shipment.Load.PickupLocationDate.Header',
    description: 'Load Pickup locationDate Header',
    defaultMessage: 'Pickup Date',
  },
  loadDeliveryLocationHeader: {
    id: 'shipment.Load.DeliveryLocation.Header',
    description: 'Load Delivery location Header',
    defaultMessage: 'Delivery Location',
  },
  loadDeliveryLocationDateHeader: {
    id: 'shipment.Load.DeliveryLocationDate.Header',
    description: 'Load Delivery location Date Header',
    defaultMessage: 'Delivery Date',
  },
  shipmentUpdateSuccess: {
    id: 'shipment.update.success',
    description: 'Shipment update success',
    defaultMessage: 'Shipment updated successfully',
  },
  shipmentUpdateError: {
    id: 'shipment.update.error',
    description: 'Shipment update error',
    defaultMessage: 'Shipment update error',
  },
})
