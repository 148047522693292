import { combineReducers } from '@reduxjs/toolkit'

import {
  ASSETS_ALL_ASSET_CATEGORIES_BY_TENANT_ID,
  ASSETS_ALL_ASSET_CATEGORIES_BY_TRANSPORT_MODE_REDUCER_NAME,
  ASSETS_ALL_ASSET_CLASSES_BY_CATEGORY_REDUCER_NAME,
  ASSETS_ALL_ASSET_TYPES_BY_CATEGORY_REDUCER_NAME,
  ASSETS_ALL_ASSET_TYPES_REDUCER_NAME,
  ASSETS_ALL_USER_TYPES_REDUCER_NAME,
} from './consts'
import {
  fetchAllAssetCategoriesByTransportModeReducers,
  fetchAssetCategoriesByTenantIdReducer,
  fetchAssetClassesByCategoryReducers,
  fetchAssetTypeReducer,
  fetchAssetTypesByCategoryReducer,
  fetchUserTypeReducer,
} from './slice'

export const assetsReducer = combineReducers({
  [ASSETS_ALL_ASSET_CATEGORIES_BY_TRANSPORT_MODE_REDUCER_NAME]:
    fetchAllAssetCategoriesByTransportModeReducers,
  [ASSETS_ALL_ASSET_CLASSES_BY_CATEGORY_REDUCER_NAME]: fetchAssetClassesByCategoryReducers,
  [ASSETS_ALL_ASSET_TYPES_BY_CATEGORY_REDUCER_NAME]: fetchAssetTypesByCategoryReducer,
  [ASSETS_ALL_ASSET_TYPES_REDUCER_NAME]: fetchAssetTypeReducer,
  [ASSETS_ALL_ASSET_CATEGORIES_BY_TENANT_ID]: fetchAssetCategoriesByTenantIdReducer,
  [ASSETS_ALL_USER_TYPES_REDUCER_NAME]: fetchUserTypeReducer,
})
