import * as yup from 'yup'

export const getContactsValidationSchema = (formatMessage: any, type?: any) => {
  return yup.object({
    contacts: yup.array(
      yup.object({
        email: yup.string().email('Invalid email format').required('Email is required'),
        phone: yup.string().max(15, 'Max allowed is 15 digits').required('Phone is required'),
        name: yup.string().required('Name is required'),
        contactTypeId: yup.number().required('Contact type is required'),
      })
    ),
  })
}

export const getContactValidationSchema = (formatMessage: any, type?: any) => {
  return yup.object({
    email: yup.string().email('Invalid email format').required('Email is required'),
    phone: yup.string().max(15, 'Max allowed is 15 digits').required('Phone is required'),
    name: yup.string().required('Name is required'),
    contactTypeId: yup.number().required('Contact type is required'),
  })
}
