import { fetchLoggedInUserSelector } from '../../features/selectors'
import { ILoggedInUser } from '../../features/types'
import useAppSelector from './useAppSelector'

export const useLoggedInUser = () => {
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)

  const { tenantId, customerId } = loggedInUser

  const isCustomerUser = Boolean(tenantId && customerId)
  const isTenantUser = Boolean(tenantId && !customerId)

  return { loggedInUser, tenantId, customerId, isCustomerUser, isTenantUser }
}
