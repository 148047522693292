import { Autocomplete, FormControl, SxProps, TextField, Theme } from '@mui/material'
import { IDropDownItem } from '../../app/types'

interface FormDropDownClearableProps {
  id: string
  data: IDropDownItem[]
  handleChange: (event: any, value: any) => void
  value: any
  label: string
  sx?: SxProps<Theme> | undefined
}

const tfStyle = {
  '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
    color: 'gray',
    visibility: 'visible',
  },
}

const FormDropDownClearable = ({
  id,
  data,
  handleChange,
  sx,
  label,
  value,
}: FormDropDownClearableProps) => {
  return (
    <FormControl fullWidth={true} sx={sx}>
      <Autocomplete
        onChange={handleChange}
        options={data}
        // disableClearable
        // clearOnEscape
        // clearIcon={<HighlightOffIcon/>}
        id={id}
        getOptionLabel={(option) => `${option.name}`}
        isOptionEqualToValue={(o) => {
          return o.id === value
        }}
        renderInput={(params) => {
          return <TextField {...params} variant='outlined' label={label} sx={tfStyle} />
        }}
      />
    </FormControl>
  )
}

export default FormDropDownClearable
