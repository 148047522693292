import { Timeline } from '@mui/icons-material';
import {
    Box,
    Button,
    createTheme,
    Grid,
    Tab,
    Tabs,
    ThemeProvider,
    useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { useLoggedInUser } from '../../app/hooks/useLoggedInUser';
import { useNotificationStack } from '../../app/hooks/useNotificationStack';
import { useAcceptShipmentByTenantIdMutation, useGetShipmentByIdQuery, useRecallShipmentMutation, useRejectShipmentMutation, useResetReviewShipmentByTenantIdMutation, useReviewShipmentByTenantIdMutation, useSubmitShipmentMutation } from '../../app/redux-fetch/apiShipment';
import ConditionalRender from '../../components/ConditionalRender/ConditionalRender';
import rootEnum from '../../components/Routes/rootEnum';
import SpinnerBlock from '../../components/Spinner/SpinnerBlock';
import TabPanel from '../../components/TabPanel/TabPanel';
import ShipmentNoteTab from '../Shipments/components/Notes/ShipmentNote';
import TerminalInfo from '../Shipments/components/TerminalInfo/TerminalInfo';
import ViewShipmentTabHeader from '../Shipments/components/ViewShipment/ViewShipmentTabHeader';
import TemperatureChart from '../TemperatureChart/TemperatureChart';
import messages from './messages';
import ShipmentSummary from './ShipmentSummary';
import { mapToShipmentFormInput } from './utils';


const ViewShipment = (): ReactElement => {
    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const { id } = useParams();
    const shipmentId = Number(id);
    const { tenantId, loggedInUser, isTenantUser, isCustomerUser } = useLoggedInUser();
    const loggedInUserId = loggedInUser.id;

    const [activeTab, setActiveTab] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isUnderReview, setIsUnderReview] = useState<boolean>(false);
    const [countdown, setCountdown] = useState<number>(0);
    const [underReviewUserId, setUnderReviewUserId] = useState<number | undefined>();
    const { enqueueSuccess, enqueueFailure } = useNotificationStack()

    const [submitShipment, { isError: isSubmitError, isSuccess: isSubmitSuccess }] = useSubmitShipmentMutation();
    const [acceptShipment, { isError: isAcceptError, isSuccess: isAcceptSuccess }] = useAcceptShipmentByTenantIdMutation();
    const [rejectShipment, { isError: isRejectError, isSuccess: isRejectSuccess }] = useRejectShipmentMutation();
    const [recallShipment, { isError: isRecallError, isSuccess: isRecallSuccess }] = useRecallShipmentMutation();
    const [reviewShipment, { isError: isReviewError, isSuccess: isReviewSuccess }] = useReviewShipmentByTenantIdMutation();
    const [resetReviewShipment, { isError: isResetReviewError, isSuccess: isResetReviewSuccess }] = useResetReviewShipmentByTenantIdMutation();

    const theme = useTheme();
    const customTheme = createTheme(theme, {
        components: {
            MuiTab: {
                styleOverrides: {
                    root: {
                        '&.Mui-selected': {
                            backgroundColor: theme.palette.secondary.main,
                            color: 'white',
                            borderRadius: '5px',
                        },
                    },
                },
            },
        },
    });

    const {
        data: shipment,
        isFetching: shipmentSelectorFetching,
        isSuccess: shipmentLoaded,
        refetch,
    } = useGetShipmentByIdQuery({
        tenantId: tenantId,
        shipmentId: Number(id),
    })

    useEffect(() => {
        if (shipment) {
            if (shipment.underReviewUntil && shipment.underReviewByUserId) {
                const diffTime = Math.max(
                    0,
                    Math.floor((new Date(shipment.underReviewUntil).getTime() - Date.now()) / 1000)
                );
                if (diffTime > 0) {
                    setCountdown(diffTime);
                    setIsUnderReview(true);
                    setUnderReviewUserId(shipment.underReviewByUserId);
                } else {
                    setIsUnderReview(false);
                    setUnderReviewUserId(undefined);
                    setCountdown(0);
                }
            } else {
                setIsUnderReview(false);
                setUnderReviewUserId(undefined);
                setCountdown(0);
            }
        }
    }, [shipment]);

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
            return () => clearTimeout(timer);
        } else if (isUnderReview && underReviewUserId === loggedInUserId) {
            handleResetReview();
        }
    }, [countdown, isUnderReview, underReviewUserId, loggedInUserId]);

    const handleLockForReview = useCallback(async () => {
        try {
            setIsSubmitting(true);
            await reviewShipment({ tenantId, shipmentId });
            setUnderReviewUserId(loggedInUserId);
            setIsUnderReview(true);
            setCountdown(10 * 60);
            enqueueSuccess(messages.shipmentUpdateSuccess);
            await refetch();
        } catch (e) {
            enqueueFailure(messages.shipmentUpdateError);
        } finally {
            setIsSubmitting(false);
        }
    }, [tenantId, shipment, shipmentId, loggedInUserId, refetch, formatMessage]);

    const handleResetReview = useCallback(async () => {
        try {
            await resetReviewShipment({ tenantId, shipmentId });
            setIsUnderReview(false);
            setUnderReviewUserId(undefined);
            setCountdown(0);
            await refetch();
        } catch (e) {
            enqueueFailure(messages.shipmentUpdateError);
        }
    }, [tenantId, shipment, shipmentId, refetch, formatMessage]);

    const handleBackToShipment = useCallback(async () => {
        if (underReviewUserId === loggedInUserId && isUnderReview) {
            await handleResetReview();
        }
        navigate(rootEnum.SHIPMENTS);
    }, [navigate, underReviewUserId, loggedInUserId, isUnderReview, handleResetReview]);

    const handleChange = (_event: React.SyntheticEvent, tabId: number) => {
        setActiveTab(tabId);
    };

    const isUnderReviewOrDifferentUser = useCallback(() => {
        return isUnderReview && underReviewUserId !== loggedInUserId;
    }, [isUnderReview, underReviewUserId, loggedInUserId]);

    if (!shipmentLoaded) return <SpinnerBlock />;

    const isSubmitted = shipment?.submittedDate !== undefined;
    const isAccepted = shipment?.acceptedDate !== undefined;

    const handleAccept = async () => {
        try {
            await acceptShipment({ tenantId, shipmentId: shipment.id }).unwrap();
            enqueueSuccess(messages.shipmentAccepted);
            navigate('/shipping/shipments');
        } catch (error) {
            enqueueFailure(messages.shipmentAcceptFailed);
        }
    }

    const handleSubmit = async () => {
        try {
            await submitShipment({ tenantId, shipmentId: shipment.id }).unwrap();
            enqueueSuccess(messages.shipmentSubmittedSuccess);
            navigate('/shipping/shipments');
        } catch (error) {
            enqueueFailure(messages.shipmentSubmitFailed);
        }
    }

    const handleReject = async () => {
        try {
            await rejectShipment({ tenantId, shipmentId: shipment.id, shipment: shipment }).unwrap();
            enqueueSuccess(messages.shipmentRejected);
            navigate('/shipping/shipments');
        } catch (error) {
            enqueueFailure(messages.shipmentRejectFailed);
        }
    }

    const handleRecall = async () => {
        try {
            await recallShipment({ tenantId, shipmentId: shipment.id, shipment: shipment }).unwrap();
            enqueueSuccess(messages.shipmentRecalled);
            navigate('/shipping/shipments');
        } catch (error) {
            enqueueFailure(messages.shipmentRecallFailed);
        }
    }

    return (
        <Grid container spacing={1} pt={4}>
            <Grid container spacing={1} pt={2} pb={2} pl={'10px'}>
                <Grid item xs>
                    <Box display='flex' justifyContent='flex-start'>
                        <Button color='secondary' variant='contained' onClick={handleBackToShipment}>
                            {formatMessage(messages.backToShipmentstBtn)}
                        </Button>
                    </Box>
                </Grid>
                <Grid item>
                    <Grid container justifyContent='flex-end' spacing={1}>
                        <Grid item>
                            <ConditionalRender
                                condition={isTenantUser && isSubmitted && !isAccepted}
                            >
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    onClick={handleLockForReview}
                                    disabled={isUnderReview || isSubmitting}
                                >
                                    {countdown && countdown > 0
                                        ? formatMessage(messages.countdownTitle, {
                                            value: `[${dayjs()
                                                .hour(0)
                                                .minute(0)
                                                .second(countdown)
                                                .format('mm:ss')}]`,
                                        })
                                        : formatMessage(messages.lockForReview)}
                                </Button>
                            </ConditionalRender>
                        </Grid>
                        <Grid item>
                            <ConditionalRender
                                condition={isTenantUser && !isAccepted}
                            >
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    onClick={handleAccept}
                                    disabled={isUnderReviewOrDifferentUser() || isSubmitting}
                                >
                                    {formatMessage(messages.acceptShipment)}
                                </Button>
                            </ConditionalRender>
                        </Grid>
                        <Grid item>
                            <ConditionalRender
                                condition={isTenantUser && isSubmitted && !isAccepted}
                            >
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    onClick={handleReject}
                                    disabled={isUnderReviewOrDifferentUser() || isSubmitting}
                                >
                                    {formatMessage(messages.buttonReject)}
                                </Button>
                            </ConditionalRender>
                        </Grid>
                        <Grid item>
                            <ConditionalRender
                                condition={
                                    isCustomerUser && isSubmitted && !isAccepted
                                }
                            >
                                <Button
                                    variant='contained'
                                    color='secondary'
                                    onClick={handleRecall}
                                    disabled={isUnderReview || isSubmitting}
                                >
                                    {formatMessage(messages.buttonRecall)}
                                </Button>
                            </ConditionalRender>
                        </Grid>
                        <Grid item>
                            <ConditionalRender condition={!isSubmitted && !isAccepted}>
                                <Button
                                    variant='contained'
                                    onClick={handleSubmit}
                                    disabled={isUnderReview || isSubmitting}
                                >
                                    {formatMessage(messages.submitShipment)}
                                </Button>
                            </ConditionalRender>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <ViewShipmentTabHeader />
            </Grid>
            <Grid item xs={12}>
                <Box sx={{ width: '100%', pt: '28px', pb: '28px' }}>
                    <ThemeProvider theme={customTheme}>
                        <Tabs
                            sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
                            value={activeTab}
                            onChange={handleChange}
                            centered
                            variant="standard"
                            scrollButtons={false}
                            aria-label="Shipment View Tabs"
                        >
                            <Tab label="Details" />
                            <Tab label="Timeline" />
                            <Tab label="Notes" />
                            <Tab label="Temperature" />
                            <Tab label="Terminal Info" />
                        </Tabs>
                    </ThemeProvider>
                </Box>
                <TabPanel value={activeTab} index={0}>
                    <Grid item xs={12}>
                        <ShipmentSummary data={mapToShipmentFormInput(shipment)} />
                    </Grid>
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    <Grid item xs={12}>
                        <Timeline />
                    </Grid>
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                    <Grid item xs={12}>
                        <ShipmentNoteTab />
                    </Grid>
                </TabPanel>
                <TabPanel value={activeTab} index={3}>
                    <Grid item xs={12}>
                        <TemperatureChart data={[]} loadId={0} loadName={''} title={''} />
                    </Grid>
                </TabPanel>
                <TabPanel value={activeTab} index={4}>
                    <Grid item xs={12}>
                        <TerminalInfo />
                    </Grid>
                </TabPanel>
            </Grid>
        </Grid>
    );
};

export default ViewShipment;
