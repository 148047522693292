import { defineMessages } from 'react-intl'

const messages = defineMessages({
  productName: {
    id: 'product.productName',
    description: 'Product Name',
    defaultMessage: 'Name',
  },
  productCode: {
    id: 'product.productCode',
    description: 'Product Code',
    defaultMessage: 'Code (SKU)',
  },
  barcode: {
    id: 'product.barcode',
    description: 'Barcode',
    defaultMessage: 'Barcode',
  },
  productCategory: {
    id: 'product.productCategory',
    description: 'Product Category',
    defaultMessage: 'Product Category',
  },
  countryOfOrigin: {
    id: 'product.countryOfOrigin',
    description: 'Country of Origin',
    defaultMessage: 'Country of Origin',
  },
  productPrice: {
    id: 'product.productPrice',
    description: 'Product Price',
    defaultMessage: 'Price',
  },
  productCurrency: {
    id: 'product.productCurrency',
    description: 'Product Currency',
    defaultMessage: 'Currency',
  },
  enabled: {
    id: 'product.enabled',
    description: 'Enabled',
    defaultMessage: 'Enabled Y/N',
  },
  yesLbl: {
    id: 'product.yesLbl',
    description: 'Yes',
    defaultMessage: 'Yes',
  },
  noLbl: {
    id: 'product.noLbl',
    description: 'No',
    defaultMessage: 'No',
  },
  createEditProduct: {
    id: 'product.createEditProduct',
    description: 'Create/Edit Product',
    defaultMessage: 'Create/Edit Product',
  },
  viewProduct: {
    id: 'product.viewProduct',
    description: 'View Product',
    defaultMessage: 'View Product',
  },
  viewBtn: {
    id: 'product.viewBtn',
    description: 'View',
    defaultMessage: 'View',
  },
  editBtn: {
    id: 'product.editBtn',
    description: 'Edit',
    defaultMessage: 'Edit',
  },
  newProductBtn: {
    id: 'product.newProductBtn',
    description: 'New Product',
    defaultMessage: 'New Product',
  },
  customerLbl: {
    id: 'product.customerLbl',
    description: 'Customer',
    defaultMessage: 'Customer',
  },
  enabledDisabledLbl: {
    id: 'product.enabledDisabledLbl',
    description: 'Enabled/Disabled',
    defaultMessage: 'Enabled',
  },
  mainInfoLbl: {
    id: 'product.mainInfoLbl',
    description: 'Main Information',
    defaultMessage: 'Main Information',
  },
})

export default messages
