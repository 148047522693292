import { useGetProductByIdQuery } from '../../../app/redux-fetch/apiQuery'
import SpinnerModalBlock from '../../../components/Spinner/SpinnerModalBlock'
import ProductForm from './ProductForm'

type ProductModalProps = {
  productId: number
  customerId: number
  isDisabled?: boolean
}

const ProductModal = ({ productId, customerId, isDisabled }: ProductModalProps) => {
  const newProduct = {
    hsLocalTariffs: [],
    customerId: customerId,
  }
  const { data, isSuccess } = useGetProductByIdQuery({ customerId, productId })
  if (!isSuccess) return <SpinnerModalBlock />

  return <ProductForm product={data ?? newProduct} isDisabled={isDisabled ?? false} />
}

export default ProductModal
