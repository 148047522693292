import { AccordionDetails, Grid } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useGetCurrenciesByCustomerIdQuery } from '../../app/redux-fetch/apiShipment';
import { convertToLabelValue } from '../../app/utils';
import StyledAccordion from '../../components/Accordions/StyledAccordion';
import StyledAccordionSummary from '../../components/Accordions/StyledAccordionSummary';
import FormInput from '../../components/Forms/FormInput';
import FormSelect from '../../components/Forms/FormSelect';
import commonMessages from '../../components/Messages/commonMessages';
import messages from './messages';

interface ConsignmentInformationFormProps {
    index: number
}

const ConsignmentInformationForm: React.FC<ConsignmentInformationFormProps> = ({ index }: ConsignmentInformationFormProps) => {

    const { watch } = useFormContext();
    const { formatMessage } = useIntl();
    const [expanded, setExpanded] = useState(true);

    const handleToggleAccordion = () => {
        setExpanded((prev) => !prev)
    }

    const customerId = watch('customerId')

    const { data: currencies = [] } = useGetCurrenciesByCustomerIdQuery({
        customerId: customerId ?? 0, hideDisabled: true,
    })


    return (
        <StyledAccordion sx={{
            '&:before': {
                display: 'none',
            },
        }} expanded={expanded}
            onChange={handleToggleAccordion}>
            <StyledAccordionSummary
                index={`consignment-info-content-${index}`}
                title='Consignment Information'
                expanded={expanded}
            >
            </StyledAccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={3}>
                        <FormInput
                            name={`consignments.${index}.referenceNumber`}
                            label={formatMessage(messages.consignmentReferenceNumber)}
                            placeholder={formatMessage(commonMessages.pleaseEnter)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormInput
                            name={`consignments.${index}.trackingNumber`}
                            label={formatMessage(messages.consignmentTrackingNumber)}
                            placeholder={formatMessage(messages.systemGenerated)}
                            disabled={true}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormInput
                            name={`consignments.${index}.description`}
                            label={formatMessage(messages.consignmentDescription)}
                            placeholder={formatMessage(messages.consignmentDescription)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <FormSelect
                            name={`consignments.${index}.currencyId`}
                            label={formatMessage(messages.currency)}
                            options={convertToLabelValue(currencies.map((currency) => ({ id: currency.id, name: currency.localisedSymbol })))}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </StyledAccordion >
    )
}

export default ConsignmentInformationForm;