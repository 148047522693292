import * as yup from 'yup'

export const getHsLocalTariffValidationSchema = (formatMessage: any, type?: any) => {
  return yup.object({
    description: yup.string().required('Description is required'),
    countryId: yup.number().required('Import Country is required'),
    classificationCode: yup.string(),
    tariff: yup.number().required('Tariff is required').max(99999.9999, 'Max value 99,999.9999'),
  })
}

export const getProductCategoryValidationSchema = (formatMessage: any, type?: any) => {
  return yup.object({
    name: yup.string().required('Product Category is required'),
    hsHeadingId: yup.number().required('HS Heading is required'),
    hsSubheadingId: yup.string().required('HS Subheading is required'),
    hsLocalTariffs: yup.array().of(getHsLocalTariffValidationSchema(formatMessage, type)),
  })
}
