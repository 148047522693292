import { useSnackbar } from 'notistack';
import { MessageDescriptor, useIntl } from 'react-intl';
import { NotiStackOptions } from '../../features/utils';

export function useNotificationStack() {
    const { enqueueSnackbar } = useSnackbar();
    const { formatMessage } = useIntl();


    const enqueueSuccess = (msg: MessageDescriptor) => {
        enqueueSnackbar(formatMessage(msg), NotiStackOptions.success);
    };

    const enqueueFailure = (msg: MessageDescriptor) => {
        enqueueSnackbar(formatMessage(msg), NotiStackOptions.error);
    };


    return {
        enqueueSuccess,
        enqueueFailure,
    }
}