import { type AxiosResponse } from 'axios'
import {
  assetsAuthorizedFetch,
  shipmentsAuthorizedFetch,
  usersAuthorizedFetch,
} from '../../app/axios/omnialog'

export const fetchJourneys = async (tenantId: number): Promise<AxiosResponse<any, any>> => {
  return await shipmentsAuthorizedFetch.get(`/tenant/${tenantId}/journey`)
}

export const fetchTransportsLegById = async (
  tenantId: number,
  id: string
): Promise<AxiosResponse<any, any>> => {
  return await shipmentsAuthorizedFetch.get(`/tenant/${tenantId}/transport-leg/${id}`)
}

export const fetchTransportsLoads = async (tenantId: number): Promise<AxiosResponse<any, any>> => {
  return await shipmentsAuthorizedFetch.get(`/tenant/${tenantId}/load/journey`)
}

export const fetchTransportsAssets = async (tenantId: number): Promise<AxiosResponse<any, any>> => {
  return await assetsAuthorizedFetch.get(`/tenant/${tenantId}/asset`)
}

export const fetchTransportsEmployee = async (
  tenantId: number
): Promise<AxiosResponse<any, any>> => {
  return await usersAuthorizedFetch.get(`/tenant/${tenantId}/user`)
}

export const saveDraftJourney = async (
  tenantId: number,
  payload: any
): Promise<AxiosResponse<any, any>> => {
  return await shipmentsAuthorizedFetch.post(`/tenant/${tenantId}/journey`, payload)
}

export const fetchJourney = async (
  tenantId: number,
  journeyId: number
): Promise<AxiosResponse<any, any>> => {
  return await shipmentsAuthorizedFetch.get(`/tenant/${tenantId}/journey/${journeyId}`)
}

export const submitJourney = async (
  tenantId: number,
  id: any,
  payload: any
): Promise<AxiosResponse<any, any>> => {
  return await shipmentsAuthorizedFetch.put(`/tenant/${tenantId}/journey/${id}/submit`, payload)
}

export const fetchAssetConfiguration = async (
  tenantId: number
): Promise<AxiosResponse<any, any>> => {
  return await assetsAuthorizedFetch.get(`/tenant/${tenantId}/asset-configuration`)
}

export const fetachAvailableAssets = async (tenantId: number): Promise<AxiosResponse<any, any>> => {
  return await assetsAuthorizedFetch.get(`/tenant/${tenantId}/asset`)
}
