import { Box, TextField } from '@mui/material'
import { useIntl } from 'react-intl'
import { NumericFormat } from 'react-number-format'
import { useAppSelector } from '../../../app/hooks'
import {
  useGetCustomersQuery,
  useGetProductCategoriesQuery,
} from '../../../app/redux-fetch/apiQuery'
import { useGetCountriesQuery, useGetCurrenciesByCustomerIdQuery } from '../../../app/redux-fetch/apiShipment'
import { IDropDownItem } from '../../../app/types'
import FormDropdown from '../../../components/FormDropDown/FormDropdown'
import RequiredAsterisk from '../../../components/RequiredAsterisk/RequiredAsterisk'
import {
  SectionLabelTypography,
  SectionTitleTypography,
} from '../../../components/Typographies/styles'
import { fetchLoggedInUserSelector } from '../../selectors'
import { ILoggedInUser } from '../../types'
import { IProductCategory } from '../types'
import { useProductFormContext } from './hooks/useProfuctFormContext'
import messages from './messages'

type MainInfoProps = {
  isDisabled?: boolean
  customerId?: number
}

const MainInfo = ({ isDisabled = false, customerId: sharedCustomerId = -1 }: MainInfoProps) => {
  const { formatMessage } = useIntl()
  const { values, setFieldValue, errors, touched } = useProductFormContext()

  const handleTextFieldChange = ({
    target,
  }: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFieldValue(target.id, target.value)
  }
  const handleFormDropDownChange = (e: any, name: any, newValue: any) => {
    setFieldValue(name, newValue)
  }
  const { data: countries = [] } = useGetCountriesQuery()
  const { data: currencies = [] } = useGetCurrenciesByCustomerIdQuery({
    customerId: sharedCustomerId, hideDisabled: true,
  })

  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const { data: customers } = useGetCustomersQuery(loggedInUser.tenantId ?? -1)
  const isCustomerUser = loggedInUser?.customerId && loggedInUser?.tenantId
  const { data: productCategories } = useGetProductCategoriesQuery(sharedCustomerId)

  const {
    name,
    customerId,
    productCategoryId,
    barcode,
    countryOfOriginId,
    currencyId,
    sku,
    price,
  } = values
  const mapTpDropDownItems = (items: IProductCategory[]) => {
    return items.map((item) => {
      return {
        id: item.id,
        name: item.name,
      } as IDropDownItem
    })
  }
  return (
    <Box sx={{ width: '50%', mt: '16px' }}>
      <Box sx={{ mb: '16px' }}>
        <SectionTitleTypography>{formatMessage(messages.mainInfoLbl)}</SectionTitleTypography>
      </Box>
      {!isCustomerUser && (
        <Box sx={{ mb: '8px' }}>
          <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
            {formatMessage(messages.customerLbl)}
            <RequiredAsterisk />
          </SectionLabelTypography>
          <FormDropdown
            id='customerId'
            items={customers as IDropDownItem[]}
            onChange={handleFormDropDownChange}
            value={customerId}
            disabled={true}
            defaultValue={customerId}
            error={touched.customerId && !!errors.customerId}
            errorText={touched.customerId && errors.customerId}
          />
        </Box>
      )}
      <Box sx={{ mb: '8px' }}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          {formatMessage(messages.productCode)}
          <RequiredAsterisk />
        </SectionLabelTypography>
        <TextField
          fullWidth
          id='sku'
          value={sku}
          defaultValue={sku}
          disabled={isDisabled}
          onChange={handleTextFieldChange}
          error={touched.sku && !!errors.sku}
          helperText={touched.sku && errors.sku}
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          {formatMessage(messages.productName)}
          <RequiredAsterisk />
        </SectionLabelTypography>
        <TextField
          fullWidth
          id='name'
          value={name}
          defaultValue={name}
          disabled={isDisabled}
          onChange={handleTextFieldChange}
          error={touched.name && !!errors.name}
          helperText={touched.name && errors.name}
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          {formatMessage(messages.productCategory)}
          <RequiredAsterisk />
        </SectionLabelTypography>
        <FormDropdown
          id='productCategoryId'
          items={mapTpDropDownItems(productCategories ?? [])}
          onChange={handleFormDropDownChange}
          value={productCategoryId}
          disabled={isDisabled}
          defaultValue={productCategoryId}
          error={touched.productCategoryId && !!errors.productCategoryId}
          errorText={touched.productCategoryId && errors.productCategoryId}
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          {formatMessage(messages.countryOfOrigin)}
          <RequiredAsterisk />
        </SectionLabelTypography>
        <FormDropdown
          id='countryOfOriginId'
          items={countries}
          onChange={handleFormDropDownChange}
          value={countryOfOriginId}
          disabled={isDisabled}
          defaultValue={countryOfOriginId}
          error={touched.countryOfOriginId && !!errors.countryOfOriginId}
          errorText={touched.countryOfOriginId && errors.countryOfOriginId}
        />
      </Box>
      <Box sx={{ mb: '8px' }}>
        <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
          {formatMessage(messages.barcode)}
        </SectionLabelTypography>
        <TextField
          fullWidth
          id='barcode'
          value={barcode}
          defaultValue={barcode}
          disabled={isDisabled}
          onChange={handleTextFieldChange}
        />
      </Box>
      <Box display='flex' columnGap={2} sx={{ mb: '16px' }}>
        <Box flex={1}>
          <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
            {formatMessage(messages.productPrice)}
          </SectionLabelTypography>
          <NumericFormat
            customInput={TextField}
            fullWidth={true}
            decimalScale={3}
            id={'price'}
            name={'price'}
            thousandSeparator={true}
            type='text'
            rows={4}
            allowNegative={false}
            onValueChange={(vals) => {
              setFieldValue('price', vals.floatValue)
            }}
            value={price}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: 11 }}
            disabled={isDisabled}
          />
        </Box>
        <Box flex={1}>
          <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
            {formatMessage(messages.productCurrency)}
          </SectionLabelTypography>
          <FormDropdown
            id='currencyId'
            items={currencies}
            onChange={handleFormDropDownChange}
            value={currencyId}
            disabled={isDisabled}
            defaultValue={currencyId}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default MainInfo
