import { ICurrency } from '../../features/Currency/types'
import { Journey } from '../../features/Journey/types'
import { ShipmentDto } from '../../features/NewShipment/types'
import { ICarrier, ILocationByTenantCustomer } from '../../features/Shipments/types'
import { IUpdateJourney } from '../../features/Transport/types'
import { IJourneyLegLoad } from './../../features/Transport/types'
import { apiSlice } from './apiQuery'

export const apiShipment = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getUnassignedLoads: build.query<IJourneyLegLoad[], { tenantId: number }>({
      query: ({ tenantId }) => {
        return {
          url: `/tenant/${tenantId}/load/journey`,
          method: 'GET',
          domain: 'Shipment',
        }
      },
      providesTags: (result, error, tenantId) => [
        { type: 'Unassigned Loads', tenantId },
        { type: 'Journey', id: 'Journey' },
        { type: 'Journeys', id: 'Journeys' },
      ],
    }),
    getJourneyLoadsById: build.query<IJourneyLegLoad[], { tenantId: number; journeyId: number }>({
      query: ({ tenantId, journeyId }) => {
        return {
          url: `/tenant/${tenantId}/journey/${journeyId}/loads`,
          method: 'GET',
          domain: 'Shipment',
        }
      },
      providesTags: (result, error, tenantId) => [
        { type: 'Unassigned Loads', tenantId },
        { type: 'Journey', id: 'Journey' },
      ],
    }),
    getCurrenciesByCustomerId: build.query<
      ICurrency[],
      { customerId: number; hideDisabled: boolean }
    >({
      query: ({ customerId, hideDisabled }) => {
        return {
          url: `/customer/${customerId}/currency`,
          method: 'GET',
          domain: 'Shipment',
          params: { hideDisabled },
        }
      },
      providesTags: (result, error) => [{ type: 'Currency', id: 'Currency' }],
    }),
    getCurrenciesByTenantId: build.query<ICurrency[], number>({
      query: (tenantId) => {
        return {
          url: `/tenant/${tenantId}/currency`,
          method: 'GET',
          domain: 'Shipment',
        }
      },
      providesTags: (result, error) => [{ type: 'Currency', id: 'Currency' }],
    }),
    deleteCurrencyById: build.mutation<any, { currencyId: number }>({
      query: ({ currencyId }) => {
        return {
          url: `/currency/${currencyId}`,
          method: 'DELETE',
          domain: 'Shipment',
        }
      },
      invalidatesTags: (result, error, { currencyId }) => [{ type: 'Currency', id: 'Currency' }],
    }),
    addCurrency: build.mutation<any, { body: ICurrency }>({
      query: ({ body }) => {
        return {
          url: '/currency',
          method: 'POST',
          domain: 'Shipment',
          body,
        }
      },
      invalidatesTags: (result, error, { body }) => [{ type: 'Currency', id: 'Currency' }],
    }),
    updateCurrency: build.mutation<any, { body: ICurrency }>({
      query: ({ body }) => {
        return {
          url: '/currency',
          method: 'PUT',
          domain: 'Shipment',
          body,
        }
      },
      invalidatesTags: (result, error, { body }) => [{ type: 'Currency', id: 'Currency' }],
    }),
    getJourneysByTenantId: build.query<Journey[], { tenantId: number }>({
      query: ({ tenantId }) => {
        return {
          url: `/tenant/${tenantId}/journey`,
          method: 'GET',
          domain: 'Shipment',
        }
      },
      providesTags: (result, error) => [
        { type: 'Journey', id: 'Journey' },
        { type: 'Journeys', id: 'Journeys' },
      ],
    }),
    getJourneyByTenantId: build.query<IUpdateJourney, { id: number; tenantId: number }>({
      query: ({ id, tenantId }) => {
        return {
          url: `/tenant/${tenantId}/journey/${id}`,
          method: 'GET',
          domain: 'Shipment',
        }
      },
      providesTags: (result, error) => [{ type: 'Journey', id: 'Journey' }],
    }),
    updateTransportJourney: build.mutation<
      any,
      { tenantId: number; journeyId: number; body: IUpdateJourney }
    >({
      query: ({ tenantId, journeyId, body }) => ({
        url: `/tenant/${tenantId}/journey/${journeyId}`,
        method: 'PUT',
        domain: 'Shipment',
        body: body,
      }),
      invalidatesTags: (journeyId) => [{ type: 'Journey', id: 'Journey' }],
    }),
    approveJourney: build.mutation<any, { tenantId: number; journeyId: number }>({
      query: ({ tenantId, journeyId }) => ({
        url: `/tenant/${tenantId}/journey/${journeyId}/approve`,
        method: 'PUT',
        domain: 'Shipment',
      }),
      invalidatesTags: (result, error, { journeyId, tenantId }) => [
        { type: 'Journey', id: journeyId },
        { type: 'Transport Jobs', id: 'Transport Jobs' },
        { type: 'Journeys', id: tenantId },
        { type: 'Journey', id: 'Journey' },
      ],
    }),
    getCarriers: build.query<ICarrier[], number>({
      query: (tenantId: number) => ({
        url: `tenant/${tenantId}/carrier`,
        method: 'GET',
        domain: 'Shipment',
      }),
      providesTags: () => [{ type: 'Carrier', id: 'Carrier' }],
    }),
    getCountries: build.query<any, void>({
      query: () => ({
        url: '/get-countries',
        method: 'GET',
        domain: 'Shipment',
      }),
      providesTags: () => [{ type: 'Country', id: 'Country' }],
    }),
    saveDraftJourney: build.mutation<any, { tenantId: number; body: any }>({
      query: ({ tenantId, body }) => ({
        url: `/tenant/${tenantId}/journey`,
        method: 'POST',
        domain: 'Shipment',
        body,
      }),
      invalidatesTags: (result, error, tenantId) => [{ type: 'Journeys', tenantId }],
    }),
    updateJourney: build.mutation<any, { tenantId: number; journeyId: number; body: any }>({
      query: ({ tenantId, journeyId, body }) => ({
        url: `/tenant/${tenantId}/journey/${journeyId}`,
        method: 'PUT',
        domain: 'Shipment',
        body,
      }),
      invalidatesTags: (result, error, tenantId) => [{ type: 'Journeys', tenantId }],
    }),
    getLocations: build.query<
      ILocationByTenantCustomer[],
      { tenantId: number; includeCustomerLocations?: boolean; customerId?: number }
    >({
      query: ({ tenantId, includeCustomerLocations, customerId }) => {
        const customer = customerId ? `customer/${customerId}/` : ''
        const includeCustomerLocationsQuery = includeCustomerLocations
          ? '?includeCustomerLocations=true'
          : ''
        return {
          url: `/tenant/${tenantId}/${customer}location${includeCustomerLocationsQuery}`,
          method: 'GET',
          domain: 'Shipment',
        }
      },
      providesTags: () => [{ type: 'Locations', id: 'Locations' }],
    }),
    createLocation: build.mutation<
      any,
      { tenantId: number | undefined; customerId: number | undefined; body: any }
    >({
      query: ({ tenantId, customerId, body }) => {
        const customer = customerId ? `customer/${customerId}/` : ''
        return {
          url: `/tenant/${tenantId}/${customer}location`,
          method: 'POST',
          domain: 'Shipment',
          body,
        }
      },
      invalidatesTags: (result, error, tenantId) => [{ type: 'Locations', tenantId }],
    }),
    updateLocation: build.mutation<
      any,
      { tenantId: number | undefined; customerId: number | undefined; body: any }
    >({
      query: ({ tenantId, customerId, body }) => {
        const customer = customerId ? `customer/${customerId}/` : ''
        return {
          url: `/tenant/${tenantId}/${customer}location`,
          method: 'PUT',
          domain: 'Shipment',
          body,
        }
      },
      invalidatesTags: (result, error, tenantId) => [{ type: 'Locations', tenantId }],
    }),
    createShipment: build.mutation<any, { shipment: ShipmentDto; tenantId: any; customerId?: any }>(
      {
        query: ({ shipment, tenantId, customerId }) => {
          const customer = customerId ? `customer/${customerId}/` : ''
          return {
            url: `/tenant/${tenantId}/${customer}shipment`,
            method: 'POST',
            domain: 'Shipment',
            body: shipment,
          }
        },
      }
    ),
    updateShipment: build.mutation<any, { shipment: ShipmentDto; tenantId: any; customerId?: any }>(
      {
        query: ({ shipment, tenantId, customerId }) => {
          const customer = customerId ? `customer/${customerId}/` : ''
          return {
            url: `/tenant/${tenantId}/${customer}shipment/${shipment.id}`,
            method: 'PUT',
            domain: 'Shipment',
            body: shipment,
          }
        },
        invalidatesTags: (result, error, { tenantId, customerId }) => [
          { type: 'Shipments', id: 'Shipments' },
        ],
      }
    ),
    getShipmentById: build.query<any, { tenantId: number; shipmentId: number }>({
      query: ({ tenantId, shipmentId }) => {
        return {
          url: `/tenant/${tenantId}/shipment/${shipmentId}`,
          method: 'GET',
          domain: 'Shipment',
        }
      },
      providesTags: (result, error) => [{ type: 'Shipments', id: 'Shipments' }],
    }),
    getAllPackagingTypes: build.query<any, void>({
      query: () => ({
        url: '/get-all-packaging-types',
        method: 'GET',
        domain: 'Shipment',
      }),
    }),
    getTemperatureRanges: build.query<any, void>({
      query: () => ({
        url: '/get-temperature-ranges',
        method: 'GET',
        domain: 'Shipment',
      }),
    }),
    getAllPackagingTemplates: build.query<any, void>({
      query: () => ({
        url: '/get-all-packaging-templates',
        method: 'GET',
        domain: 'Shipment',
      }),
    }),
    submitShipment: build.mutation<
      any,
      { tenantId: number; shipmentId: number; customerId?: number }
    >({
      query: ({ tenantId, shipmentId, customerId }) => {
        const customer = customerId ? `customer/${customerId}/` : ''
        return {
          url: `/tenant/${tenantId}/${customer}shipment/${shipmentId}/submit`,
          method: 'PUT',
          domain: 'Shipment',
        }
      },
      invalidatesTags: (result, error, { tenantId }) => [{ type: 'Shipments', id: 'Shipments' }],
    }),
    acceptShipmentByTenantId: build.mutation<any, { tenantId: number; shipmentId: number }>({
      query: ({ tenantId, shipmentId }) => ({
        url: `/tenant/${tenantId}/shipment/${shipmentId}/accept`,
        method: 'PUT',
        domain: 'Shipment',
      }),
      invalidatesTags: (result, error, { tenantId, shipmentId }) => [
        { type: 'Shipments', id: 'Shipments' },
      ],
    }),
    getAllShipments: build.query<any, { tenantId: number; customerId?: number }>({
      query: ({ tenantId, customerId }) => {
        const customer = customerId ? `customer/${customerId}/` : ''
        return {
          url: `/tenant/${tenantId}/${customer}shipment`,
          method: 'GET',
          domain: 'Shipment',
        }
      },
      providesTags: (result, error) => [{ type: 'Shipments', id: 'Shipments' }],
    }),
    resetReviewShipmentByTenantId: build.mutation<any, { tenantId: number; shipmentId: number }>({
      query: ({ tenantId, shipmentId }) => ({
        url: `/tenant/${tenantId}/shipment/${shipmentId}/reset-review`,
        method: 'PUT',
        domain: 'Shipment',
      }),
    }),
    reviewShipmentByTenantId: build.mutation<any, { tenantId: number; shipmentId: number }>({
      query: ({ tenantId, shipmentId }) => ({
        url: `/tenant/${tenantId}/shipment/${shipmentId}/review`,
        method: 'PUT',
        domain: 'Shipment',
      }),
    }),
    rejectShipment: build.mutation<
      any,
      { tenantId: number; shipmentId: number; shipment: ShipmentDto }
    >({
      query: ({ tenantId, shipmentId, shipment }) => ({
        url: `/tenant/${tenantId}/shipment/${shipmentId}/reject`,
        method: 'PUT',
        domain: 'Shipment',
        body: shipment,
      }),
      invalidatesTags: (result, error, { tenantId, shipmentId }) => [
        { type: 'Shipments', id: 'Shipments' },
      ],
    }),
    recallShipment: build.mutation<any, { tenantId: number; shipmentId: number; shipment: any }>({
      query: ({ tenantId, shipmentId, shipment }) => {
        const customer = shipment.customerId ? `customer/${shipment.customerId}/` : ''
        return {
          url: `/tenant/${tenantId}/${customer}shipment/${shipmentId}/recall`,
          method: 'PUT',
          domain: 'Shipment',
          body: shipment,
        }
      },
      invalidatesTags: (result, error, { tenantId, shipmentId }) => [
        { type: 'Shipments', id: 'Shipments' },
      ],
    }),
    getShipmentSummaries: build.query<any, { tenantId: number; customerId?: number }>({
      query: ({ tenantId, customerId }) => {
        const customer = customerId ? `customer/${customerId}/` : ''

        return {
          url: `/tenant/${tenantId}/${customer}shipment-summaries`,
          method: 'GET',
          domain: 'Shipment',
        }
      },
      providesTags: (result, error) => [{ type: 'Shipments', id: 'Shipments' }],
    }),
  }),
})

export const {
  useGetJourneysByTenantIdQuery,
  useGetJourneyByTenantIdQuery,
  useUpdateTransportJourneyMutation,
  useUpdateJourneyMutation,
  useApproveJourneyMutation,
  useGetCarriersQuery,
  useGetUnassignedLoadsQuery,
  useSaveDraftJourneyMutation,
  useGetJourneyLoadsByIdQuery,
  useCreateLocationMutation,
  useUpdateLocationMutation,
  useGetLocationsQuery,
  useGetCountriesQuery,
  useGetCurrenciesByCustomerIdQuery,
  useGetCurrenciesByTenantIdQuery,
  useAddCurrencyMutation,
  useUpdateCurrencyMutation,
  useDeleteCurrencyByIdMutation,
  useCreateShipmentMutation,
  useGetAllPackagingTypesQuery,
  useUpdateShipmentMutation,
  useGetShipmentByIdQuery,
  useGetTemperatureRangesQuery,
  useGetAllPackagingTemplatesQuery,
  useSubmitShipmentMutation,
  useAcceptShipmentByTenantIdMutation,
  useGetAllShipmentsQuery,
  useResetReviewShipmentByTenantIdMutation,
  useReviewShipmentByTenantIdMutation,
  useRejectShipmentMutation,
  useRecallShipmentMutation,
  useGetShipmentSummariesQuery,
} = apiShipment
