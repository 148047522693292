import { createSelector } from '@reduxjs/toolkit'
import { makeTypedFetchSelectors } from '../app/redux-fetch'
import { RootState } from '../app/store/store'
import { TENANT_REDUCER } from './TenantSettings/consts'
import {
  APP_LOGGED_IN_CUSTOMER_REDUCER_NAME,
  APP_LOGGED_IN_USER_REDUCER_NAME,
  APP_REDUCER_NAME,
  TENANT_ALL_USERS,
} from './const'
import { IEmployee, ILoggedInCustomer, ILoggedInUser } from './types'

export const fetchLoggedInUserSelector = makeTypedFetchSelectors<ILoggedInUser>(
  APP_REDUCER_NAME,
  APP_LOGGED_IN_USER_REDUCER_NAME
)

export const isUserCanAcceptShipmentSelector = createSelector(
  fetchLoggedInUserSelector.data,
  (user): boolean => {
    return (
      user?.userGroups.some((ug) =>
        ug.roles.some((r) => r.securityPrincipals.some((s) => s.name === 'Shipment.Accept'))
      ) ?? false
    )
  }
)

export const isTenantUserSelector = createSelector(fetchLoggedInUserSelector.data, (user) => {
  user?.tenantId && !user.customerId
})

export const fetchLoggedInCustomerSelector = makeTypedFetchSelectors<ILoggedInCustomer>(
  APP_REDUCER_NAME,
  APP_LOGGED_IN_CUSTOMER_REDUCER_NAME
)

export const fetchTenantAllUsersSelector = makeTypedFetchSelectors<IEmployee[]>(
  TENANT_REDUCER,
  TENANT_ALL_USERS
)

export const getIsCustomerUserTypeSelector = createSelector(
  fetchLoggedInUserSelector.data,
  (loggedInUser) => (loggedInUser?.customerId && loggedInUser?.tenantId ? true : false)
)

export const fetchLoggedInUserOrDefaultSelector = createSelector(
  fetchLoggedInUserSelector.data,
  (loggedInUser) => loggedInUser || ({} as ILoggedInUser)
)

export const isSignInSelector = createSelector(
  (state: RootState) => state.portal,
  (state) => {
    return state.isSigninUser
  }
)
