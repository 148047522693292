import { useGetProductCategoryByIdQuery } from '../../../app/redux-fetch/apiQuery'
import SpinnerModalBlock from '../../../components/Spinner/SpinnerModalBlock'
import { useProductTabContext } from '../ProductTabContext'
import ProductCategoryForm from './ProductCategoryForm'

type ProductModalProps = {
  productCategoryId: number
  isDisabled?: boolean
}
const ProductCategoryModal = ({ productCategoryId, isDisabled = false }: ProductModalProps) => {
  const { customerId } = useProductTabContext()

  const newProductCategory = {
    hsLocalTariffs: [],
    customerId,
  }

  const { data, isSuccess } = useGetProductCategoryByIdQuery({
    customerId,
    categoryId: productCategoryId,
  })
  if (!isSuccess) return <SpinnerModalBlock />

  return (
    <ProductCategoryForm productCategory={data ?? newProductCategory} isDisabled={isDisabled} />
  )
}

export default ProductCategoryModal
