import { Box } from '@mui/material'
import { useSideModalContext } from './SideModalContext'


const FixedBottomBox = () => {
    const { bottomComponent } = useSideModalContext()
    return (
        <>
            {bottomComponent && (<Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    width: '100%',
                    padding: '16px',
                    backgroundColor: '#fff',
                    borderTop: '1px solid #e0e0e0',
                    zIndex: 999,
                }}
            >
                {bottomComponent}
            </Box>)}
        </>
    )
}

export default FixedBottomBox