import { Box, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { InputHeader } from '../Headers/styles';

type FormInputProps = TextFieldProps & {
  name: string;
  label: string;
  unit?: string;
};

const FormInput: React.FC<FormInputProps> = ({ name, label, unit, ...props }) => {
  const { control } = useFormContext();
  return (
    <Box mb={2}>
      <InputHeader>
        {label}
      </InputHeader>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            {...props}
            error={!!error}
            helperText={error?.message}
            variant="outlined"
            fullWidth
            InputProps={{
              endAdornment: unit ? <InputAdornment position="end">{unit}</InputAdornment> : null,
            }}
            onChange={(e) => {
              const value = props.type === 'number' ? Number(e.target.value) : e.target.value;
              field.onChange(value);
            }}
          />
        )}
      />
    </Box>
  );
};

export default FormInput;