import { defineMessages } from 'react-intl'

const messages = defineMessages({
  formDropDownSelectOption: {
    id: 'formdropdown.selectoption',
    description: 'Form dropdown select an option text',
    defaultMessage: 'Please Select',
  },
  formDropDownShowSystem: {
    id: 'formdropdown.showSystem',
    description: 'Form dropdown show system option text',
    defaultMessage: 'Show System {entities}',
  },
  formDropDownHideSystem: {
    id: 'formdropdown.hideSystem',
    description: 'Form dropdown hide system option text',
    defaultMessage: 'Hide System {entities}',
  },
})

export default messages
