import AddIcon from '@mui/icons-material/Add';
import { Button, FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useGetCurrenciesByCustomerIdQuery } from '../../app/redux-fetch/apiShipment';
import ToggleContainer from '../../components/Containers/ToggleContainer';
import FormInput from '../../components/Forms/FormInput';
import messages from './messages';
import ProductForm from './ProductForm';

interface PackageContentFormProps {
    consignmentIndex: number;
    packagingIndex: number;
}

const PackageContentForm: React.FC<PackageContentFormProps> = ({ consignmentIndex, packagingIndex }) => {
    const { control, setValue, register, watch } = useFormContext();

    const { formatMessage } = useIntl();

    const { fields: products, append: appendProduct, remove: removeProduct } = useFieldArray({
        control,
        name: `consignments.${consignmentIndex}.packaging.${packagingIndex}.products`,
    });


    const customerId = watch('customerId')

    const { data: currencies = [] } = useGetCurrenciesByCustomerIdQuery({
        customerId: customerId ?? 0, hideDisabled: true,
    })

    const currencyId = watch(`consignments.${consignmentIndex}.currencyId`)


    const [isOpenPackageContent, setIsOpenPackageContent] = React.useState(false);

    const handleTogglePackageContent = () => {
        setIsOpenPackageContent((prev) => !prev);
    };

    const useDeclaredValue = watch(
        `consignments.${consignmentIndex}.packaging.${packagingIndex}.useDeclaredValue`,
        false
    );

    const resetDeclaredValues = () => {
        setValue(`consignments.${consignmentIndex}.packaging.${packagingIndex}.declaredContentDescription`, undefined);
        setValue(`consignments.${consignmentIndex}.packaging.${packagingIndex}.declaredValue`, undefined);
        products.forEach((_, productIndex) => {
            removeProduct(productIndex);
        });
    };

    const handleToggleDeclaredValue = () => {
        console.log('useDeclaredValue');
        resetDeclaredValues();
    };

    return (
        <>
            {!isOpenPackageContent && (
                <Button onClick={handleTogglePackageContent} sx={{ textTransform: 'none', marginBottom: 2 }}>
                    {formatMessage(messages.showPackageContents)}
                </Button>
            )}
            <ToggleContainer
                title={formatMessage(messages.packageContent)}
                isOpen={isOpenPackageContent}
                handleToggle={handleTogglePackageContent}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            name={`consignments.${consignmentIndex}.packaging.${packagingIndex}.useDeclaredValue`}
                            control={control}
                            defaultValue={false}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={<Switch
                                        {...field}
                                        checked={field.value}
                                        onChange={(e, checked) => {
                                            field.onChange(checked)
                                            handleToggleDeclaredValue()
                                        }}
                                    />}
                                    label={field.value ? formatMessage(messages.declaredValue) : formatMessage(messages.specifyContent)}
                                />
                            )}
                        />
                    </Grid>
                    {useDeclaredValue ? (
                        <>
                            <Grid item xs={12}>
                                <TextField
                                    label={formatMessage(messages.contentDescription)}
                                    {...register(
                                        `consignments.${consignmentIndex}.packaging.${packagingIndex}.declaredContentDescription`,
                                        { required: true }
                                    )}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormInput
                                    name={`consignments.${consignmentIndex}.packaging.${packagingIndex}.declaredValue`}
                                    label={formatMessage(messages.unitPrice)}
                                    InputLabelProps={{ shrink: true }}
                                    type='number'
                                    unit={currencies.find(currency => currency.id === currencyId)?.localisedSymbol}
                                    fullWidth
                                />
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Grid item xs={12}>
                                <Button
                                    variant="outlined"
                                    startIcon={<AddIcon />}
                                    onClick={() =>
                                        appendProduct({
                                            productDescription: '',
                                            countryOfOrigin: '',
                                            quantity: 1,
                                            unitPrice: 0,
                                            totalValue: 0,
                                            currency: '',
                                            useProduct: false,
                                        })
                                    }
                                    sx={{ marginBottom: 2 }}
                                >
                                    {formatMessage(messages.addProduct)}
                                </Button>
                            </Grid>

                            {products.map((product, productIndex) => (
                                <Grid item xs={12} key={product.id}>
                                    <ProductForm
                                        consignmentIndex={consignmentIndex}
                                        packagingIndex={packagingIndex}
                                        productIndex={productIndex}
                                        removeProduct={removeProduct}
                                    />
                                </Grid>
                            ))}
                        </>
                    )}
                </Grid>
            </ToggleContainer>
        </>
    );
};

export default PackageContentForm;
