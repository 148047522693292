import { type ReactElement } from 'react'
import Loading from '../../assets/loading.gif'
import { SpinnerCenter } from './styles'

const SpinnerBlock = (): ReactElement<any, any> => {
  return (
    <SpinnerCenter>
      <img src={Loading} alt="Loading..." />
    </SpinnerCenter>
  )
}

export default SpinnerBlock
