import { Breadcrumbs, Link, Typography, useTheme } from '@mui/material'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import HomeIcon from '../../assets/icons/homeIcon.svg'
import rootEnum from '../Routes/rootEnum'

function getLastPartOfUrl(url: string): string {
  const parts = url.split('/')
  return parts[parts.length - 1]
}

const NotClickableBreadcrumbs = ['/shipping', '/datasets', '/transport', '/routes', '/settings']

const PretifyLink = [
  {
    link: getLastPartOfUrl(rootEnum.USER_MANAGEMENT),
    display: 'User Management',
  },
  {
    link: getLastPartOfUrl(rootEnum.ROLES_PERMISSIONS),
    display: 'Roles & Permissions',
  },
  {
    link: getLastPartOfUrl(rootEnum.ASSET_MANAGEMENT),
    display: 'Asset Management',
  },
  {
    link: getLastPartOfUrl(rootEnum.TRANSPORT_JOB_LISTS),
    display: 'Transport Jobs',
  }
]

function displayLink(value: string): string {
  const pretifyLink = PretifyLink.find((link) => link.link === value)
  if (pretifyLink) return pretifyLink.display
  return value.charAt(0).toUpperCase() + value.slice(1)
}

const LocationBasedBreadcrumbs: React.FC = () => {
  const location = useLocation()
  const theme = useTheme()
  const pathnames = location.pathname.split('/').filter((x) => x)

  return (
    <Breadcrumbs aria-label='breadcrumb'>
      <Link component={RouterLink} to='/'>
        <img
          src={HomeIcon}
          alt='omni'
          style={{ width: 24, height: 24, color: 'white', marginTop: '5px' }}
        />
      </Link>
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1
        const to = `/${pathnames.slice(0, index + 1).join('/')}`
        const toProp = NotClickableBreadcrumbs.includes(to)
          ? { sx: { cursor: 'pointer', fontWeight: 400, color: theme.palette.secondary.main } }
          : { to, component: RouterLink }

        return last ? (
          <Typography
            color='textPrimary'
            key={to}
            sx={{ cursor: 'pointer', fontWeight: 700, color: theme.palette.secondary.main }}
          >
            {displayLink(value)}
          </Typography>
        ) : (
          <Link
            underline='none'
            key={to}
            sx={{ fontWeight: 500, color: theme.palette.secondary.main }}
            {...toProp}
          >
            {displayLink(value)}
          </Link>
        )
      })}
    </Breadcrumbs>
  )
}

export default LocationBasedBreadcrumbs
