import { defineMessages } from 'react-intl'

const messages = defineMessages({
  title: {
    id: 'trackingNumber.title',
    description: 'Tracking number title',
    defaultMessage: 'Tracking number',
  },
  save: {
    id: 'trackingNumber.saveBtn',
    description: 'Save tracking number button name',
    defaultMessage: 'Save',
  },
  barcode: {
    id: 'trackingNumber.barcodeExample',
    description: 'Barcode example title',
    defaultMessage: 'Tracking number - Barcode example',
  },
  qrCode: {
    id: 'trackingNumber.qrCodeExample',
    description: 'QR code example title',
    defaultMessage: 'Tracking number - QR code example',
  },
  confirmDialogTitle: {
    id: 'trackingNumber.confirmDialogTitle',
    description: 'Save configmration dialog title',
    defaultMessage: 'Save tracking template number',
  },
  confirmDialogContent: {
    id: 'trackingNumber.confirmDialogContent',
    description: 'Save configmration dialog content',
    defaultMessage:
      'You can only set your tracking number template once. Once set, this cannot be changed. Are you sure you wish to continue?',
  },
  confirmDialogYes: {
    id: 'trackingNumber.confirmDialogYes',
    description: 'Save configmration dialog yes button name',
    defaultMessage: 'Yes',
  },

  confirmDialogNo: {
    id: 'trackingNumber.confirmDialogNo',
    description: 'Save configmration dialog no button name',
    defaultMessage: 'No',
  },
})

export default messages
