import { createSlice } from '@reduxjs/toolkit'
import {
  fetchApiDataThunk,
  fetchBrandingDataThunk,
  fetchCustomerSettingsDataThunk,
  makeFetchSlice,
} from '../app/redux-fetch'
import { AppDispatch } from '../app/store/store'
import { fetchAllTenantUsers, fetchLoggedInCustomer, fetchLoggedInUser } from './api'
import {
  APP_IS_SIGN_USER_REDUCER_NAME,
  APP_LOGGED_IN_CUSTOMER_REDUCER_NAME,
  APP_LOGGED_IN_USER_REDUCER_NAME,
  TENANT_ALL_USERS,
} from './const'
import logger from '../app/middleware/log'

const fetchLoggedInUserSlice = makeFetchSlice(APP_LOGGED_IN_USER_REDUCER_NAME)

export const fetchLoggedInUserReducers = fetchLoggedInUserSlice.reducer
export const fetchLoggedInUserActions = fetchLoggedInUserSlice.actions

export const fetchLoggedInUserThunk = ({
  tenantId,
  customerId,
}: {
  tenantId?: number
  customerId?: number
}) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchBrandingDataThunk({
        dispatch,
        actions: fetchLoggedInUserActions,
        asyncFunc: () => fetchLoggedInUser(tenantId, customerId),
      })
    } catch (error) {
      logger.error('🚀 Error fetching logged in user', error)
    }
  }
}

const fetchLoggedInCustomerSlice = makeFetchSlice(APP_LOGGED_IN_CUSTOMER_REDUCER_NAME)

export const fetchLoggedInCustomerReducers = fetchLoggedInCustomerSlice.reducer
export const fetchLoggedInCustomerActions = fetchLoggedInCustomerSlice.actions

export const fetchLoggedInCustomerThunk = ({
  tenantId,
  customerId,
}: {
  tenantId: number
  customerId: number
}) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchCustomerSettingsDataThunk({
        dispatch,
        actions: fetchLoggedInCustomerActions,
        asyncFunc: () => fetchLoggedInCustomer({ tenantId, customerId }),
      })
    } catch (error) {
      logger.error('🚀 Error fetching logged in customer', error)
    }
  }
}

const fetchAllTenantUsersSlice = makeFetchSlice(TENANT_ALL_USERS)

export const fetchAllTenantUsersReducers = fetchAllTenantUsersSlice.reducer
export const fetchAllTenantUsersActions = fetchAllTenantUsersSlice.actions

export const fetchAllTenantUsersThunk = (tenantId: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchApiDataThunk({
        dispatch,
        actions: fetchAllTenantUsersActions,
        asyncFunc: () => fetchAllTenantUsers(tenantId),
      })
    } catch (error) {
      logger.error('🚀 Error fetching logged in customer', error)
    }
  }
}

export interface IsSignState {
  isSignIn: boolean
}

const initialState: IsSignState = {
  isSignIn: false,
}

const counterSlice = createSlice({
  name: APP_IS_SIGN_USER_REDUCER_NAME,
  initialState,
  reducers: {
    signIn: (state) => {
      state.isSignIn = true
    },
    signOut: (state) => {
      state.isSignIn = false
    },
  },
})

export const isSigninUserReducer = counterSlice.reducer
export const isSigninUserActions = counterSlice.actions
