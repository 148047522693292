import { defineMessages } from 'react-intl'

const messages = defineMessages({
    noActiveAccount: {
        id: 'api.noActive Account',
        description: 'No active account! Verify a user has been signed in.',
        defaultMessage: 'No active account!',
    },
})

export default messages
