import { Typography, styled } from '@mui/material'

export const UppercaseTypography = styled(Typography)`
  margin-top: 8px;
  font-size: 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  font-family: ${(props) => props.theme.typography.fontFamily};
  text-color: ${(props) => props.theme.palette.primary.contrastText};
`
