import AddIcon from '@mui/icons-material/Add';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import ToggleContainer from '../../components/Containers/ToggleContainer';
import FlowSection from './FlowSection';
import TransitDetailsForm from './TransitDetailsForm';
import WaypointForm from './WaypointForm';
import messages from './messages';

interface WaypointContainerProps {
    consignmentIndex: number;
}

const WaypointContainer: React.FC<WaypointContainerProps> = ({
    consignmentIndex,
}: WaypointContainerProps) => {
    const { control } = useFormContext();
    const [isOpenTransitDetails, setIsOpenTransitDetails] = useState(false);
    const { formatMessage } = useIntl();

    const handleToggleTransitDetail = () => {
        setIsOpenTransitDetails((prev) => !prev);
    };

    const { insert, fields, remove, append } = useFieldArray({
        control,
        name: `consignments.${consignmentIndex}.waypoints`,
    });

    return (
        <>
            <FlowSection icon={<LocalShippingIcon />} showIcon={isOpenTransitDetails}>
                <Box sx={{ pt: '16px' }}>
                    <ToggleContainer
                        title={formatMessage(messages.transitDetails)}
                        isOpen={isOpenTransitDetails}
                        handleToggle={handleToggleTransitDetail}
                    >
                        <TransitDetailsForm
                            namePrefix={`consignments.${consignmentIndex}.shipFrom.transitDetails`}
                        />
                    </ToggleContainer>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
                    <Box>
                        {!isOpenTransitDetails && (
                            <Button onClick={handleToggleTransitDetail} sx={{ textTransform: 'none', marginBottom: 2 }}>
                                {formatMessage(messages.showTransitDetails)}
                            </Button>
                        )}
                    </Box>
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            startIcon={<AddIcon />}
                            onClick={() => append({ location: '' })}
                            sx={{ textTransform: 'none' }}
                        >
                            {formatMessage(messages.addWaypoint)}
                        </Button>
                    </Box>
                </Box>
            </FlowSection>

            {fields.map((field, waypointIndex) => (
                <>
                    <WaypointForm
                        key={field.id}
                        consignmentIndex={consignmentIndex}
                        waypointIndex={waypointIndex}
                        removeWaypoint={remove}
                        insertWaypoint={insert}
                    />
                </>
            ))}

        </>
    );
};

export default WaypointContainer;
