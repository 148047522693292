import { Box } from '@mui/material'

interface ICenteredBoxProps {
    children?: React.ReactNode
}

const CenteredBox = ({ children }: ICenteredBoxProps) => {
    return (<Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={'100%'}
    >
        {children}
    </Box>
    )
}

export default CenteredBox