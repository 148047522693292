import {
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Switch
} from '@mui/material'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useGetAllTransportModesQuery } from '../../app/redux-fetch/apiQuery'
import { convertToLabelValue } from '../../app/utils'
import FormInput from '../../components/Forms/FormInput'
import FormSelect from '../../components/Forms/FormSelect'
import messages from './messages'
import { IShipmentFormInput } from './ShipmentForm'

interface TransitDetailsFormProps {
    namePrefix: string
}

const TransitDetailsForm: React.FC<TransitDetailsFormProps> = ({ namePrefix }) => {
    const { control } = useFormContext()
    const { formatMessage } = useIntl()

    const carrierOption = useWatch({
        name: `${namePrefix}.carrierOption`,
    })

    const { data: transportModes = [] } = useGetAllTransportModesQuery()

    const transportModeOptions = useMemo(() => {
        return convertToLabelValue(transportModes)
    }, [transportModes])


    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <FormSelect
                        name={`${namePrefix}.modeOfTransit`}
                        label={formatMessage(messages.modeOfTransit)}
                        options={transportModeOptions}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={8} container justifyContent="center" alignItems="center">
                    <Controller
                        name={`${namePrefix}.carrierOption` as keyof IShipmentFormInput}
                        control={control}
                        render={({ field }) => (
                            <RadioGroup {...field} row>
                                <FormControlLabel
                                    value='noPreference'
                                    control={<Radio />}
                                    label={formatMessage(messages.noPreference)}
                                />
                                <FormControlLabel
                                    value='preferredCarrier'
                                    control={<Radio />}
                                    label={formatMessage(messages.preferredCarrier)}
                                />
                                <FormControlLabel
                                    value='preBookedTransit'
                                    control={<Radio />}
                                    label={formatMessage(messages.preBookedTransit)}
                                />
                            </RadioGroup>
                        )}
                    />
                </Grid>
                {(carrierOption === 'preferredCarrier' || carrierOption === 'preBookedTransit') && (
                    <Grid container spacing={2} sx={{ marginTop: 2 }}>
                        <Grid item xs={12} md={6}>
                            <FormInput
                                name={`${namePrefix}.carrierId`}
                                label={formatMessage(messages.carrier)}
                                placeholder={formatMessage(messages.enterCarrier)}
                                fullWidth
                            />
                        </Grid>
                        {carrierOption === 'preBookedTransit' && (
                            <Grid item xs={12} md={6}>
                                <FormInput
                                    name={`${namePrefix}.transitIdentifier`}
                                    label={formatMessage(messages.transitIdentifier)}
                                    placeholder={formatMessage(messages.enterTransitIdentifier)}
                                    fullWidth
                                />
                            </Grid>
                        )}
                    </Grid>
                )}
                <Grid item xs={12} md={2} container justifyContent="center" alignItems="center">
                    <Controller
                        name={`${namePrefix}.crossBorder` as keyof IShipmentFormInput}
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={<Switch {...field} checked={Boolean(field.value)} />}
                                label={formatMessage(messages.crossBorder)}
                                sx={{ marginTop: 2 }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={2} container justifyContent="center" alignItems="center">
                    <Controller
                        name={`${namePrefix}.assetRequired` as keyof IShipmentFormInput}
                        control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                control={<Switch {...field} checked={Boolean(field.value)} />}
                                label='Specify asset'
                                sx={{ marginTop: 2 }}
                            />
                        )}
                    />
                </Grid>
                {useWatch({ name: `${namePrefix}.assetRequired` }) && (
                    <>
                        <Grid item xs={12} md={4} justifyContent="center" alignItems="center">
                            <FormSelect
                                name={`${namePrefix}.asset`}
                                label='Asset'
                                options={[
                                    { label: 'Truck', value: 'truck' },
                                    { label: 'Container', value: 'container' },
                                    { label: 'Pallet', value: 'pallet' },
                                ]}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={4} justifyContent="center" alignItems="center">
                            <FormInput
                                name={`${namePrefix}.numberOfAssets`}
                                label='Number of Assets'
                                placeholder='Enter number of assets'
                                type='number'
                                fullWidth
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </>
    )
}

export default TransitDetailsForm
