import { IShipmentFormInput } from './ShipmentForm'
import { ShipmentDto, TransitDetailDto } from './types'

export const getCustomerName = (customers: any[], customerId: number) => {
  return customers.find((customer) => customer.id === customerId)?.name
}

export const mapToShipmentFormInput = (shipmentData: ShipmentDto): IShipmentFormInput => {
  if (
    shipmentData.tenantId === undefined ||
    shipmentData.customerId === undefined ||
    !shipmentData.consignments
  ) {
    throw new Error('Required shipment data is missing.')
  }

  const mapTransitDetail = (transitDetail: TransitDetailDto | null | undefined) => {
    if (!transitDetail) return undefined

    return {
      id: transitDetail.id,
      carrierId: transitDetail.carrier?.id ?? undefined,
      modeOfTransit: transitDetail.transportModeId ?? undefined,
      transitIdentifier: transitDetail.transitIdentifier ?? undefined,
      consignmentStopId: transitDetail.consignmentStopId ?? undefined,
      crossBorder: transitDetail.isCrossBorder ?? undefined,
    }
  }

  return {
    id: shipmentData.id,
    tenantId: shipmentData.tenantId,
    revision: shipmentData.revision,
    customerId: shipmentData.customerId,
    shipperId: shipmentData.shipperId ?? undefined,
    customerReferenceNumber: shipmentData.customerReferenceNumber,
    shipmentNumber: shipmentData.trackingNumber ?? undefined,
    description: shipmentData.description ?? undefined,
    consignments: shipmentData.consignments.map((consignment) => {
      if (!consignment.stops) {
        throw new Error('Consignment stops are missing.')
      }

      const shipFromStop = consignment.stops.find((stop) => stop.consignmentStopTypeId === 1)
      const deliverToStop =
        consignment.stops.find((stop) => stop.consignmentStopTypeId === 3) || shipFromStop
      const waypointStops = consignment.stops.filter((stop) => stop.consignmentStopTypeId === 2)
      if (!shipFromStop || !deliverToStop) {
        throw new Error('ShipFrom or DeliverTo stop is missing.')
      }

      const shipFrom = {
        id: shipFromStop.id,
        locationId: shipFromStop.locationId,
        consignmentId: consignment.id,
        loadingDate: shipFromStop.scheduledDate ? new Date(shipFromStop.scheduledDate) : new Date(),
        clearingRequired: shipFromStop.clearingRequired ?? undefined,
        contactId: shipFromStop.contactId ?? undefined,
        returnTo: consignment.returnLocationId === shipFromStop.locationId,
        transitDetails: mapTransitDetail(shipFromStop.transitDetail),
      }

      const deliverTo = {
        id: deliverToStop.id,
        locationId: deliverToStop.locationId,
        consignmentId: consignment.id,
        deliveryDate: deliverToStop.scheduledDate
          ? new Date(deliverToStop.scheduledDate)
          : new Date(),
        clearingRequired: deliverToStop.clearingRequired ?? undefined,
        contactId: deliverToStop.contactId ?? undefined,
        deliveryInstructions: deliverToStop.instructions ?? undefined,
      }

      const waypoints = waypointStops.map((stop) => {
        return {
          id: stop.id,
          locationId: stop.locationId,
          consignmentId: consignment.id,
          loadingDate: new Date(stop.scheduledDate),
          clearingRequired: stop.clearingRequired ?? undefined,
          deliveryInstructions: stop.instructions,
          transitDetails: mapTransitDetail(stop.transitDetail),
        }
      })

      const returnTo =
        consignment.returnLocationId != null
          ? {
              locationId: consignment.returnLocationId,
            }
          : undefined

      const packaging = consignment.packages?.map((pkg) => {
        if (!pkg.detail || !pkg.detail.physicalProperties) {
          throw new Error('Package detail or physical properties are missing.')
        }

        const physicalProps = pkg.detail.physicalProperties
        const packagingItem: any = {
          id: pkg.id,
          consignmentId: consignment.id,
          packagingDetailId: pkg.detail.id,
          packagingTypeId: pkg.detail.packagingTypeId ?? undefined,
          numberOfPackages: pkg.quantity ?? undefined,
          packagingTemplateId: pkg.detail.packagingTemplateId ?? undefined,
          physicalPropertiesId: physicalProps.id,
          weight: physicalProps.grossWeight ?? undefined,
          length: physicalProps.length ?? undefined,
          width: physicalProps.width ?? undefined,
          height: physicalProps.height ?? undefined,
          isStackable: pkg.detail.isStackable ?? undefined,
          weightUnit: physicalProps.weightMeasureUnitId ?? undefined,
          dimensionUnit: physicalProps.lengthMeasureUnitId ?? undefined,
        }

        if (pkg.contents && pkg.contents.length > 0) {
          packagingItem.products = pkg.contents.map((content) => {
            if (content.quantity === undefined || content.unitPrice === undefined) {
              throw new Error('Product content missing required fields.')
            }

            return {
              id: content.id,
              productDescription: content.description ?? undefined,
              productId: content.productId ?? undefined,
              countryOfOriginId: content.countryOfOriginId ?? undefined,
              quantity: content.quantity,
              unitPrice: content.unitPrice,
              totalValue: content.totalValue,
              useProduct: !!content.productId,
            }
          })
        }

        if (pkg.temperatureSetting) {
          Object.assign(packagingItem, {
            temperatureControlled: true,
            id: pkg.temperatureSetting.id,
            temperatureRange: pkg.temperatureSetting.temperatureRangeId ?? undefined,
            setPointUnitId: pkg.temperatureSetting.temperatureUnitId ?? undefined,
            setPoint: pkg.temperatureSetting.setPoint ?? undefined,
            lowerWarning: pkg.temperatureSetting.lowerThresholdWarning ?? undefined,
            upperWarning: pkg.temperatureSetting.upperThresholdWarning ?? undefined,
            lowerCritical: pkg.temperatureSetting.lowerThresholdCritical ?? undefined,
            upperCritical: pkg.temperatureSetting.upperThresholdCritical ?? undefined,
          })
        }

        if (pkg.declaredValue !== undefined && pkg.declaredValue !== null) {
          packagingItem.useDeclaredValue = true
          packagingItem.declaredContentDescription =
            pkg.contents?.map((content) => content.description).join(', ') || ''
          packagingItem.amountInsured = pkg.declaredValue
          packagingItem.insuranceRequired = true
        }

        return packagingItem
      })

      return {
        id: consignment.id,
        referenceNumber: consignment.referenceNumber,
        trackingNumber: consignment.trackingNumber,
        currencyId: consignment.currencyId ?? undefined,
        description: consignment.description ?? undefined,
        shipmentId: shipmentData.id,
        returnTo,
        shipFrom,
        deliverTo,
        packaging,
        waypoints,
      }
    }),
  }
}

export const mapToShipmentData = (data: IShipmentFormInput): ShipmentDto => {
  const shipmentData: ShipmentDto = {
    id: data.id,
    customerId: data.customerId,
    shipperId: data.shipperId,
    description: data.description,
    tenantId: data.tenantId,
    revision: data.revision,
    customerReferenceNumber: data.customerReferenceNumber,
    consignments: data.consignments?.map((consignment) => {
      return {
        id: consignment.id,
        shipmentId: data.id,
        referenceNumber: consignment.referenceNumber,
        trackingNumber: consignment.trackingNumber,
        currencyId: consignment.currencyId,
        description: consignment.description,
        returnLocationId: consignment.shipFrom.returnTo
          ? consignment.shipFrom.locationId
          : consignment.deliverTo.locationId,
        packages: consignment.packaging?.map((packaging) => {
          return {
            id: packaging.id,
            consignmentId: consignment.id,
            packagingDetailId: packaging.packagingDetailId,
            detail: {
              id: packaging.packagingDetailId,
              packagingTypeId: packaging.packagingTypeId,
              packagingTemplateId: packaging.packagingTemplateId,
              physicalPropertiesId: packaging.physicalPropertiesId,
              physicalProperties: {
                id: packaging.physicalPropertiesId,
                weightMeasureUnitId: packaging.weightUnit,
                lengthMeasureUnitId: packaging.dimensionUnit,
                grossWeight: packaging.weight,
                height: packaging.height,
                width: packaging.width,
                length: packaging.length,
              },
              isStackable: packaging.isStackable,
            },
            ...(packaging.temperatureControlled && {
              temperatureSetting: {
                temperatureControlled: packaging.temperatureControlled,
                temperatureRangeId: packaging.temperatureRange,
                temperatureUnitId: packaging.setPointUnitId,
                setPoint: packaging.setPoint,
                lowerThresholdWarning: packaging.lowerWarning,
                lowerThresholdCritical: packaging.lowerCritical,
                upperThresholdWarning: packaging.upperWarning,
                upperThresholdCritical: packaging.upperCritical,
              },
            }),
            quantity: packaging.numberOfPackages,
            isHazardous: false,
            useDeclaredValue: packaging.useDeclaredValue,
            declaredContentDescription: packaging.declaredContentDescription,
            temperatureControlled: packaging.temperatureControlled,
            temperatureRange: packaging.temperatureRange,
            insuranceRequired: packaging.insuranceRequired,
            amountInsured: packaging.amountInsured,
            value: packaging.declaredValue,
            contents: !packaging.useDeclaredValue
              ? packaging.products?.map((product) => {
                  return {
                    id: product.id,
                    packagingId: packaging.id,
                    description: product.productDescription,
                    countryOfOriginId: product.countryOfOriginId,
                    quantity: product.quantity,
                    unitPrice: product.unitPrice,
                    productId: product.useProduct ? product.productId : undefined,
                  }
                }) ?? []
              : [],
          }
        }, []),
        stops: [
          {
            id: consignment.shipFrom.id,
            locationId: consignment.shipFrom.locationId,
            scheduledDate: consignment.shipFrom.loadingDate,
            consignmentStopTypeId: 1,
            consignmentId: consignment.id,
            clearingRequired: consignment.shipFrom.clearingRequired,
            sequence: 1,
            transitDetail:
              consignment.shipFrom.transitDetails &&
              Object.keys(consignment.shipFrom.transitDetails).length > 0 &&
              consignment.shipFrom.transitDetails.modeOfTransit
                ? {
                    id: consignment.shipFrom.transitDetails.id,
                    consignmentStopId: consignment.shipFrom.id,
                    transportModeId: consignment.shipFrom.transitDetails.modeOfTransit,
                    isCrossBorder: consignment.shipFrom.transitDetails.crossBorder,
                    carrierId: consignment.shipFrom.transitDetails.carrierId,
                    transitIdentifier: consignment.shipFrom.transitDetails.transitIdentifier,
                  }
                : undefined,
          },
          ...(consignment.waypoints?.map((waypoint, idx) => ({
            id: waypoint.id,
            locationId: waypoint.locationId,
            scheduledDate: waypoint.loadingDate,
            consignmentStopTypeId: 2,
            consignmentId: consignment.id,
            sequence: idx + 2,
            transitDetail:
              waypoint.transitDetails &&
              Object.keys(waypoint.transitDetails).length > 0 &&
              waypoint.transitDetails.modeOfTransit
                ? {
                    id: waypoint.transitDetails.id,
                    consignmentStopId: waypoint.id,
                    transportModeId: waypoint.transitDetails.modeOfTransit,
                    isCrossBorder: waypoint.transitDetails.crossBorder,
                    carrierId: waypoint.transitDetails.carrierId,
                    transitIdentifier: waypoint.transitDetails.transitIdentifier,
                  }
                : undefined,
          })) ?? []),
          {
            id: consignment.deliverTo.id,
            locationId: consignment.deliverTo.locationId,
            scheduledDate: consignment.deliverTo.deliveryDate,
            consignmentStopTypeId: 3,
            consignmentId: consignment.id,
            sequence: consignment.waypoints?.length ? consignment.waypoints?.length + 1 : 0 + 2,
            instructions: consignment.deliverTo.deliveryInstructions,
          },
        ],
      }
    }),
  }
  return shipmentData
}
