import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { Formik } from 'formik'
import { useIntl } from 'react-intl'
import { useNotificationStack } from '../../../../app/hooks/useNotificationStack'
import {
  useAddContactCategoryMutation,
  useUpdateContactCategoryMutation,
} from '../../../../app/redux-fetch/apiCoreQuery'
import commonMessages from '../../../../components/Messages/commonMessages'
import RequiredAsterisk from '../../../../components/RequiredAsterisk/RequiredAsterisk'
import { SectionLabelTypography } from '../../../../components/Typographies/styles'
import { IContactCategory } from '../../../Contacts/types'
import messages from '../messages'
import { contactCategoryValidationSchema } from './contactCategoryValidationSchema'

type ContactCategoryFormProps = {
  category: IContactCategory
  handleClose: () => void
  isOpen: boolean
}

const ContactCategoryForm = ({ category, handleClose, isOpen }: ContactCategoryFormProps) => {
  const { formatMessage } = useIntl()
  const isNew = !category?.id || category?.id === 0

  const [addContactCategory] = useAddContactCategoryMutation()
  const [updateContactCategory] = useUpdateContactCategoryMutation()

  const { enqueueSuccess, enqueueFailure } = useNotificationStack()

  const onSubmit = async (data: IContactCategory) => {
    try {
      if (isNew) {
        await addContactCategory(data).unwrap()
        enqueueSuccess(messages.contactCategoryCreated)
      } else {
        await updateContactCategory(data).unwrap()
        enqueueSuccess(messages.contactCategoryUpdated)
      }
    } catch (error) {
      if (isNew) {
        enqueueFailure(messages.contactCategoryNotCreated)
      } else {
        enqueueFailure(messages.contactCategoryNotUpdated)
      }
    } finally {
      handleClose()
    }
  }

  return (
    <Formik
      initialValues={category}
      validationSchema={contactCategoryValidationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
        <Dialog open={isOpen} onClose={handleClose}>
          <form onSubmit={handleSubmit}>
            <DialogTitle>
              <Typography sx={{ fontWeight: 700, fontSize: '20px' }}>
                {formatMessage(messages.createEditContactType)}
              </Typography>
            </DialogTitle>
            <DialogContent sx={{ minWidth: '450px' }}>
              <Box sx={{ mb: '16px' }}>
                <SectionLabelTypography variant='subtitle1' sx={{ mb: '4px' }}>
                  {formatMessage(messages.contactCategory)}
                  <RequiredAsterisk />
                </SectionLabelTypography>
                <TextField
                  fullWidth
                  id='name'
                  name='name'
                  value={values.name || ''}
                  inputProps={{ maxLength: 100 }}
                  onChange={handleChange}
                  helperText={touched.name && errors.name}
                  error={touched.name && Boolean(errors.name)}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button color='secondary' variant='outlined' onClick={handleClose}>
                {formatMessage(commonMessages.cancelBtn)}
              </Button>
              <Button color='secondary' variant='contained' type='submit' disabled={isSubmitting}>
                {isNew
                  ? formatMessage(commonMessages.createBtn)
                  : formatMessage(commonMessages.updateBtn)}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Formik>
  )
}

export default ContactCategoryForm
