
import AddIcon from '@mui/icons-material/Add';
import {
    AccordionDetails,
    Button,
    Grid,
    useMediaQuery,
    useTheme,
} from '@mui/material';

import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useLoggedInUser } from '../../app/hooks/useLoggedInUser';
import { useGetCurrenciesByCustomerIdQuery, useGetLocationsQuery } from '../../app/redux-fetch/apiShipment';
import StyledAccordion from '../../components/Accordions/StyledAccordion';
import StyledAccordionSummary from '../../components/Accordions/StyledAccordionSummary';
import BoxWhiteContainer from '../../components/Containers/BoxWhiteContainer';
import InfoDisplayItem from '../../components/Typographies/InfoDisplayItem';
import { LabelItem } from '../../components/Typographies/LabelItem';
import PackagingItem from './PackagingItem';
import { useShipmentFormContext } from './context/ShipmentFormContext';
import messages from './messages';

interface PackagingFormProps {
    consignmentIndex: number;
}

const PackagingForm: React.FC<PackagingFormProps> = ({ consignmentIndex }) => {
    const { control, watch } = useFormContext();
    const { fields: packaging, append, remove } = useFieldArray({
        control,
        name: `consignments.${consignmentIndex}.packaging`,
    });

    const { formatMessage } = useIntl();

    const copyPackaging = (index: number) => {
        const packagingItem = packaging[index];
        append({ ...packagingItem });
    }

    const [expanded, setExpanded] = useState(true);
    const { currentPage } = useShipmentFormContext();

    const handleToggleAccordion = () => {
        setExpanded((prev) => !prev);
    };

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const customer = watch('customer');
    const referenceNumber = watch('referenceNumber');

    const customerId = watch('customerId')

    const { data: currencies = [] } = useGetCurrenciesByCustomerIdQuery({
        customerId: customerId ?? 0, hideDisabled: true,
    })

    const currencyId = watch(`consignments.${consignmentIndex}.currencyId`);

    const getCurrencyLabel = useCallback(() => {
        const name = currencies.find((currency) => currency.id === currencyId)?.name;
        return name;
    }, [currencies, currencyId]);

    const { tenantId } = useLoggedInUser()
    const { data: locations = [] } = useGetLocationsQuery({ tenantId, includeCustomerLocations: true })

    const consignmentReferenceNumber = watch(`consignments.${consignmentIndex}referenceNumber`);
    const consignmentTrackingNumber = watch(`consignments.${consignmentIndex}.trackingNumber`);

    const getLocationName = (locationId: number) => {
        return locations.find((location) => location.id === locationId)?.name;
    }

    const shipFrom = watch(`consignments.${consignmentIndex}.shipFrom.locationId`);
    const deliverTo = watch(`consignments.${consignmentIndex}.deliverTo.locationId`);

    const loadingDate = dayjs(watch(`consignments.${consignmentIndex}.shipFrom.loadingDate`)).format('DD/MM/YYYY');
    const deliveryDate = dayjs(watch(`consignments.${consignmentIndex}.deliverTo.deliveryDate`)).format('DD/MM/YYYY');

    const renderPackagingInfo = () => {
        return currentPage > 2 ?
            (<BoxWhiteContainer sx={{ mb: '32px' }}>
                <LabelItem>{formatMessage(messages.customerInformation)}</LabelItem>
                <InfoDisplayItem label={formatMessage(messages.customer)} value={customer ?? 'N/A'} isSmallScreen={false} />
                <InfoDisplayItem label={formatMessage(messages.customerReferenceNumber)} value={referenceNumber ?? 'N/A'} isSmallScreen={isSmallScreen} />
                <InfoDisplayItem label={formatMessage(messages.shipmentNumber)} value={'N/A'} isSmallScreen={isSmallScreen} />
            </BoxWhiteContainer>) : null
    }
    const renderPackagingItem = () => {
        return currentPage >= 2 ?
            (<>
                <BoxWhiteContainer sx={{ mb: '32px' }}>
                    <Grid container>
                        <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <LabelItem>{formatMessage(messages.consignmentInformation)}</LabelItem>
                        </Grid>
                        <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <InfoDisplayItem label={formatMessage(messages.consignmentPackagingContents)} value={consignmentReferenceNumber ?? 'N/A'} isSmallScreen={false} />
                        </Grid>
                        <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <InfoDisplayItem label={formatMessage(messages.consignmentTrackingNumber)} value={consignmentTrackingNumber ?? 'N/A'} isSmallScreen={false} />
                        </Grid>
                        <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <InfoDisplayItem label={formatMessage(messages.currency)} value={getCurrencyLabel() ?? 'N/A'} isSmallScreen={false} />
                        </Grid>
                    </Grid>
                </BoxWhiteContainer>
                <BoxWhiteContainer sx={{ mb: '32px' }}>
                    <Grid container>
                        <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <LabelItem>{formatMessage(messages.shipFromDeliverTo)}</LabelItem>
                        </Grid>
                        <Grid item xs={12} md={2} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <InfoDisplayItem label={formatMessage(messages.shipFrom)} value={getLocationName(shipFrom) ?? 'N/A'} isSmallScreen={false} />
                        </Grid>
                        <Grid item xs={12} md={2} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <InfoDisplayItem label={formatMessage(messages.deliverTo)} value={getLocationName(deliverTo) ?? 'N/A'} isSmallScreen={false} />
                        </Grid>
                        <Grid item xs={12} md={2} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <InfoDisplayItem label={formatMessage(messages.scheduledDate)} value={loadingDate ?? 'N/A'} isSmallScreen={false} />
                        </Grid>
                        <Grid item xs={12} md={2} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <InfoDisplayItem label={formatMessage(messages.deliveryDate)} value={deliveryDate ?? 'N/A'} isSmallScreen={false} />
                        </Grid>
                    </Grid>
                </BoxWhiteContainer>
                <StyledAccordion expanded={expanded} onChange={handleToggleAccordion} >
                    <StyledAccordionSummary index={`packaging-content-${consignmentIndex}`} title={formatMessage(messages.consignmentPackaging)} />
                    <AccordionDetails>
                        {packaging.map((item, index) => (
                            <PackagingItem
                                key={item.id}
                                consignmentIndex={consignmentIndex}
                                packagingIndex={index}
                                itemId={item.id}
                                remove={remove}
                                copy={copyPackaging}
                            />
                        ))}
                        <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={() =>
                                append({
                                    numberOfPackages: 1,
                                    temperatureControlled: false,
                                    products: [],
                                })
                            }
                            sx={{ marginTop: 2 }}
                        >
                            {formatMessage(messages.addPackaging)}
                        </Button>
                    </AccordionDetails>
                </StyledAccordion>
            </>) : null
    }
    return (
        <>
            {renderPackagingInfo()}
            {renderPackagingItem()}
        </>
    );
};

export default PackagingForm;
