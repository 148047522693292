import { type AxiosResponse } from 'axios'
import { assetsAuthorizedFetch } from '../../../../app/axios/omnialog'
import { IAssetCategory, IAssetClass, IAssetType } from './types'

export const fetchAllAssetCategoriesByTenantAndTransportModeId = async (
  tenantId: number,
  transportModeId: number
): Promise<AxiosResponse<any, any>> => {
  return await assetsAuthorizedFetch.get(
    `/tenant/${tenantId}/transport-mode/${transportModeId}/asset-category`
  )
}

export const fetchAllAssetCategoriesByTenantId = async (
  tenantId: number,
): Promise<AxiosResponse<any, any>> => {
  return await assetsAuthorizedFetch.get(
    `/tenant/${tenantId}/asset-category`
  )
}

export const fetchAssetClassesByCategory = async (
  tenantId: number,
  assetCategoryId: number
): Promise<AxiosResponse<any, any>> => {
  return await assetsAuthorizedFetch.get(
    `tenant/${tenantId}/asset-category/${assetCategoryId}/asset-class`
  )
}

export const fetchAssetTypesByCategory = async (
  tenantId: number,
  assetCategoryId: number
): Promise<AxiosResponse<any, any>> => {
  return await assetsAuthorizedFetch.get(
    `tenant/${tenantId}/asset-category/${assetCategoryId}/asset-type`
  )
}

export const fetchAssetType = async (tenantId: number): Promise<AxiosResponse<any, any>> => {
  return await assetsAuthorizedFetch.get(`tenant/${tenantId}/asset-type`)
}

export const updateAssetCategory = async (
  assetCategory: IAssetCategory,
  tenantId: number
): Promise<AxiosResponse<any, any>> => {
  return await assetsAuthorizedFetch.put(`/tenant/${tenantId}/asset-category`, assetCategory)
}

export const updateAssetClass = async (
  assetClasses: IAssetClass,
  tenantId: number
): Promise<AxiosResponse<any, any>> => {
  return await assetsAuthorizedFetch.put(`/tenant/${tenantId}/asset-class`, assetClasses)
}

export const updateAssetType = async (
  assetTypes: IAssetType,
  tenantId: number
): Promise<AxiosResponse<any, any>> => {
  return await assetsAuthorizedFetch.put(`/tenant/${tenantId}/asset-type`, assetTypes)
}

export const postAssetCategory = async (
  assetCategory: IAssetCategory,
  tenantId: number
): Promise<AxiosResponse<any, any>> => {
  return await assetsAuthorizedFetch.post(`/tenant/${tenantId}/asset-category`, assetCategory)
}

export const postAssetClass = async (
  assetClasse: IAssetClass,
  tenantId: number
): Promise<AxiosResponse<any, any>> => {
  return await assetsAuthorizedFetch.post(`/tenant/${tenantId}/asset-class`, assetClasse)
}

export const postAssetType = async (
  assetType: IAssetType,
  tenantId: number
): Promise<AxiosResponse<any, any>> => {
  return await assetsAuthorizedFetch.post(`/tenant/${tenantId}/asset-type`, assetType)
}
export const deleteAssetCategory = async (
  assetCategory: IAssetCategory,
  tenantId: number
): Promise<AxiosResponse<any, any>> => {
  const { id } = assetCategory
  return await assetsAuthorizedFetch.delete(`/tenant/${tenantId}/asset-category/${id}`)
}

export const deleteAssetClass = async (
  assetClass: IAssetClass,
  tenantId: number
): Promise<AxiosResponse<any, any>> => {
  const { id } = assetClass
  return await assetsAuthorizedFetch.delete(`/tenant/${tenantId}/asset-class/${id}`)
}

export const deleteAssetType = async (
  assetType: IAssetType,
  tenantId: number
): Promise<AxiosResponse<any, any>> => {
  const { id } = assetType
  return await assetsAuthorizedFetch.delete(`/tenant/${tenantId}/asset-type/${id}`)
}

