import { Alert, Snackbar } from '@mui/material'
import { ReactElement, useState } from 'react'
import { useLocation } from 'react-router-dom'

export interface ErrorProps {
  msg: string
}

const ErrorPage = (props: ErrorProps): ReactElement<any, any> => {
  const { msg } = props
  const [open, setOpen] = useState(!!msg)
  const search = useLocation().hash.replace('#', '?')
  let errorDescription

  if (msg && msg.indexOf('claim \'sub\' which is missing') > 0) {
    errorDescription = 'Account is missing'
  } else {
    errorDescription = new URLSearchParams(search).get('error_description')
  }
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert severity='error' sx={{ width: '100%' }}>
          {errorDescription}
        </Alert>
      </Snackbar>
    </>
  )
}

export default ErrorPage
