import isDate from 'lodash/isDate';

export const getMinDate = (date: Date | undefined) => {
    if(isDate(date)){
        const dt = new Date(date);
        dt.setDate(date.getDate() + 1);
        return dt;
    }

    return undefined;
}

export const getMaxDate = (date: Date | undefined) => {
    if(isDate(date)){
        const dt = new Date(date);
        dt.setDate(date.getDate() - 1);
        return dt;
    }

    return undefined;
}