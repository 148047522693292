import { ICommonAttributes, SubType } from '../../../../types/common'
import { IAsset } from './types'

export const getSubType = (tenantId: number | null) =>
  !tenantId || tenantId == 0 ? SubType.SystemDefined : SubType.UserDefined

export const compareAssets = (a: IAsset, b: IAsset) => a.name.localeCompare(b.name) === 0

export const compareAssetByCommonAttributes = (a: ICommonAttributes, b: ICommonAttributes) =>
  a.id === b.id && a.name === b.name && a.entity === b.entity

export const isPureUserDefined = (c: IAsset) =>
  c.enabled && !c.isCloneOfSysmtemDefined && c.subType === SubType.UserDefined

export const processAssets = (assets: IAsset[], entity: ICommonAttributes['entity']) => {
  const allAssets: IAsset[] = assets.map((c) => ({
    ...c,
    entity,
    dirty: false,
    subType: getSubType(c.tenantId),
    isCloneOfSysmtemDefined: false,
    canDelete: false,
  }))

  const duplicatesOfSystemDefined = allAssets
    .map((c) => c)
    .reduce((acc, curr, _, array) => {
      const found = array.filter((c) => compareAssets(c, curr))
      if (found && found.length > 1) {
        const systemDefined = found.find((c) => c.subType === SubType.SystemDefined)
        if (systemDefined) {
          const exists = acc.find((c) => compareAssets(c, systemDefined))
          if (exists) return acc
          acc.push(systemDefined)
          return acc
        }
      }
      return acc
    }, [] as IAsset[])

  // Find and mark clones of system defined categories
  allAssets.forEach((c) => {
    if (c.subType === SubType.SystemDefined) return
    const exists = duplicatesOfSystemDefined.find((d) => compareAssets(d, c))
    if (exists) {
      c.isCloneOfSysmtemDefined = true
      c.canDelete = false
    }
  })

  // Dedupe and sort by name
  const allAssetsDedupedSortedByName = allAssets
    .filter((c) => !duplicatesOfSystemDefined.includes(c))
    .sort((a, b) => (a.name > b.name ? 1 : -1))

  return allAssetsDedupedSortedByName
}
