import { combineReducers } from '@reduxjs/toolkit'
import {
  JOURNEYS_BY_TENANT_ID_REDUCER_NAME,
  TRANSPORTS_ASSETS_CONFIGURATION_REDUCER_NAME,
  TRANSPORTS_ASSETS_REDUCER_NAME,
  TRANSPORTS_EMPLOYEE_REDUCER_NAME,
  TRANSPORTS_LEG_BY_ID_REDUCER_NAME,
  TRANSPORTS_LOADS_REDUCER_NAME,
} from './consts'
import {
  fetchTransportAssetConfigurationReducers,
  fetchTransportsAssetsReducers,
  fetchTransportsEmployeeReducers,
  fetchTransportsLegByIdReducers,
  fetchTransportsLegReducers,
  fetchTransportsLoadsReducers,
} from './slice'

export const transportsReducers = combineReducers({
  [JOURNEYS_BY_TENANT_ID_REDUCER_NAME]: fetchTransportsLegReducers,
  [TRANSPORTS_LEG_BY_ID_REDUCER_NAME]: fetchTransportsLegByIdReducers,
  [TRANSPORTS_LOADS_REDUCER_NAME]: fetchTransportsLoadsReducers,
  [TRANSPORTS_EMPLOYEE_REDUCER_NAME]: fetchTransportsEmployeeReducers,
  [TRANSPORTS_ASSETS_CONFIGURATION_REDUCER_NAME]: fetchTransportAssetConfigurationReducers,
  [TRANSPORTS_ASSETS_REDUCER_NAME]: fetchTransportsAssetsReducers,
})
