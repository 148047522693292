import CancelIcon from '@mui/icons-material/CancelOutlined'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { MRT_Cell, MRT_ColumnDef } from 'material-react-table'
import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useLoggedInUser } from '../../../../app/hooks/useLoggedInUser'
import { useNotificationStack } from '../../../../app/hooks/useNotificationStack'
import {
  useDeleteContactTypeMutation,
  useGetContactTypesByCustomerIdQuery,
  useGetContactTypesByTenantIdQuery,
} from '../../../../app/redux-fetch/apiCoreQuery'
import AddButton from '../../../../components/Buttons/AddButton'
import commonMessages from '../../../../components/Messages/commonMessages'
import MRTDataGridv2 from '../../../../components/MRTDataGrid/MRTDataGridv2'
import { TableTitleTypography } from '../../../../components/Typographies/TableTitleTypography'
import YesNoTypography from '../../../../components/YesNoTypograpphy/YesNoTypography'
import { IContactType } from '../../../Contacts/types'
import messages from '../messages'
import ContactTypeForm from './ContactTypeForm'

const ContactTypeTab: React.FC = () => {
  const { formatMessage } = useIntl()
  const { tenantId, customerId } = useLoggedInUser()
  const [createOpen, setCreateOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)
  const [selectedContactId, setSelectedContactId] = useState<number | null>(null)
  const { enqueueSuccess, enqueueFailure } = useNotificationStack()
  const [deleteContactType] = useDeleteContactTypeMutation()

  const {
    data: contactTypes = [],
    isFetching: contactsLoading,
    isSuccess: contactsLoaded,
  } = customerId
      ? useGetContactTypesByCustomerIdQuery(customerId)
      : useGetContactTypesByTenantIdQuery(tenantId)

  const columns = useMemo<MRT_ColumnDef<IContactType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: formatMessage(commonMessages.name),
        Cell: ({ cell }: { cell: MRT_Cell<IContactType> }) => (
          <Typography>{cell.getValue<string>()}</Typography>
        ),
      },
      {
        header: formatMessage(messages.contactCategory),
        accessorFn: (row) => row.contactCategory?.name ?? '',
        id: 'contactCategoryName',
        Cell: ({ cell }: { cell: MRT_Cell<IContactType> }) => (
          <Typography>{cell.getValue<string>()}</Typography>
        ),
      },
      {
        accessorKey: 'inUse',
        header: formatMessage(messages.inUse),
        filterVariant: 'checkbox',
        muiTableHeadCellProps: { align: 'center' },
        muiTableBodyCellProps: { align: 'center' },
        Cell: ({ cell }: { cell: MRT_Cell<IContactType> }) => (
          <YesNoTypography isYes={cell.getValue<boolean>()} />
        ),
      },
    ],
    [formatMessage]
  )

  const handleDeleteContactType = async (id: number) => {
    try {
      await deleteContactType(id).unwrap()
      enqueueSuccess(messages.contactTypeDeleted)
    } catch (error) {
      enqueueFailure(messages.contactTypeNotDeleted)
    }
  }

  const selectedContactType = contactTypes.find((c) => c.id === selectedContactId) ?? {
    tenantId: +tenantId,
    customerId: customerId,
    id: 0,
    name: '',
    contactCategoryId: 0,
  }

  return (
    <>
      {createOpen && (
        <ContactTypeForm
          contactType={{
            tenantId: +tenantId,
            customerId: customerId,
            id: 0,
            name: '',
            contactCategoryId: 0,
          }}
          handleClose={() => setCreateOpen(false)}
          isOpen={createOpen}
        />
      )}
      {editOpen && (
        <ContactTypeForm
          contactType={selectedContactType}
          handleClose={() => setEditOpen(false)}
          isOpen={editOpen}
        />
      )}
      <MRTDataGridv2
        initialState={{
          pagination: { pageSize: 100, pageIndex: 0 },
          density: 'comfortable',
          sorting: [{ id: 'name', desc: false }],
        }}
        leftHeadingComponent={
          <Box sx={{ display: 'inline-flex' }}>
            <TableTitleTypography>{formatMessage(messages.contacts)}</TableTitleTypography>
          </Box>
        }
        rightHeadingComponent={
          <AddButton
            text={formatMessage(messages.newContactBtn)}
            onClick={() => setCreateOpen(true)}
          />
        }
        state={{
          isLoading: contactsLoading || !contactsLoaded,
        }}
        data={contactTypes}
        columns={columns}
        getRowId={(row) => row.id.toString()}
        enableEditing
        enableRowActions
        positionActionsColumn='last'
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Link
              underline='none'
              color='secondary'
              onClick={() => {
                setSelectedContactId(row.original.id)
                setEditOpen(true)
              }}
              sx={{ fontWeight: 700, cursor: 'pointer' }}
            >
              {formatMessage(messages.editBtn)}
            </Link>
            {!row.original.inUse && (
              <CancelIcon
                color='secondary'
                sx={{ cursor: 'pointer', pl: 1 }}
                onClick={() => handleDeleteContactType(row.original.id)}
              />
            )}
          </Box>
        )}
      />
    </>
  )
}

export default ContactTypeTab
