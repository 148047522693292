import { useProductTabContext } from '../ProductTabContext'
import ProductCategoryForm from './ProductCategoryForm'

const CreateProductCategoryModal = () => {
  const { customerId } = useProductTabContext()

  const newProductCategory = {
    hsLocalTariffs: [],
    customerId,
  }

  return <ProductCategoryForm productCategory={newProductCategory} isDisabled={false} />
}

export default CreateProductCategoryModal
