import { Box, Grid, Tabs } from '@mui/material'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import TabPanel from '../../../components/TabPanel/TabPanel'
import StyledTab from '../../../components/Tabs/StyledTab'
import ContactCategoryTab from './ContactCategory/ContactCategoryTab'
import ContactTypeTab from './ContactType/ContactTypeTab'
import messages from './messages'

const ContactTabs = () => {
  const { formatMessage } = useIntl()
  const [activeTab, setActiveTab] = useState(0)

  const handleChange = (_event: React.SyntheticEvent, tabId: number) => {
    setActiveTab(tabId)
  }

  const tabs = [
    {
      label: formatMessage(messages.contacts),
      component: (
        <Grid item xs={12}>
          <ContactTypeTab />
        </Grid>
      ),
    },
    {
      label: formatMessage(messages.contactCategories),
      component: (
        <Grid item xs={12}>
          <ContactCategoryTab />
        </Grid>
      ),
    },
  ]

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Tabs
          sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
          value={activeTab}
          onChange={handleChange}
          centered
          variant='standard'
          scrollButtons={false}
          aria-label='Contacts settings scrollable tabs'
        >
          {tabs.map((tab, index) => (
            <StyledTab key={index} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={activeTab} index={index}>
          {tab.component}
        </TabPanel>
      ))}
    </>
  )
}

export default ContactTabs
