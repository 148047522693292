import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useLoggedInUser } from '../../app/hooks/useLoggedInUser';
import { useGetCustomersQuery } from '../../app/redux-fetch/apiQuery';
import { useGetLocationsQuery } from '../../app/redux-fetch/apiShipment';
import { convertToLabelValue } from '../../app/utils';
import BoxWhiteContainer from '../../components/Containers/BoxWhiteContainer';
import FormInput from '../../components/Forms/FormInput';
import FormSelect from '../../components/Forms/FormSelect';
import commonMessages from '../../components/Messages/commonMessages';
import InfoDisplayItem from '../../components/Typographies/InfoDisplayItem';
import { LabelItem } from '../../components/Typographies/LabelItem';
import { useShipmentFormContext } from './context/ShipmentFormContext';
import messages from './messages';

interface ShipmentInformationProps {
    isEdit: boolean;
}

const ShipmentInformation: React.FC<ShipmentInformationProps> = ({ isEdit }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { watch } = useFormContext();

    const { currentPage, totalPages } = useShipmentFormContext()
    const { formatMessage } = useIntl();

    const customer = watch('customerId');


    const customerReferenceNumber = watch('customerReferenceNumber');

    const { tenantId, isCustomerUser, customerId } = useLoggedInUser()

    const { data: customers = [], isSuccess: isCustomersLoaded } = useGetCustomersQuery(tenantId)
    const { data: locations = [], isSuccess: isLocationsLoaded } = useGetLocationsQuery({ tenantId, includeCustomerLocations: true })

    const shipmentNumber = watch('shipmentNumber');


    const getCustomerName = (customerId: number) => {
        return customers.find((customer) => customer.id === customerId)?.name;
    }

    if (currentPage !== 1) {
        return (
            <BoxWhiteContainer sx={{ mb: '32px' }}>
                <Grid container>
                    <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <LabelItem>{formatMessage(messages.customerInformation)}</LabelItem>
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <InfoDisplayItem label={formatMessage(messages.customer)} value={getCustomerName(customer) || 'N/A'} isSmallScreen={isSmallScreen} />
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <InfoDisplayItem label={formatMessage(messages.customerReferenceNumber)} value={customerReferenceNumber || 'N/A'} isSmallScreen={isSmallScreen} />
                    </Grid>
                    <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <InfoDisplayItem label={formatMessage(messages.shipmentNumber)} value={shipmentNumber || 'N/A'} isSmallScreen={isSmallScreen} />
                    </Grid>
                </Grid>
            </BoxWhiteContainer>
        );
    } else {
        return (
            <Box display="flex" flexWrap="wrap" gap={2} sx={{ marginBottom: 2 }}>
                <Box
                    flex={isSmallScreen ? '1 1 100%' : '1 1 0'}
                    minWidth={isSmallScreen ? '100%' : '200px'}
                >
                    <FormSelect
                        name="customerId"
                        label={formatMessage(messages.customer)}
                        options={convertToLabelValue(customers)}
                        disabled={isCustomerUser}
                        fullWidth
                    />
                </Box>
                <Box
                    flex={isSmallScreen ? '1 1 100%' : '1 1 0'}
                    minWidth={isSmallScreen ? '100%' : '200px'}
                >
                    <FormInput
                        name="description"
                        label={formatMessage(messages.description)}
                        placeholder={formatMessage(commonMessages.pleaseEnter)}
                        fullWidth
                    />
                </Box>
                <Box
                    flex={isSmallScreen ? '1 1 100%' : '1 1 0'}
                    minWidth={isSmallScreen ? '100%' : '200px'}
                >
                    <FormInput
                        name="customerReferenceNumber"
                        label={formatMessage(messages.customerReferenceNumber)}
                        placeholder={formatMessage(commonMessages.pleaseEnter)}
                        fullWidth
                    />
                </Box>
                <Box
                    flex={isSmallScreen ? '1 1 100%' : '1 1 0'}
                    minWidth={isSmallScreen ? '100%' : '200px'}
                >
                    <FormInput
                        name="shipmentNumber"
                        label={formatMessage(messages.shipmentNumber)}
                        placeholder={formatMessage(messages.autoGenerated)}
                        fullWidth
                        disabled
                        helperText={!isEdit ? formatMessage(messages.autoGenerated) : ''}
                    />
                </Box>
                <Box
                    flex={isSmallScreen ? '1 1 100%' : '1 1 0'}
                    minWidth={isSmallScreen ? '100%' : '200px'}
                >
                    <FormSelect
                        name="shipperId"
                        label={formatMessage(messages.shipper)}
                        options={convertToLabelValue(locations)}
                        fullWidth
                    />
                </Box>
            </Box>
        );
    }
};

export default ShipmentInformation;
