import React, { createContext, useContext, useMemo } from 'react';

interface ShipmentFormContextProps {
  currentPage: number;
  totalPages: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const ShipmentFormContext = createContext<ShipmentFormContextProps | null>(null);

export const useShipmentFormContext = () => {
  const context = useContext(ShipmentFormContext);
  if (!context) {
    throw new Error('useShipmentFormContext must be used within ShipmentFormProvider');
  }
  return context;
};

export const ShipmentFormProvider: React.FC<{
  currentPage: number;
  totalPages: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  children: React.ReactNode;
}> = ({ currentPage, totalPages, setCurrentPage, children }) => {
  const value = useMemo(() => ({
    currentPage,
    totalPages,
    setCurrentPage,
  }), [currentPage, totalPages, setCurrentPage]);

  return (
    <ShipmentFormContext.Provider value={value}>
      {children}
    </ShipmentFormContext.Provider>
  );
};
