/* eslint-disable */
import { defineMessages } from 'react-intl'

const messages = defineMessages({
  dashboard: {
    id: 'menu.dashboard',
    description: 'Dashboard',
    defaultMessage: 'Dashboard',
  },
  settings: {
    id: 'menu.settings',
    description: 'Settings',
    defaultMessage: 'Settings',
  },
  shipments: {
    id: 'menu.shipments',
    description: 'Shipments',
    defaultMessage: 'Shipments',
  },
  locations: {
    id: 'menu.locations',
    description: 'Locations',
    defaultMessage: 'Locations',
  },
  journeys: {
    id: 'menu.journeys',
    description: 'Journeys side menu name',
    defaultMessage: 'Journeys',
  },
  customers: {
    id: 'menu.customer',
    description: 'Customer',
    defaultMessage: 'Customers',
  },
  invalidTenantError: {
    id: 'loginErrors.invalidTenantError',
    description: 'Error message indicates invalid tenant of the user',
    defaultMessage: 'Login Failed: No user account found!',
  },
  generalLoginError: {
    id: 'loginErrors.generalLoginError',
    description: 'Error message indicates unexpected error during sign in',
    defaultMessage: 'Login Failed!',
  },
  assetsManagement: {
    id: 'menu.assetsManagement',
    description: 'Assets Management',
    defaultMessage: 'Assets Management',
  },
})

export default messages
