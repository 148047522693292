import { createTheme, type Theme } from '@mui/material/styles'
import { createContext } from 'react'
import logger from '../../app/middleware/log'

export const defaultThemeSource = (): object => ({
  palette: {
    primary: {
      contrastText: '#FFFFFF',
      main: '#203a7c',
    },
    secondary: {
      main: '#00a0e4',
    },
    text: {
      primary: '#000000',
    },
  },
})

export const defaultTheme = (): Theme => createTheme(defaultThemeSource())

export class ThemeContextState {
  theme: Theme = defaultTheme()
  setThemeContextState = (themeContextState: ThemeContextState): void => {
    logger.log('not initialized')
  }

  createAndSetTheme(themeSource: object) {
    const newTheme = createTheme(themeSource)
    this.setTheme(newTheme)
    return newTheme
  }

  setTheme(theme: Theme): void {
    theme.typography.fontFamily = 'Roboto, sans-serif'
    this.theme = theme
    const newThemeContextState = createThemeContextState(this.theme, this.setThemeContextState)
    this.setThemeContextState(newThemeContextState)
  }
}

export const createThemeContextState = (
  theme: Theme | null = null,
  setThemeContextState: ((themeContextState: ThemeContextState) => void) | null = null
): ThemeContextState => {
  const newThemeContextState = new ThemeContextState()
  if (theme !== null) {
    theme.typography.fontFamily = 'Roboto, sans-serif'
    newThemeContextState.theme = theme
  }
  if (setThemeContextState !== null) {
    newThemeContextState.setThemeContextState = setThemeContextState
  }
  return newThemeContextState
}

export const ThemeContext = createContext<ThemeContextState>(createThemeContextState())
