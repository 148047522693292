import { defineMessages } from 'react-intl'

const messages = defineMessages({
  userConnectTo365Button: {
    id: 'tenantSettings.office365.userConnectTo365Button',
    description: 'User connect to 365 button text',
    defaultMessage: 'Connect to 365',
  },
  userSyncroniseButton: {
    id: 'tenantSettings.office365.userSyncroniseButton',
    description: 'User Synchronize button text',
    defaultMessage: 'Synchronize',
  },
  userConsentLinkAlreadyIssued: {
    id: 'tenantSettings.office365.userConsentLinkAlreadyIssued',
    description: 'User consent link already issued text',
    defaultMessage: 'A consent link has already been issued.',
  },
  userConsentLink: {
    id: 'tenantSettings.office365.userConsentLink',
    description: 'Request new consent hyperlink link text',
    defaultMessage: 'Request new consent hyperlink',
  },
  userSyncroniseSuccess: {
    id: 'tenantSettings.office365.userSyncroniseSuccess',
    description: 'User sync successfull message',
    defaultMessage: 'User sync is in progress',
  },
  userSyncroniseFailure: {
    id: 'tenantSettings.office365.userSyncroniseFailure',
    description: 'User sync failure message',
    defaultMessage: 'User sync failed',
  },
  userFetchOnBoardingLinkSuccess: {
    id: 'tenantSettings.office365.userFetchOnBoardingLinkSuccess',
    description: 'User fetch onboaring link success message',
    defaultMessage: 'Successfully fetched onboarding link',
  },
  userFetchOnBoardingLinkFailure: {
    id: 'tenantSettings.office365.userFetchOnBoardingLinkFailure',
    description: 'User fetch onboaring link failure message',
    defaultMessage: 'Failed to fetch onboarding link',
  },
  userFetchIdentityProviderIntegrationInfoSuccess: {
    id: 'tenantSettings.office365.userFetchIdentityProviderIntegrationInfoSuccess',
    description: 'User fetch identity provider integration info success message',
    defaultMessage: 'Successfully fetched identity provider information',
  },
  userFetchIdentityProviderIntegrationInfoFailure: {
    id: 'tenantSettings.office365.userFetchIdentityProviderIntegrationInfoFailure',
    description: 'User fetch identity provider integration info failure message',
    defaultMessage: 'Failed to fetch identity provider information',
  },
})

export default messages
