import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { IDropDownItem } from '../../app/types'

interface IFormAssignDropDownProps {
  id: string
  label?: string
  items: IDropDownItem[]
  onChange: (e: any, id: string) => void
}
const FormAssignDropDown = (props: IFormAssignDropDownProps) => {
  const { id, label, items, onChange } = props
  return (
    <>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          id={id}
          label={label}
          value={''}
          onChange={(e: any) => {
            e.preventDefault()
            e.stopPropagation()
            onChange(e, id)
            e.target.value = ''
          }}
          onBlur={(e: any) => {
            e.preventDefault()
            e.stopPropagation()
            e.target.value = ''
          }}
        >
          {items.map((item) => (
            <MenuItem key={item?.id ?? -1} value={item?.id}>
              {item?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default FormAssignDropDown
