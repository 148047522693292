import logger from '../../app/middleware/log'
import { fetchAssetsDataThunk, fetchBrandingDataThunk, makeFetchSlice } from '../../app/redux-fetch'
import { AppDispatch } from '../../app/store/store'
import {
  fetchAllAssetCategoriesByTenantAndTransportModeId,
  fetchAssetClassesByCategory,
  fetchAssetType,
  fetchAssetTypesByCategory,
} from './Components/AssetSettings/api'
import { getBranding } from './api'
import {
  ASSETS_ALL_ASSET_CATEGORIES_BY_TRANSPORT_MODE_REDUCER_NAME,
  ASSETS_ALL_ASSET_CLASSES_BY_CATEGORY_REDUCER_NAME,
  ASSETS_ALL_ASSET_TYPES_BY_CATEGORY_REDUCER_NAME,
  ASSETS_ALL_ASSET_TYPES_REDUCER_NAME,
  TENANT_ALL_BRANDING_REDUCER_NAME,
} from './consts'

const fetchAllBrandingSlice = makeFetchSlice(TENANT_ALL_BRANDING_REDUCER_NAME)

export const fetchAllTenantReducers = fetchAllBrandingSlice.reducer
export const fetchAllTenantActions = fetchAllBrandingSlice.actions

export const fetchBrandingThunk = (tenantId: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchBrandingDataThunk({
        dispatch,
        actions: fetchAllTenantActions,
        asyncFunc: () => getBranding(tenantId),
      })
    } catch (error) {
      logger.error('🚀 Error fetching branding data', error)
    }
  }
}

const fetchAllAssetCategoriesByTransportModeSilce = makeFetchSlice(
  ASSETS_ALL_ASSET_CATEGORIES_BY_TRANSPORT_MODE_REDUCER_NAME
)

export const fetchAllAssetCategoriesByTransportModeReducers =
  fetchAllAssetCategoriesByTransportModeSilce.reducer
export const fetchAllAssetCategoriesByTransportModeActions =
  fetchAllAssetCategoriesByTransportModeSilce.actions

export const fetchAllAssetCategoriesByTransportModeThunk = (
  tenantId: number,
  transportModeId: number
) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchAssetsDataThunk({
        dispatch,
        actions: fetchAllAssetCategoriesByTransportModeActions,
        asyncFunc: () =>
          fetchAllAssetCategoriesByTenantAndTransportModeId(tenantId, transportModeId),
      })
    } catch (error) {
      logger.error('Error fetching asset categories by transport mode', error)
    }
  }
}

const fetchAssetClassesByCategorySlice = makeFetchSlice(
  ASSETS_ALL_ASSET_CLASSES_BY_CATEGORY_REDUCER_NAME
)

export const fetchAssetClassesByCategoryReducers = fetchAssetClassesByCategorySlice.reducer
export const fetchAssetClassesByCategoryActions = fetchAssetClassesByCategorySlice.actions

export const fetchAssetClassesByCategoryThunk = (tenantId: number, assetCategoryId: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchAssetsDataThunk({
        dispatch,
        actions: fetchAssetClassesByCategoryActions,
        asyncFunc: () => {
          if (assetCategoryId === -1) return Promise.resolve([])
          return fetchAssetClassesByCategory(tenantId, assetCategoryId)
        },
      })
    } catch (error) {
      logger.error('Error fetching asset classes by category', error)
    }
  }
}

const fetchAssetTypesByCategorySlice = makeFetchSlice(
  ASSETS_ALL_ASSET_TYPES_BY_CATEGORY_REDUCER_NAME
)

export const fetchAssetTypesByCategoryReducer = fetchAssetTypesByCategorySlice.reducer
export const fetchAssetTypesByCategoryActions = fetchAssetTypesByCategorySlice.actions

export const fetchAssetTypesByCategoryThunk = (tenantId: number, assetCategoryId: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchAssetsDataThunk({
        dispatch,
        actions: fetchAssetTypesByCategoryActions,
        asyncFunc: () => {
          if (assetCategoryId === -1) return Promise.resolve([])
          return fetchAssetTypesByCategory(tenantId, assetCategoryId)
        },
      })
    } catch (error) {
      logger.error('Error fetching asset types by category', error)
    }
  }
}

const fetchAssetTypeSlice = makeFetchSlice(ASSETS_ALL_ASSET_TYPES_REDUCER_NAME)

export const fetchAssetTypeReducer = fetchAssetTypeSlice.reducer
export const fetchAssetTypeActions = fetchAssetTypeSlice.actions

export const fetchAssetTypeThunk = (tenantId: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      await fetchAssetsDataThunk({
        dispatch,
        actions: fetchAssetTypeActions,
        asyncFunc: () => {
          return fetchAssetType(tenantId)
        },
      })
    } catch (error) {
      logger.error('Error fetching asset types', error)
    }
  }
}
