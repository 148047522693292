import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionSummary, Box } from '@mui/material';
import React from 'react';
import { AccordionHeader } from '../Headers/styles';

interface StyledAccordionSummaryProps {
    index: string;
    title?: string;
    children?: React.ReactNode;
    expanded?: boolean;
}

const StyledAccordionSummary: React.FC<StyledAccordionSummaryProps> = ({ index, title, children, expanded }) => (
    <AccordionSummary
        aria-controls={index}
        id={index}
        sx={{
            flexDirection: 'row-reverse',
        }}
    >
        <Box display='flex' alignItems='center' width='100%'>
            <Box
                component={ExpandMoreIcon}
                sx={{
                    color: '#636363',
                    transition: 'transform 0.3s',
                    transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)', // Rotate based on expanded state
                }}
            />
            {children ? children : <AccordionHeader>{title}</AccordionHeader>}
        </Box>

    </AccordionSummary >
);

export default StyledAccordionSummary;