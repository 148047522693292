import { defineMessages } from 'react-intl';

const messages = defineMessages({
  tenants: {
    id: 'userManagement.CustomerTenantSelect.tenants',
    description: 'Label for tenants',
    defaultMessage: 'Tenants'
  },
  customers: {
    id: 'userManagement.CustomerTenantSelect.customers',
    description: 'Label for customers',
    defaultMessage: 'Customers'
  }
});

export default messages;