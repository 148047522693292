import { Link } from 'react-router-dom'

export const NotImplemented = () => {
  return (
    <div>
      <h2>Coming Soon!</h2>
      <p>
        <Link to='/'>Go to the dashboard</Link>
      </p>
    </div>
  )
}

export default NotImplemented
