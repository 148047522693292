import { Box, Typography } from '@mui/material';

interface InfoDisplayItemProps {
    label: string;
    value: string | number;
    isSmallScreen: boolean;
}

const InfoDisplayItem: React.FC<InfoDisplayItemProps> = ({ label, value, isSmallScreen }: InfoDisplayItemProps) => {
    return (
        <Box
            display="flex"
            alignItems="center" // Ensures vertical alignment
            minWidth={isSmallScreen ? '100%' : '200px'}
        >
            <Typography variant="subtitle1" sx={{ fontWeight: 700, color: '#636363', textAlign: 'left', whiteSpace: 'nowrap' }}>
                {label}:{' '}
            </Typography>
            <Typography component="span" variant="subtitle1" color="textSecondary" sx={{ marginLeft: '4px', whiteSpace: 'nowrap' }}>
                {value}
            </Typography>
        </Box>
    );
};

export default InfoDisplayItem;
