import { Box, Grid, Tab, Tabs, ThemeProvider, createTheme, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { useGetCustomersQuery } from '../../app/redux-fetch/apiQuery'
import SpinnerBlock from '../../components/Spinner/SpinnerBlock'
import TabPanel from '../../components/TabPanel/TabPanel'
import { fetchAllCountriesThunk, fetchCurrenciesByCustomerIdThunk } from '../Shipments/slice'
import { fetchLoggedInUserSelector } from '../selectors'
import { ILoggedInUser } from '../types'
import ProductCategoryTab from './ProductCategoryTab/ProductCategoryTab'
import ProductTab from './ProductTab/ProductTab'
import { ProductTabContext } from './ProductTabContext'

const ProductTabs = () => {
  const [activeTab, setActiveTab] = useState(0)
  const handleChange = (_event: React.SyntheticEvent, tabId: number) => {
    setActiveTab(tabId)
  }
  const dispatch = useAppDispatch()
  const loggedInUser = useAppSelector(fetchLoggedInUserSelector.data) || ({} as ILoggedInUser)
  const [customerId, setCustomerId] = useState<number>(loggedInUser.customerId ?? -1)
  const { data, isSuccess: isCustomerLoaded } = useGetCustomersQuery(loggedInUser.tenantId ?? -1)

  useEffect(() => {
    dispatch(fetchAllCountriesThunk())
    dispatch(fetchCurrenciesByCustomerIdThunk(customerId, true))
  }, [dispatch, loggedInUser.customerId, loggedInUser.tenantId, customerId])

  let theme = useTheme()

  theme = createTheme(theme, {
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: theme.palette.secondary.main,
              color: 'white',
              borderRadius: '5px',
            },
          },
        },
      },
    },
  })

  if (!isCustomerLoaded) return <SpinnerBlock />

  return (
    <>
      <ProductTabContext.Provider value={{ customerId, setCustomerId }}>
        <Box sx={{ width: '100%' }}>
          <ThemeProvider theme={theme}>
            <Tabs
              sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
              value={activeTab}
              onChange={handleChange}
              centered
              variant='standard'
              scrollButtons={false}
              aria-label='Tenant settings scrollable tabs'
            >
              <Tab label='Products' />
              <Tab label='Product Categories' />
            </Tabs>
          </ThemeProvider>
        </Box>
        <TabPanel value={activeTab} index={0}>
          <Grid item xs={12}>
            <ProductTab />
          </Grid>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Grid item xs={12}>
            <ProductCategoryTab />
          </Grid>
        </TabPanel>
      </ProductTabContext.Provider>
    </>
  )
}

export default ProductTabs
