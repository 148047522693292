import { defineMessages } from 'react-intl'

export const messages = defineMessages({
  journeysTableTitle: {
    id: 'journey.transportTableHeading',
    description: 'All journey table',
    defaultMessage: 'Journeys',
  },
  routeLoad: {
    id: 'transport.routeLoad',
    description: 'Route Loads table title',
    defaultMessage: 'Route Loads',
  },
  shipmentUnscheduled: {
    id: 'createJourney.shipmentUnscheduled',
    defaultMessage: 'Accepted Shipments - Unscheduled',
    description: 'The header title when there are no scheduled shipments in the journey form.',
  },
  createNewJourney: {
    id: 'createJourney.createNewJourney',
    defaultMessage: 'Create New Journey',
    description: 'The header title when creating a new journey with selected loads.',
  },
  createJourney: {
    id: 'createJourney.createJourney',
    defaultMessage: 'Create Journey',
    description: 'Button text to create a new journey.',
  },
  addTransportLeg: {
    id: 'createJourney.addTransportLeg',
    defaultMessage: 'Add Leg',
    description: 'Button text to add a leg to the journey.',
  },
  createMultiModalJourney: {
    id: 'createJourney.createMultiModalJourney',
    defaultMessage: 'Create Multi-Modal Journey',
    description: 'Button text to create a new multi-modal journey.',
  },
  selectedLoads: {
    id: 'createJourney.selectedLoads',
    defaultMessage: 'Selected Loads',
    description: 'The title for the section displaying selected loads in the journey form.',
  },
  leg2OriginLabel: {
    id: 'createJourney.leg2OriginLabel',
    defaultMessage: 'Leg #2 Origin',
    description:
      'Label for the dropdown to select the origin for the second leg of a multi-modal journey.',
  },
  leg2DestinationLabel: {
    id: 'createJourney.leg2DestinationLabel',
    defaultMessage: 'Leg #2 Destination',
    description:
      'Label for the dropdown to select the destination for the second leg of a multi-modal journey.',
  },
  approveForTransport: {
    id: 'createJourney.approveForTransport',
    defaultMessage: 'Approve for Transport',
    description: 'Button text to approve the journey for transport.',
  },
  saveDraft: {
    id: 'createJourney.saveDraft',
    defaultMessage: 'Save Draft',
    description: 'Button text to save the journey as a draft.',
  },
  noSelectedLoadsWarning: {
    id: 'createJourney.noSelectedLoadsWarning',
    defaultMessage:
      'You have not selected any loads. Are you sure you wish to create a Journey without loads?',
    description: 'Warning message when attempting to create a journey without selecting any loads.',
  },
  cannotCombineLoadsAndAssets: {
    id: 'createJourney.cannotCombineLoadsAndAssets',
    defaultMessage: 'You cannot combine shipment loads and asset requests in the same journey',
    description:
      'Error message when attempting to combine shipment loads and asset requests in the same journey.',
  },
  journeySubmitted: {
    id: 'createJourney.journeySubmitted',
    defaultMessage: 'Journey has been submitted',
    description: 'Success message when the journey has been successfully submitted.',
  },
  journeyNotSubmitted: {
    id: 'createJourney.journeyNotSubmitted',
    defaultMessage: 'Journey has not been submitted',
    description: 'Error message when the journey submission failed.',
  },
  journeyNotSaved: {
    id: 'createJourney.journeyNotSaved',
    defaultMessage: 'Journey has not been saved',
    description: 'Error message when the journey saving failed.',
  },
  journeyNotApproved: {
    id: 'createJourney.journeyNotApproved',
    defaultMessage: 'Journey has not been approved',
    description: 'Error message when the journey approving failed.',
  },
  journeySavedSuccessfully: {
    id: 'createJourney.journeySavedSuccessfully',
    defaultMessage: 'Journey saved successfully',
    description: 'Success message when the journey has been successfully saved.',
  },
  journeyApprovedSuccessfully: {
    id: 'createJourney.journeyApprovedSuccessfully',
    defaultMessage: 'Journey approved successfully',
    description: 'Success message when the journey has been successfully approved.',
  },
  loadPickupDeliveryValidation: {
    id: 'createJourney.loadPickupDeliveryValidation',
    defaultMessage:
      // eslint-disable-next-line quotes
      "The journey's first pickup must match the load's specified pickup location, and the journey's final drop-off must match the load's designated consignee destination.",
    description:
      'Validation message for ensuring every load has correct pickup and delivery locations in the journey.',
  },
  loadPickupDeliveryLegsValidation: {
    id: 'createJourney.loadPickupDeliveryLegsValidation',
    defaultMessage:
      // eslint-disable-next-line quotes
      "A load's pickup destination in the current Leg must match its last drop-off location from the previous Leg.",
    description:
      'Validation message for ensuring every load has correct pickup and delivery locations in the journey beside Legs.',
  },
  invalidRouteLocationCount: {
    id: 'createJourney.invalidRouteLocationCount',
    defaultMessage: 'Route #{wrongRouteIdx} must have a start location and an end location.',
    description: 'Error message when a route has an invalid number of locations.',
  },
  loadPickupDropoffValidation: {
    id: 'createJourney.loadPickupDropoffValidation',
    defaultMessage:
      ' Each load within a route must have both its pickup and drop-off points in the same route.',
    description:
      'Validation Failed: Every load assigned to a route should have its pickup and drop-off locations clearly defined within that same route. Please review the journey plan and ensure all load transfers align with this requirement.',
  },
})
