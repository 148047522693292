import { combineReducers } from '@reduxjs/toolkit'
import {
  TENANT_ALL_IDENTITY_PROVIDER_ID_REDUCER_NAME,
  TENANT_ALL_ON_BOARDING_REDUCER_NAME,
} from '../CommonSettings/Office365/consts'
import {
  fetchIdentityProviderIntegrationIdReducers,
  fetchOffice365OnBoardingReducers,
} from '../CommonSettings/Office365/slice'
import { TENANT_ALL_USERS } from '../const'
import { fetchAllTenantUsersReducers } from '../slice'
import { TENANT_ALL_BRANDING_REDUCER_NAME } from './consts'
import { fetchAllTenantReducers } from './slice'

export const tenantReducers = combineReducers({
  [TENANT_ALL_BRANDING_REDUCER_NAME]: fetchAllTenantReducers,
  [TENANT_ALL_ON_BOARDING_REDUCER_NAME]: fetchOffice365OnBoardingReducers,
  [TENANT_ALL_IDENTITY_PROVIDER_ID_REDUCER_NAME]: fetchIdentityProviderIntegrationIdReducers,
  [TENANT_ALL_USERS]: fetchAllTenantUsersReducers
})
