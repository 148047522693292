import { Box } from '@mui/material'
import {
  DrawingManagerF,
  GoogleMap,
  MarkerF,
  PolygonF,
  useJsApiLoader,
} from '@react-google-maps/api'
import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import SpinnerBlock from '../Spinner/SpinnerBlock'
import { polygonOptions } from './const'
import messages from './messages'
import { Wrapper } from './styles'
import { PolygonMapProps } from './types'

const PolygonMap = ({
  polygons = [],
  defaultCenter,
  marker,
  onChangePolygons,
  onChangeMarker,
  handleGeocode,
  googleMapsApiKey,
  height = 600,
  width = '100%',
  drawingControl = true,
  draggable = true,
  editable = true,
}: PolygonMapProps) => {
  const { formatMessage } = useIntl()
  const [center, setCenter] = useState(defaultCenter)
  const mapRef = useRef<google.maps.Map>()
  const polygonRef = useRef<any>([])
  const drawingManagerRef = useRef<any>()
  const { isLoaded } = useJsApiLoader({ googleMapsApiKey, libraries: ['drawing', 'places'] })
  const drawingManagerOptions = {
    polygonOptions,
    drawingControl: drawingControl && Boolean(polygons.length === 0),
    drawingControlOptions: {
      position: window.google?.maps?.ControlPosition?.TOP_RIGHT,
      drawingModes: [window.google?.maps?.drawing?.OverlayType?.POLYGON],
    },
  }
  const [geoCoder, setGeocoder] = useState<google.maps.Geocoder>()

  const onLoadMap = (map: google.maps.Map) => {
    mapRef.current = map
    addSearchBox(map)
  }

  const onLoadPolygon = (polygon: google.maps.Polygon) => {
    polygonRef.current = polygon
    centerMap(polygon)
  }

  const centerMap = (polygon: google.maps.Polygon) => {
    if (mapRef.current) {
      google.maps.event.addListenerOnce(mapRef.current, 'idle', () => {
        const bounds = new google.maps.LatLngBounds()
        polygon.getPath().forEach((e) => bounds.extend(e))
        mapRef.current?.fitBounds(bounds)
      })
    }
  }

  const addSearchBox = (map: google.maps.Map) => {
    if (!editable) {
      return
    }

    const input = document.getElementById('searchbox') as HTMLInputElement
    const searchBox = new google.maps.places.SearchBox(input)
    map.controls[google.maps.ControlPosition.TOP_LEFT].insertAt(0, input)

    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces()

      if (!places?.length) {
        return
      }

      const bounds = new google.maps.LatLngBounds()

      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          return
        }

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport)
        } else {
          bounds.extend(place.geometry.location)
        }
      })

      map.fitBounds(bounds)
    })

    input.style.display = ''
  }

  useEffect(() => {
    if (isLoaded) {
      const geoCoder = new google.maps.Geocoder()
      setGeocoder(geoCoder)
    }
  }, [isLoaded])

  const onLoadDrawingManager = (drawingManager: google.maps.drawing.DrawingManager) => {
    drawingManagerRef.current = drawingManager
  }

  const onOverlayComplete = (event: any) => {
    drawingManagerRef.current.setDrawingMode(null)
    if (event.type === window.google.maps.drawing.OverlayType.POLYGON) {
      const newPolygon = event.overlay
        .getPath()
        .getArray()
        .map((latLng: google.maps.LatLng) => ({ lat: latLng.lat(), lng: latLng.lng() }))
      event.overlay?.setMap(null)
      onChangePolygons(newPolygon)
    }
  }

  const onEditPolygon = () => {
    const polygon = polygonRef.current
    if (polygon) {
      const coordinates = polygon
        .getPath()
        .getArray()
        .map((latLng: google.maps.LatLng) => ({ lat: latLng.lat(), lng: latLng.lng() }))
      onChangePolygons(coordinates)
    }
  }

  return (
    <Box display='flex' height={height} width={width} justifyContent='flex-end'>
      {isLoaded ? (
        <Wrapper>
          <GoogleMap
            zoom={center?.zoom || 6}
            center={center}
            mapContainerStyle={{ width: '100%', height: '100%' }}
            onLoad={onLoadMap}
            onClick={(e) => {
              const lat = e.latLng?.lat()
              const lng = e.latLng?.lng()
              if (editable && lat && lng && onChangeMarker) {
                geoCoder?.geocode({ location: e.latLng }).then((res: any) => {
                  handleGeocode && handleGeocode(res.results[0])
                  onChangeMarker({
                    lat: lat,
                    lng: lng,
                  })
                })
              }
            }}
            onTilesLoaded={() => setCenter(null)}
          >
            {marker && (
              <MarkerF
                position={{ lat: marker.lat, lng: marker.lng }}
                draggable={editable}
                onDragEnd={(e) => {
                  const lat = e.latLng?.lat()
                  const lng = e.latLng?.lng()

                  if (lat && lng && onChangeMarker) {
                    geoCoder?.geocode({ location: e.latLng }).then((res: any) => {
                      handleGeocode && handleGeocode(res.results[0])
                      onChangeMarker({
                        lat: lat,
                        lng: lng,
                      })
                    })
                  }
                }}
              />
            )}
            <DrawingManagerF
              options={drawingManagerOptions}
              onLoad={onLoadDrawingManager}
              onOverlayComplete={onOverlayComplete}
            />
            {polygons.length && (
              <PolygonF
                draggable={draggable}
                editable={editable}
                options={polygonOptions}
                paths={polygons}
                onLoad={onLoadPolygon}
                onMouseUp={onEditPolygon}
                onDragEnd={onEditPolygon}
              />
            )}
          </GoogleMap>
          <input
            id='searchbox'
            placeholder={formatMessage(messages.mapSearchBoxPlaceholder)}
            style={{
              borderRadius: '2px',
              boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
              display: 'none',
              fontFamily: 'Roboto, Arial, sans-serif',
              fontSize: '18px',
              marginTop: '10px',
              padding: '9px',
              width: '214px',
            }}
          />
        </Wrapper>
      ) : (
        <SpinnerBlock />
      )}
    </Box>
  )
}

export default PolygonMap
