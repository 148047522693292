import { defineMessages } from 'react-intl'

const messages = defineMessages({
  mapSearchBoxPlaceholder: {
    id: 'map.searchBoxPlaceholder',
    description: 'The placeholder for the search box',
    defaultMessage: 'Search Map',
  },
})

export default messages
