import { Box, SxProps, Theme } from '@mui/material';

interface BoxWhiteContainerProps {
    children: React.ReactNode;
    sx?: SxProps<Theme>;  // Accept additional sx props
}

const BoxWhiteContainer: React.FC<BoxWhiteContainerProps> = ({ children, sx }: BoxWhiteContainerProps) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                border: '1px solid #ccc',
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                padding: '16px',
                gap: '16px',
                ...sx,
            }}
        >
            {children}
        </Box>
    );
}

export default BoxWhiteContainer;
