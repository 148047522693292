import { Box } from '@mui/material'
import LogoIcon from '../../assets/logo-transparent.png'
import { ContrastTypography } from '../Typographies/ContrastTypography'

interface LogoProps {
  companyName: string
  isRtl: boolean
}

const Logo = (props: LogoProps) => {
  const { companyName, isRtl } = props
  return (
    <Box display='flex' alignItems='center' justifyContent='center' sx={{ cursor: 'pointer' }}>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        sx={{
          width: '96px',
          marginRight: isRtl ? '0px' : '16px',
          marginLeft: isRtl ? '16px' : '0px',
        }}
      >
        <img src={LogoIcon} alt='Company Logo' style={{ width: 40, height: 40 }} />
      </Box>
      <Box flexGrow={1}>
        <ContrastTypography
          style={{
            width: '100%',
            textAlign: isRtl ? 'right' : 'left',
            textTransform: 'uppercase',
            fontWeight: 700,
          }}
        >
          {companyName}
        </ContrastTypography>
      </Box>
    </Box>
  )
}

export default Logo
