import AddIcon from '@mui/icons-material/AddCircleOutlineTwoTone'

import { ButtonProps } from '@mui/material'
import Button from '@mui/material/Button'
import React from 'react'

interface AddButtonProps extends ButtonProps {
  text: string
}

const AddButton: React.FC<AddButtonProps> = ({ text, ...props }) => {
  return (
    <Button {...props} startIcon={<AddIcon />} color='secondary' variant='contained'>
      {text}
    </Button>
  )
}

export default AddButton
