import { combineReducers } from '@reduxjs/toolkit'
import {
  SHIPMENTS_ALL_COUNTRIES_REDUCER_NAME,
  SHIPMENTS_ALL_CURRENCIES_REDUCER_NAME,
  SHIPMENTS_ALL_LOCATIONS_BY_TENANT_CUSTOMER_REDUCER_NAME,
  SHIPMENTS_ALL_LOCATION_TYPES_REDUCER_NAME,
  SHIPMENTS_ALL_MEASURE_UNITS_REDUCER_NAME,
  SHIPMENTS_ALL_PALLET_TYPES_REDUCER_NAME,
  SHIPMENTS_ALL_REDUCER_NAME,
  SHIPMENTS_ALL_TEMPERATURE_RANGES_REDUCER_NAME,
  SHIPMENTS_ALL_TRANSPORT_MODES_REDUCER_NAME,
  SHIPMENTS_BY_ID_REDUCER_NAME,
  SHIPMENTS_CUSTOMERS_BY_TENANT_ID_REDUCER_NAME,
  SHIPMENTS_CUSTOMER_CURRENCIES_REDUCER_NAME,
  SHIPMENTS_SYSTEM_CURRENCIES_REDUCER_NAME,
} from './consts'
import {
  fetchAllCountriesReducers,
  fetchAllCurrenciesByTenantIdReducers,
  fetchAllCustomersByTenantIdReducers,
  fetchAllLocationTypesReducers,
  fetchAllPalletTypesReducers,
  fetchAllShipmentsReducers,
  fetchAllTemperatureRangeReducers,
  fetchAllTransportModesReducers,
  fetchAllUnitsSliceReducers,
  fetchCurrenciesByCustomerIdReducers,
  fetchLocationsByTenantOrCustomerReducers,
  fetchShipmentByIdReducers,
  fetchSystemCurrenciesReducers,
} from './slice'

export const shipmentsReducers = combineReducers({
  [SHIPMENTS_ALL_COUNTRIES_REDUCER_NAME]: fetchAllCountriesReducers,
  [SHIPMENTS_ALL_REDUCER_NAME]: fetchAllShipmentsReducers,
  [SHIPMENTS_CUSTOMER_CURRENCIES_REDUCER_NAME]: fetchCurrenciesByCustomerIdReducers,
  [SHIPMENTS_SYSTEM_CURRENCIES_REDUCER_NAME]: fetchSystemCurrenciesReducers,
  [SHIPMENTS_ALL_MEASURE_UNITS_REDUCER_NAME]: fetchAllUnitsSliceReducers,
  [SHIPMENTS_ALL_TEMPERATURE_RANGES_REDUCER_NAME]: fetchAllTemperatureRangeReducers,
  [SHIPMENTS_ALL_PALLET_TYPES_REDUCER_NAME]: fetchAllPalletTypesReducers,
  [SHIPMENTS_ALL_TRANSPORT_MODES_REDUCER_NAME]: fetchAllTransportModesReducers,
  [SHIPMENTS_ALL_LOCATION_TYPES_REDUCER_NAME]: fetchAllLocationTypesReducers,
  [SHIPMENTS_BY_ID_REDUCER_NAME]: fetchShipmentByIdReducers,
  [SHIPMENTS_ALL_LOCATIONS_BY_TENANT_CUSTOMER_REDUCER_NAME]:
    fetchLocationsByTenantOrCustomerReducers,
  [SHIPMENTS_CUSTOMERS_BY_TENANT_ID_REDUCER_NAME]: fetchAllCustomersByTenantIdReducers,
  [SHIPMENTS_ALL_CURRENCIES_REDUCER_NAME]: fetchAllCurrenciesByTenantIdReducers,
})
