// import { createSelector } from '@reduxjs/toolkit'
import { makeTypedFetchSelectors } from '../../../app/redux-fetch'
import { TENANT_REDUCER } from '../../TenantSettings/consts'
import {
  TENANT_ALL_IDENTITY_PROVIDER_ID_REDUCER_NAME,
  TENANT_ALL_ON_BOARDING_REDUCER_NAME,
} from './consts'
import { IIdentityProvider, IOnBoarding } from './types'

export const fetchOffice365OnBoardingSelector = makeTypedFetchSelectors<IOnBoarding>(
  TENANT_REDUCER,
  TENANT_ALL_ON_BOARDING_REDUCER_NAME
)

export const fetchIdentityProviderIntegrationIdSelector = makeTypedFetchSelectors<
  IIdentityProvider[]
>(TENANT_REDUCER, TENANT_ALL_IDENTITY_PROVIDER_ID_REDUCER_NAME)
