import { createContext, useContext } from 'react'

export const ProductTabContext = createContext<ProductTabContextType | undefined>(undefined)

type ProductTabContextType = {
    customerId: number
    setCustomerId?: React.Dispatch<React.SetStateAction<number>>
}

export function useProductTabContext(): ProductTabContextType {
    const context = useContext(ProductTabContext)
    if (context === undefined) {
        throw new Error('useProductTabContext  is not initialized')
    }
    return context
}
