import * as yup from 'yup'

export const getProductValidationSchema = (formatMessage: any) =>
  yup.object({
    name: yup.string().required('Product name is required'),
    customerId: yup.number().required('Customer is required'),
    sku: yup.string().required('SKU is required'),
    productCategoryId: yup.number().required('Product Category is required'),
    countryOfOriginId: yup.number().required('Country of Origin is required'),
  })
