import { createContext, useContext } from 'react'

type SideModalContextType = {
  onClose: () => void
  onSave?: (values: any) => void
  setBottomComponent?: (component: React.ReactNode) => void
  setSaveHandler?: (component: React.ReactNode) => void
  onDelete?: () => void
  bottomComponent?: React.ReactNode
}

export const SideModalContext = createContext<SideModalContextType | undefined>(undefined)

export function useSideModalContext(): SideModalContextType {
  const context = useContext(SideModalContext)
  if (context === undefined) {
    throw new Error('useSideModalContext must be used within a SideModalContextProvider')
  }
  return context
}
