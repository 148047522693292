import { NumberFormatBase } from 'react-number-format';

const SerialNumberInput = (props: any) => {
    const formatToEightDigits = (value: string): string => {
        if (value) {
            return String(value).padStart(8, '0')
        }
        return ''
    }
    return <NumberFormatBase {...props} format={formatToEightDigits} />
}

export default SerialNumberInput