import { Typography } from '@mui/material'


const RequiredAsterisk = () => {

    return (
        <Typography component="span" sx={{
            marginLeft: '4px',
            color: '#636363',

        }}>
            *
        </Typography>
    )
}

export default RequiredAsterisk