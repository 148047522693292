import { type AxiosResponse } from 'axios'
import { usersAuthorizedFetch } from '../../app/axios/omnialog'
import { IBranding } from './types'

export const getBranding = async (tenantId: number): Promise<AxiosResponse<any, any>> => {
  return await usersAuthorizedFetch.get('/get-branding-by-tenant-id', { params: { tenantId } })
}

export const getOnBoarding = async (tenantId: number): Promise<AxiosResponse<any, any>> => {
  return await usersAuthorizedFetch.get('/onboard', { params: { tenantId } })
}

export const postBranding = async (branding: IBranding): Promise<AxiosResponse<any, any>> => {
  return await usersAuthorizedFetch.post('/create-branding', branding)
}

export const updateBranding = async (branding: IBranding): Promise<AxiosResponse<any, any>> => {
  return await usersAuthorizedFetch.put('/update-branding', branding)
}
