import { AccordionDetails, Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import StyledAccordion from '../../components/Accordions/StyledAccordion';
import StyledAccordionSummary from '../../components/Accordions/StyledAccordionSummary';
import FormSelect from '../../components/Forms/FormSelect';
import commonMessages from '../../components/Messages/commonMessages';
import { useLocationModal } from './hooks/useLocationModal';
import messages from './messages';

interface ReturnToProps {
    consignmentIndex: number;
}

const ReturnTo: React.FC<ReturnToProps> = ({ consignmentIndex }: ReturnToProps) => {
    const { control, setValue, watch } = useFormContext();

    const [expanded, setExpanded] = useState(true);
    const { formatMessage } = useIntl();

    const handleToggleAccordion = () => {
        setExpanded((prev) => !prev);
    };

    const { openModal, ModalComponent, locationOptions } = useLocationModal({
        onSave: (id) => {
            setValue(`consignments.${consignmentIndex}.returnTo.location`, id);
        },
    });

    const returnTo = watch(`consignments.${consignmentIndex}.shipFrom.returnTo`);

    if (!returnTo) {
        return null;
    }

    return (
        <>
            {ModalComponent}
            <StyledAccordion
                defaultExpanded
                sx={{ marginTop: '10px' }}
                onChange={handleToggleAccordion}
            >
                <StyledAccordionSummary
                    index={`returnTo-content-${consignmentIndex}`}
                    expanded={expanded}
                    title={formatMessage(messages.returnTo)}
                >
                </StyledAccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <FormSelect
                                name={`consignments.${consignmentIndex}.returnTo.locationId`}
                                label={formatMessage(messages.location)}
                                options={locationOptions}
                                actionLabel={
                                    <Button
                                        size="small"
                                        onClick={() => {
                                            openModal();
                                        }}
                                        sx={{ textTransform: 'none', p: 0, mb: '6px' }}
                                    >
                                        {formatMessage(commonMessages.addNew)}
                                    </Button>
                                }
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FormSelect
                                name={`consignments.${consignmentIndex}.returnTo.contactId`}
                                label={formatMessage(messages.contact)}
                                options={[]}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="subtitle1" gutterBottom sx={{ color: '#636363' }}>
                                {formatMessage(messages.selectedLocation)}
                            </Typography>
                            <Typography variant="body2" sx={{ color: '#636363' }}>Location 1 (Address Of Location 1)</Typography>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </StyledAccordion>
        </>)
}

export default ReturnTo