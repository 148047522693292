import { useIntl } from 'react-intl'
import { useCreateProductCategoryMutation } from '../../../app/redux-fetch/apiQuery'
import ConfirmDialog from '../../../components/ConfirmDialog/ConfirmDialog'
import { IProductCategory } from '../types'
import { useProductCategoryFormContext } from './hooks/useProductCategoryFormContext'
import messages from './messages'

type ConfirmCreateDialogProps = {
  handleEdit: () => void
  handleCancel: () => void
  isOpen: boolean
}

const ConfirmCreateDialog = ({ handleEdit, handleCancel, isOpen }: ConfirmCreateDialogProps) => {
  const { formatMessage } = useIntl()
  const [createProductCategory] = useCreateProductCategoryMutation()
  const { values } = useProductCategoryFormContext()
  return (
    <ConfirmDialog
      open={isOpen}
      title={formatMessage(messages.createProductDialogTitle)}
      content={formatMessage(messages.createProductDialogText)}
      continueButtonText={formatMessage(messages.confirmDialogYes)}
      discardButtonText={formatMessage(messages.confirmDialogNo)}
      onContinueEdit={async () => {
        await createProductCategory({
          customerId: values.customerId ?? -1,
          body: { ...values, customerId: values.customerId } as IProductCategory,
        })
        handleEdit()
      }}
      onDiscardChanges={() => {
        handleCancel()
      }}
    />
  )
}

export default ConfirmCreateDialog
