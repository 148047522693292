import { Accordion, styled, type AccordionProps } from '@mui/material'

export const AssetRequestAccordion = styled(Accordion)<AccordionProps>(() => ({
  borderBotton: '1px solid rgba (0, 0, 0, 0.125)',
  margin: '16px',
}))

export const ContainerAccordion = styled(Accordion)<AccordionProps>(() => ({
  borderBotton: '1px solid rgba (0, 0, 0, 0.125)',
  margin: '16px',
}))

export const LoadAccordion = styled(Accordion)<AccordionProps>(() => ({
  borderBotton: '1px solid rgba (0, 0, 0, 0.125)',
  margin: '16px',
}))

export const PalletAccordion = styled(Accordion)<AccordionProps>(() => ({
  borderBotton: '1px solid rgba (0, 0, 0, 0.125)',
  margin: '16px',
}))

export const ShipmentAccordion = styled(Accordion)<AccordionProps>(() => ({
  marginTop: '4px',
  marginBottom: '16px',
  border: '1px solid rgba(0, 0, 0, .125)',
}))

export const ShipmentSummaryAccordion = styled(Accordion)<AccordionProps>(() => ({
  marginTop: '4px',
  marginBottom: '16px',
  border: '1px solid rgba(0, 0, 0, .125)',
}))
