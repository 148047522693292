import { useEffect, useState } from 'react';
import { useLoggedInUser } from '../../../app/hooks/useLoggedInUser';
import { useModalWithForm } from '../../../app/hooks/useModalWithForm';
import { useGetLocationsQuery } from '../../../app/redux-fetch/apiShipment';
import { convertToLabelValue } from '../../../app/utils';
import { Option } from '../../../components/Forms/FormSelect';

interface UseLocationModalProps {
    onSave: (data: any) => void;
}

export const useLocationModal = ({ onSave }: UseLocationModalProps) => {
    const { tenantId, customerId } = useLoggedInUser();

    const [locationOptions, setLocationOptions] = useState<Option[]>([]);

    const { data: locations = [] } = useGetLocationsQuery({
        tenantId,
        customerId,
    });

    useEffect(() => {
        setLocationOptions(convertToLabelValue(locations));
    }, [locations]);

    const { isModalOpen, openModal, closeModal, ModalComponent } = useModalWithForm({
        type: 'location',
        onSave: (createdLocation) => {
            onSave(createdLocation.data.id);
        },
    });

    return {
        locationOptions,
        isModalOpen,
        openModal,
        closeModal,
        ModalComponent,
    };
};
