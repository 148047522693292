import { AccordionDetails } from '@mui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import StyledAccordion from '../../components/Accordions/StyledAccordion';
import StyledAccordionSummary from '../../components/Accordions/StyledAccordionSummary';
import { AccordionHeader } from '../../components/Headers/styles';
import ConsignmentInformationForm from './ConsignmentInformationForm';
import DeliverToForm from './DeliverToForm';
import messages from './messages';
import ShipFromForm from './ShipFromForm';

interface ShipFromDeliverToContainerProps {
    consignmentIndex: number;
}


const ShipFromDeliverToContainer: React.FC<ShipFromDeliverToContainerProps> = ({
    consignmentIndex,
}: ShipFromDeliverToContainerProps) => {
    const [expanded, setExpanded] = useState(true);
    const { formatMessage } = useIntl();

    const handleToggleAccordion = () => {
        setExpanded((prev) => !prev);
    };
    return (
        <>
            <ConsignmentInformationForm index={consignmentIndex} />
            <StyledAccordion title={formatMessage(messages.shipFrom)} expanded={expanded} onChange={handleToggleAccordion}
            >
                <StyledAccordionSummary index={`ship-from-${consignmentIndex}`}>
                    <AccordionHeader sx={{ pr: '16px' }}>
                        {formatMessage(messages.shipFromDeliverTo)}
                    </AccordionHeader>
                </StyledAccordionSummary>
                <AccordionDetails>
                    <ShipFromForm index={consignmentIndex} />
                    <DeliverToForm index={consignmentIndex} />
                </AccordionDetails>
            </StyledAccordion >
        </>
    );
};

export default ShipFromDeliverToContainer;