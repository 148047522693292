import dayjs from 'dayjs'
import { IShipment } from '../../types'
import { messages } from '../AllShipments/messages'

export const getStatus = (formatMessage: any, shipment?: IShipment) => {
  const currentTime = dayjs(new Date())
  if (!shipment) {
    return ''
  }
  if (shipment.underReviewUntil) {
    const localLockedTime = dayjs.utc(shipment.underReviewUntil)
    const diffTime = localLockedTime.diff(currentTime, 'second')
    if (diffTime > 0) {
      return formatMessage(messages.lockForReviewBtn)
    }
  }
  if (shipment.acceptedDate) {
    return formatMessage(messages.acceptedStatus)
  }
  if (shipment.submittedDate) {
    return formatMessage(messages.submittedStatus)
  }
  return formatMessage(messages.draftStatus)
}
