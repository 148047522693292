import { useProductTabContext } from '../ProductTabContext'
import { IProduct } from '../types'
import ProductForm from './ProductForm'

const CreateProductModal = () => {
  const { customerId } = useProductTabContext()

  return (
    <ProductForm
      product={
        {
          customerId: customerId,
          enabled: true,
        } as IProduct
      }
      isDisabled={false}
    />
  )
}

export default CreateProductModal
