export const SHIPMENTS_REDUCER = 'shipments'
export const SHIPMENTS_ALL_COUNTRIES_REDUCER_NAME = 'allCountries'
export const SHIPMENTS_CUSTOMER_CURRENCIES_REDUCER_NAME = 'allCustomerCurrencies'
export const SHIPMENTS_ALL_CURRENCIES_REDUCER_NAME = 'allCurrencies'
export const SHIPMENTS_SYSTEM_CURRENCIES_REDUCER_NAME = 'allSystemCurrencies'
export const SHIPMENTS_ALL_REDUCER_NAME = 'allShipments'
export const SHIPMENTS_ALL_LOCATIONS_BY_TENANT_CUSTOMER_REDUCER_NAME =
  'allLocationsByTenantCustomer'
export const SHIPMENTS_ALL_TEMPERATURE_RANGES_REDUCER_NAME = 'allTemperatureRanges'
export const SHIPMENTS_ALL_MEASURE_UNITS_REDUCER_NAME = 'allMeasureUnits'
export const SHIPMENTS_ALL_PALLET_TYPES_REDUCER_NAME = 'allPalletTypes'
export const SHIPMENTS_ALL_TRANSPORT_MODES_REDUCER_NAME = 'allTransportModes'
export const SHIPMENTS_ALL_LOCATION_TYPES_REDUCER_NAME = 'allLocationTypes'
export const SHIPMENTS_BY_ID_REDUCER_NAME = 'shipmentById'
export const SHIPMENTS_CUSTOMERS_BY_TENANT_ID_REDUCER_NAME = 'allTenantCustomers'
